import { Breadcrumbs, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Container, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Link, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add, AddShoppingCart, Edit, MoreVert } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import AuthorityUtils from "util/AuthorityUtils";
import NewsPanel from "./news/News";
import NewsEditor from "./NewsEditor";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import './ItemDetails.css';
import ItemDetailsEditor from "./ItemDetailsEditor";
import { useShoppingCart } from "util/ShoppingCartContextProvider";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import { t } from "i18next";
import SEO from "util/SEO";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dialog: {
    minWidth: '85%',
    minHeight: '85%'
  },
  addFab: {
    position: 'absolute',
    bottom: '5vh',
    right: '3vw'
  },
  menuCard: {
    border: 'none',
    boxShadow: 'none'
  },
  imgFullHeight: {
    height: '100%',
    transform: 'translateX(-50%)',
    position: 'relative',
    left: '50%',
  },
  /* Styles applied to an `img` element child, if needed to ensure it covers the item. */
  imgFullWidth: {
    width: '100%',
    position: 'relative',
    transform: 'translateY(-50%)',
    top: '50%',
  },
  cart: {
    "&&:hover": {
      backgroundColor: "#c62e54"
    }
  }
}));

const fit = (imgEl, classes) => {
  if (!imgEl || !imgEl.complete) {
    return;
  }

  if (
    imgEl.width / imgEl.height >
    imgEl.parentElement.offsetWidth / imgEl.parentElement.offsetHeight
  ) {
    imgEl.classList.remove(...classes.imgFullWidth.split(' '));
    imgEl.classList.add(...classes.imgFullHeight.split(' '));
  } else {
    imgEl.classList.remove(...classes.imgFullHeight.split(' '));
    imgEl.classList.add(...classes.imgFullWidth.split(' '));
  }
};

function ensureImageCover(imgEl, classes) {
  if (!imgEl) {
    return;
  }

  if (imgEl.complete) {
    fit(imgEl, classes);
  } else {
    imgEl.addEventListener('load', () => {
      fit(imgEl, classes);
    });
  }
}

const ItemDetails = ({
  itemDetailsService,
  exceptionHandlerService,
  commentsService,
  sectionPath,
  fileService }) => {

    const scrollRef = useRef(null);
    
    const goToTop = () => {
      scrollRef.current?.scrollIntoView({behavior: 'instant', block: "end"});
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    const imgRef = React.useRef(new Array());

    React.useEffect(() => {
        imgRef.current.forEach(image => {
          ensureImageCover(image, classes);
        });
      });
    
      React.useEffect(() => {
        const handleResize = debounce(() => {
          imgRef.current.forEach(image => {
            fit(image, classes);
          });
          
        });
    
        window.addEventListener('resize', handleResize);
        return () => {
          handleResize.clear();
          window.removeEventListener('resize', handleResize);
        };
      }, [classes]);


  const [itemDetails, setItemDetails] = useState({
    images: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  useEffect(() => {
    goToTop();
    setIsLoading(true);
    const fetchNews = async () => {
      try {
        const result = await itemDetailsService.retrieveItemDetailsBySectionPathAndCountry(sectionPath, currentRegion);
        if (result && result.id) {
          setItemDetails(result);
        } else {
          setItemDetails({
            images: []
          });
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
      //exceptionHandlerService.showInfoToast('Item Details has been retrieved');
    };

    fetchNews();

  }, [sectionPath]);


  const handleClose = function () {

  }

  const updateEventHandler = function (updatedItemDetails) {
    setItemDetails(updatedItemDetails);
  }

  const handleAddNewsButtonClick = function () {
    setOpen(true);
  }

  const images = itemDetails.images.map(image =>
    <SwiperSlide>
      <img src={"/fstor/" + image.pathMedium} alt="" ref={(element) => imgRef.current.push(element)}/>
    </SwiperSlide>
  );

  const breadcrumbsLinks = function () {
    const words = sectionPath.split('/');
    let currentLink = "/_";
    let currentWordIndex = 1;
    return words.map(pathItem => {
      currentLink = currentLink + "/" + pathItem;
      if (currentWordIndex < words.length) {
        currentWordIndex++;
        return (<Link underline="hover" color="inherit" href={currentLink}>
          {pathItem}
        </Link>);
      } else if (currentWordIndex === words.length) {
        currentWordIndex++;
        return (<Typography color="primary">{pathItem}</Typography>);
      }
    });
  }

  const {
    addTileToCart,
    addItemDetailsToCart,
    getItemsCount
  } = useShoppingCart();

  const addToCart = function (itemDetails) {
    exceptionHandlerService.showInfoToast(t('common_item_was_added_to_cart'));
    addItemDetailsToCart(itemDetails);
  }

  const getHeader = (id) => {
    return <Card className={classes.menuCard}>
      <CardContent>

        <Typography variant="h5">{id.header}</Typography>
        <Chip label={t("item_details_offer_language") + " - " + (id.commercialData?.languageName ? id.commercialData?.languageName : "")}></Chip>
      </CardContent>
    </Card>
  }

  return (
    <div  className={isMobile ? "itemDetailsPageMobile" : "itemDetailsPage"}>
      <SEO title={itemDetails?.header ? itemDetails?.header : ""} description="" name="Pearl Art Store" image={itemDetails?.images[0]?.pathMedium ? "/fstor/" + itemDetails?.images[0]?.pathMedium : null}/>
      <Container maxWidth="lg" className="breadcrumbs">
      <div >
      </div>
        <Grid ref={scrollRef} key="breadcrumbs" item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            {breadcrumbsLinks()}
          </Breadcrumbs>

        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid key="news-grid" container>
          <Grid key="pictures" item xs={isMobile ? 12 : 6}>
            <Card className={classes.menuCard}>
              <CardContent>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={5}
                  slidesPerView={1}
                  navigation
                  color="green"
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {isLoading && <CircularProgress color="primary" size={37} thickness={10} />}
                  {!isLoading && itemDetails && images}
                  {!isLoading && !itemDetails.id && <div>{t('common_no_data_to_display')}</div>}
                </Swiper>
              </CardContent>
            </Card>
          </Grid>
          <Grid key="details" item xs={isMobile ? 12 : 6}>
            <Grid key="details-markup" container>
              {!isMobile && getHeader(itemDetails)}
              {itemDetails && itemDetails.commercialData && itemDetails.commercialData.cost && <Grid key="commercial-data" item xs={12}>
                <Card className={classes.menuCard}>
                  <CardContent>
                    <Typography variant="h6" color="secondary">{itemDetails.commercialData.cost + ' ' + itemDetails.commercialData.currency}</Typography>
                  </CardContent>
                </Card>
              </Grid>}
              <Grid key="d" item xs={12}>
                <Card className={classes.menuCard}>
                  <CardContent>
                    <Typography variant="h5">{t('item_details_description')}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: itemDetails.description }}></div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid key="actions" item xs={12}>
              <Card className={classes.menuCard}>
                  <CardContent>
                  <Chip disabled={isLoading || !itemDetails.commercialData?.cost || !itemDetails.commercialData?.inStock} className={classes.cart} color="secondary" icon={<AddShoppingCart />} label={itemDetails.commercialData?.cost && itemDetails.commercialData?.inStock ? t("tile_page_add_to_cart") : t("tile_page_out_of_stock")} onClick={() => addToCart(itemDetails)} />
                  {itemDetails && itemDetails.commercialData && ( '' + itemDetails.commercialData.inStock )  && <Typography variant="caption" display="block">{t('item_details_in_stock')} : {itemDetails.commercialData.inStock}</Typography>}
                </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
      <Dialog disableEnforceFocus disableScrollLock classes={{ paper: classes.dialog }} disableBackdropClick onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          Edit item details
        </DialogTitle>
        <DialogContent dividers>
          <ItemDetailsEditor itemDetailsService={itemDetailsService} exceptionHandlerService={exceptionHandlerService} itemDetails={itemDetails} setOpen={setOpen} updateEventHandler={updateEventHandler} sectionPath={sectionPath} fileService={fileService} />

        </DialogContent>
      </Dialog>
      {AuthorityUtils.userHasAuthority("UPDATE_NEWS_POST") && <Fab color="primary" aria-label="add" onClick={handleAddNewsButtonClick} className={classes.addFab}>
        <Edit />
      </Fab>}
    </div>
  )
};

export default ItemDetails;
