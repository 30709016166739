import React, { useEffect, useRef, useState } from "react";
import { Jodit } from "jodit";
import { Button, CircularProgress, Container, FormControl, ImageList, ImageListItem, InputLabel, makeStyles, MenuItem, Select, Switch, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import './ItemDetailsEditor.css';
import { AddAPhoto, Delete, DeleteForever, Edit, Remove, RemoveCircle } from "@material-ui/icons";
import "jodit/build/jodit.css";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";

const ItemDetailsEditor = ({ itemDetailsService, exceptionHandlerService, itemDetails, setOpen, updateEventHandler, sectionPath, fileService }) => {

  var editor = useRef(null);
  const [header, setHeader] = useState(itemDetails.header ? itemDetails.header.slice() : "");
  const [isLoading, setIsLoading] = useState(false);
  const [imageIdLoading, setImageIdLoading] = useState('');
  const [isHeaderCorrect, setIsHeaderCorrect] = useState(true);
  const [isSelectedImagesCorrect, setIsSelectedImagesCorrect] = useState(true);
  const [includeCommercialData, setIncludeCommercialData] = useState(itemDetails?.commercialData?.cost ? true : false);
  const [currency, setCurrency] = useState(itemDetails?.commercialData?.currency ? itemDetails?.commercialData?.currency :'PLN');
  const [cost, setCost] = useState(itemDetails?.commercialData?.cost ? itemDetails?.commercialData?.cost + '' : '');
  const [inStock, setInStock] = useState(itemDetails?.commercialData?.inStock + '' ? itemDetails?.commercialData?.inStock : 1);
  const [costInputCorrect, setCostInputCorrect] = useState(true);
  const [inStockInputCorrect, setInStockInputCorrect] = useState(true);
  const history = useHistory();
  const [ed, setEd] = useState(null);
  const {
    currentRegion
  } = useLanguageAndRegionContext();

  const [language, setLanguage] = React.useState(itemDetails?.commercialData?.languageName ? itemDetails?.commercialData?.languageName :'');
  const [country, setCountry] = React.useState(currentRegion);
  const [languageInputValid, setLanguageInputValid] = React.useState(true);
  const [countryInputValid, setCountryInputValid] = React.useState(true);
  const scrollRef = useRef(null);

  const goToTop = () => {
    scrollRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const currencies = [
    'PLN'
  ];


  const config = {
    minHeight: "25vh",
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    iframe: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: '/api/files/images/posts/temp',

      isSuccess: function (resp) {
        return true;
      },
      process: function (resp) {
        console.log(resp);
        return {
          files: resp.files || ["file"], // {array} The names of uploaded files.
          path: "/fstor/" + resp.pathMedium,
          baseurl: "/fstor/" + resp.pathMedium,
          msg: "msg"
        };
      },
      defaultHandlerSuccess: function (response) { // `this` is the editor.
        const j = this;
        if (response.files && response.files.length) {
          response.files.forEach((filename, index) => { //edetor insertimg function
            const image = document.createElement('img');
            image.setAttribute('src', response.path);
            j.selection.insertHTML(image);
          });
        }
      }
    },
    toolbarAdaptive: false,
    link: {
      processVideoLink: true
    },
    selectionCellStyle: 'border: 1px double #1e88e5 !important;',
    useExtraClassesOptions: true,
    table: {
      data: {
        classList: {
          'table table-bordered, th table-bordered, td table-bordered': 'Bootstrap Bordered'
        }
      }
    }
  };

    const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    setLanguageInputValid(true);
};

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryInputValid(true);
  };


  useEffect(() => {
    let id = itemData;
    let currentNumber = 0;
    itemDetails.images.forEach(img => {
      id.set('' + currentNumber, img)
      currentNumber++;
    });
    if (currentNumber < 5) {
      for (let i = currentNumber; i < 6; i++) {
        id.set('' + i, {})
      }
    }
    const editor = Jodit.make("#editor", config);
    editor.value = itemDetails.description ? itemDetails.description.slice() : "";
    editor.workplace.style.cssText = 'width: auto; height: auto; min-height: 25vh; color: black';
    setEd(editor);
    setItemData(new Map(id));
    // returned function will be called on component unmount 
    return () => {
      document.querySelectorAll("[class^=jodit]").forEach(item => item.remove());
    }
  }, []);

  const handleHeaderInputValueChange = function (e) {
    setHeader(e.target.value);
    validateHeader(e.target.value);
  }

  const handleCurrencySelectChange = function (e) {
    setCurrency(e.target.value);
  }

  const validateHeader = function (header) {
    if (!header) {
      setIsHeaderCorrect(false);
      goToTop();
      return false;
    } else {
      setIsHeaderCorrect(true);
      return true;
    }
  }

  const validateLanguage = function (language) {
    if(!language) {
      setLanguageInputValid(false);
      goToTop();
      return false;
    } else {
      setLanguageInputValid(true);
      return true;
    }
  }

  const validateCountry = function (country) {
    if(!country) {
      setCountryInputValid(false);
      goToTop();
      return false;
    } else {
      setCountryInputValid(true);
      return true;
    }
  }

  const validateSelectedImages = function (selectedImages) {
    if (selectedImages && selectedImages.length > 0) {
      setIsSelectedImagesCorrect(true);
      return true;
    } else {
      setIsSelectedImagesCorrect(false);
      window.scrollTo(0, 0);
      return false;
    }
  }

  const validateCommercialData = function() {
    if(!includeCommercialData) {
      return true;
    }

    if(!cost) {
      setCostInputCorrect(false);
      window.scrollTo(0, 0);
      return false;
    }
    if(!inStock) {
      setInStockInputCorrect(false);
      window.scrollTo(0, 0);
      return false;
    }

    return true;
  }

  const saveItemDetails = function (itemData) {
    let selectedImages = Array.from(itemData.values()).filter(i => Object.keys(i).length > 0);
    if (validateHeader(header) 
        && validateSelectedImages(selectedImages) 
        && validateCommercialData() 
        && validateLanguage(language) 
        && validateCountry(country)) {
      setIsLoading(true);
      let desc = ed.value;
      let commercialData = itemDetails?.commercialData;
      if(commercialData) {
        commercialData.country = country;
        commercialData.language = language;
      } else {
        commercialData = {
          countryName: country,
          languageName: language
        }
      }
      if(includeCommercialData) {
        commercialData.cost = cost;
        commercialData.currency = currency;
        commercialData.inStock = inStock;
      }
      if (itemDetails && itemDetails.id) {
        itemDetailsService.updateItemDetails({
          id: itemDetails.id,
          header: header,
          section: {
            path: sectionPath
          },
          commercialData: commercialData,
          description: desc,
          images: selectedImages
        }).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            itemDetails.id = response.id;
            itemDetails.header = header;
            itemDetails.description = desc;
            itemDetails.images = selectedImages;
            itemDetails.commercialData = commercialData;
            updateEventHandler(itemDetails);
            setIsLoading(false);
            setOpen(false);
          }

        }).catch(e => {
          setIsLoading(false);
        });
      } else {
        const newsItemRequest = {
          id: null,
          header: header,
          section: {
            path: sectionPath
          },
          description: desc,
          commercialData: commercialData,
          images: Array.from(itemData.values()).filter(i => Object.keys(i).length > 0)
        };
        itemDetailsService.addItemDetails(newsItemRequest).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            itemDetails.id = response.id;
            itemDetails.header = header;
            itemDetails.description = desc;
            itemDetails.commercialData = commercialData;
            itemDetails.images = Array.from(itemData.values()).filter(i => Object.keys(i).length > 0)
            updateEventHandler(itemDetails);
            setIsLoading(false);
            setOpen(false);
          }

        }).catch(e => {
          setIsLoading(false);
        });
      }
    }
  }

  const handleEnterPressedEvent = function (e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      saveItemDetails();
    } else if (e.key === "Esc") {
      cancel();
    }
  }

  const cancel = function () {
    setOpen(false);
  }


  const handleImageInputChange = function (e, selectedImageId) {
    let image = e.target.files[0];
    setIsLoading(true);
    setImageIdLoading(selectedImageId);
    fileService.addTempFile(image)
      .then(response => {
        if (response.error && response.status === 401) {
          exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
          setIsLoading(false);
          setImageIdLoading('');
        } else if (response.error) {
          exceptionHandlerService.showErrorToast('Unexpected error occured');
          setIsLoading(false);
          setImageIdLoading('');
        } else {
          itemData.set(selectedImageId, response);
          setItemData(new Map(itemData));
          setIsLoading(false);
          setImageIdLoading('');
        }

      }).catch(e => {
        setIsLoading(false);
        setImageIdLoading('');
      });
  }

  const [itemData, setItemData] = useState(new Map());
  const imageUploadClickableArea = useRef(new Map());

  const handleAreaClick = function (itemId) {
    if (!isLoading) {
      imageUploadClickableArea.current.get(itemId).click();
    }
  }

  const removeItem = function (itemId) {
    itemData.set(itemId, {});
    setItemData(new Map(itemData));
  }

  const handleSwitch = (e) => {
    setIncludeCommercialData(e.target.checked);
  }

  const handleCostChange = (e) => {
    const cost = +e.target.value;
    if(typeof cost === 'number' && cost > 0) {
      setCost(e.target.value);
      console.log(+e.target.value);
    } else {
      setCost('');
    }

  }

  const handleInStockChange = (e) => {
    const inStock = +e.target.value;
    if(typeof inStock === 'number' && inStock > 0) {
      setInStock(Math.trunc(parseInt(inStock+'', 10)) + '');
    } else if(typeof inStock === 'number' && inStock === 0 && e.target.value.length > 0) {
      setInStock('0');
    } else if(typeof inStock === 'number' && inStock === 0 && e.target.value.length === 0) {
      setInStock('');
    }
  }

  return (
    <div>
      <div>
      <div ref={scrollRef}>
      </div>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Header:
        </Typography>

        <TextField required id="news-header" label="Header" value={header} onChange={handleHeaderInputValueChange} error={!isHeaderCorrect} />
        <br />
        <br />
        <TextField
          id="filled-select-language"
          select
          label="Language"
          value={language}
          onChange={handleLanguageChange}
          helperText="Please select your language"
          variant="filled"
          className={`${languageInputValid ? '' : 'error'}`}
        >
                <MenuItem id="language-ru" value="Русский">Русский</MenuItem>
                <MenuItem id="language-pl" value="Polski">Polski</MenuItem>
                <MenuItem id="language-en" value="English">English</MenuItem>
        </TextField>
        <br />
            <TextField
          id="filled-select-country"
          select
          label="Country"
          value={country}
          onChange={handleCountryChange}
          helperText="Please select your country"
          variant="filled"
          disabled={true}
          className={`${countryInputValid ? '' : 'error'}`}
        >
                <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
        </TextField>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Commercial Data:
        </Typography>
        <Typography>Enabled:</Typography>
        <Switch checked={includeCommercialData} onChange={handleSwitch} />
        <Typography>Currency:</Typography>
        <TextField
          id="filled-select-currency"
          select
          disabled={!includeCommercialData}
          label="Select"
          value={currency}
          onChange={handleCurrencySelectChange}
          helperText="Please select your currency"
          variant="filled"
        >
          {currencies.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Typography>Cost:</Typography>
        <TextField disabled={!includeCommercialData} id="cost" label="Cost" value={cost} onChange={handleCostChange} error={!costInputCorrect}/>
        <Typography>Count in Stock:</Typography>
        <TextField disabled={!includeCommercialData} id="count" label="Count" value={inStock} type="number" onChange={handleInStockChange} error={!inStockInputCorrect}/>
        <br />

      </div>
      <div>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Images:
        </Typography>
        {!isSelectedImagesCorrect && <div className="imagesInputErrorMsg">
          Please select at least one image
        </div>}
        <ImageList cols={6} rowHeight={164} className="photosInput">
          {Array.from(itemData.keys()).map((itemId) => {
            return <ImageListItem key={itemId}>
              {Object.keys(itemData.get(itemId)).length === 0 && <div onClick={() => handleAreaClick(itemId)} className="emptyPhotoInput">
                <input id={itemId} onChange={(e) => { handleImageInputChange(e, itemId) }} ref={(element) => imageUploadClickableArea.current.set(itemId, element)}
                  type="file"
                  hidden
                />
                <AddAPhoto />{(imageIdLoading === itemId) && <CircularProgress color="primary" size={17} thickness={10} />}</div>}
              {Object.keys(itemData.get(itemId)).length !== 0 && <div>

                <div className="removeButton" onClick={() => removeItem(itemId)} ><Delete color="error"></Delete></div>
                <img
                  src={'/fstor/' + itemData.get(itemId).pathSmall}
                  srcSet={'/fstor/' + itemData.get(itemId).pathSmall}
                  alt='tie'
                  loading="lazy"
                />
              </div>}

            </ImageListItem>

          })}
        </ImageList>

      </div>
      <div>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Details:
        </Typography>
        <textarea id="editor" name="editor" />
      </div>
      <div>
        <Button className="submit-button" variant="contained" color="primary" disabled={isLoading} onClickCapture={() => saveItemDetails(itemData)} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Apply</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
        <Button className="cancel-button" variant="contained" color="primary" disabled={isLoading} onClickCapture={cancel} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Cancel</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
      </div>
    </div>
  );
}

export default ItemDetailsEditor;
