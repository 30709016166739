class AuthorityUtils {
    static userHasAuthority = function (authority) {
        const authorities = localStorage.getItem('authorities');
        if(authorities && authorities !== "undefined") {
            return JSON.parse(authorities)?.includes(authority);
        } 
        return false;
    }
}

export default AuthorityUtils;