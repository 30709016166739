import React, { useEffect, useRef, useState } from "react";
import { Jodit } from "jodit";
import { Button, CircularProgress, Container, ImageList, ImageListItem, makeStyles, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import './HomePageEditor.css';
import { AddAPhoto, Delete } from "@material-ui/icons";
import "jodit/build/jodit.css";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";

const HomePageEditor = ({ homePageService, exceptionHandlerService, itemDetails, setOpen, updateEventHandler, sectionPath, fileService }) => {

  var editor = useRef(null);
  const [header, setHeader] = useState(itemDetails.header ? itemDetails.header.slice() : "");
  const [isLoading, setIsLoading] = useState(false);
  const [imageIdLoading, setImageIdLoading] = useState('');
  const [isHeaderCorrect, setIsHeaderCorrect] = useState(true);
  const [isSelectedImagesCorrect, setIsSelectedImagesCorrect] = useState(true);
  const history = useHistory();
  const [ed, setEd] = useState(null);
  const {
    currentRegion,
    currentLanguage
  } = useLanguageAndRegionContext();

  const config = {
    minHeight: "25vh",
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    iframe: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: '/api/files/images/posts/temp',

      isSuccess: function (resp) {
        return true;
      },
      process: function (resp) {
        return {
          files: resp.files || ["file"], // {array} The names of uploaded files.
          path: "/fstor/" + resp.pathMedium,
          baseurl: "/fstor/" + resp.pathMedium,
          msg: "msg"
        };
      },
      defaultHandlerSuccess: function (response) { // `this` is the editor.
        const j = this;
        if (response.files && response.files.length) {
          response.files.forEach((filename, index) => { //edetor insertimg function
            const image = document.createElement('img');
            image.setAttribute('src', response.path);
            j.selection.insertHTML(image);
          });
        }
      }
    },
    toolbarAdaptive: false,
    link: {
      processVideoLink: true
    },
    selectionCellStyle: 'border: 1px double #1e88e5 !important;',
    useExtraClassesOptions: true,
    table: {
      data: {
        classList: {
          'table table-bordered, th table-bordered, td table-bordered': 'Bootstrap Bordered'
        }
      }
    }
  };

  useEffect(() => {
    let id = itemData;
    let currentNumber = 0;
    itemDetails.images.forEach(img => {
      id.set('' + currentNumber, img)
      currentNumber++;
    });
    if(currentNumber < 5) {
      for(let i = currentNumber; i < 6; i++) {
        id.set('' + i, {})
      }
    }
    const editor = Jodit.make("#editor", config);
    editor.value = itemDetails.description ? itemDetails.description.slice() : "";
    editor.workplace.style.cssText = 'width: auto; height: auto; min-height: 25vh; color: black';
    setEd(editor);
    setItemData(new Map(id));
    // returned function will be called on component unmount 
    return () => {
      document.querySelectorAll("[class^=jodit]").forEach(item => item.remove());
    }
  }, []);

  const handleHeaderInputValueChange = function (e) {
    setHeader(e.target.value);
    validateHeader(e.target.value);
  }

  const validateHeader = function (header) {
    if (!header) {
      setIsHeaderCorrect(false);
      return false;
    } else {
      setIsHeaderCorrect(true);
      return true;
    }
  }

  const validateSelectedImages = function (selectedImages) {
    if (selectedImages && selectedImages.length > 0) {
      setIsSelectedImagesCorrect(true);
      return true;
    } else {
      setIsSelectedImagesCorrect(false);
      return false;
    }
  }

  const saveItemDetails = function (itemData) {
    let selectedImages = Array.from(itemData.values()).filter(i => Object.keys(i).length > 0);
    if (validateHeader(header) && validateSelectedImages(selectedImages)) {
      setIsLoading(true);
      let desc = ed.value;
      if (itemDetails && itemDetails.id) {
        homePageService.updateHomePage({
          id: itemDetails.id,
          header: header,
          section: {
            path: sectionPath
          },
          description: desc,
          images: selectedImages,
          commercialData: {
            countryName: currentRegion,
            languageName: currentLanguage
            
          }
        }).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            itemDetails.id = response.id;
            itemDetails.header = header;
            itemDetails.description = desc;
            itemDetails.images = selectedImages;
            updateEventHandler(itemDetails);
            setIsLoading(false);
            setOpen(false);
          }

        }).catch(e => {
          setIsLoading(false);
        });
      } else {
        const newsItemRequest = {
          id: null,
          header: header,
          section: {
            path: sectionPath
          },
          description: desc,
          images: Array.from(itemData.values()).filter(i => Object.keys(i).length > 0),
          commercialData: {
            countryName: currentRegion,
            languageName: currentLanguage
          }
        };
        homePageService.addHomePage(newsItemRequest).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            itemDetails.id = response.id;
            itemDetails.header = header;
            itemDetails.description = desc;
            itemDetails.images = Array.from(itemData.values()).filter(i => Object.keys(i).length > 0)
            updateEventHandler(itemDetails);
            setIsLoading(false);
            setOpen(false);
          }

        }).catch(e => {
          setIsLoading(false);
        });
      }
    }
  }

  const handleEnterPressedEvent = function (e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      saveItemDetails();
    } else if (e.key === "Esc") {
      cancel();
    }
  }

  const cancel = function() {
    setOpen(false);
  }

  const handleHomePageHeaderInputChange = function(e) {
    let image = e.target.files[0];
    setIsLoading(true);
    fileService.updateHomePageHeader(image)
    .then(response => {
      if (response.error && response.status === 401) {
        exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
        setIsLoading(false);
        setOpen(false);
      } else if (response.error) {
        exceptionHandlerService.showErrorToast('Unexpected error occured');
        setIsLoading(false);
        setOpen(false);
        
      } else {
        setIsLoading(false);
        exceptionHandlerService.showInfoToast('Header image was renewed. Please refresh the page');
      }

    }).catch(e => {
      setIsLoading(false);
      setImageIdLoading('');
    });
  }

  const handleLogoInputChange = function(e) {
    let image = e.target.files[0];
    setIsLoading(true);
    fileService.updateLogo(image)
    .then(response => {
      if (response.error && response.status === 401) {
        exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
        setIsLoading(false);
        setOpen(false);
      } else if (response.error) {
        exceptionHandlerService.showErrorToast('Unexpected error occured');
        setIsLoading(false);
        setOpen(false);
        
      } else {
        setIsLoading(false);
        exceptionHandlerService.showInfoToast('Logo image was renewed. Please refresh the page');
      }

    }).catch(e => {
      setIsLoading(false);
      setImageIdLoading('');
    });
  }

  const handleHomePageAreaClick = function () {
    homePageHeaderUploadClickableArea.current.click();
  }

  const handleLogoAreaClick = function () {
    logoUploadClickableArea.current.click();
  }

  const handleImageInputChange = function (e, selectedImageId) {
    let image = e.target.files[0];
    setIsLoading(true);
    setImageIdLoading(selectedImageId);
    fileService.addTempFile(image)
    .then(response => {
      if (response.error && response.status === 401) {
        exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
        setIsLoading(false);
        setImageIdLoading('');
      } else if (response.error) {
        exceptionHandlerService.showErrorToast('Unexpected error occured');
        setIsLoading(false);
        setImageIdLoading('');
      } else {
        itemData.set(selectedImageId, response);
        setItemData(new Map(itemData));
        setIsLoading(false);
        setImageIdLoading('');
      }

    }).catch(e => {
      setIsLoading(false);
      setImageIdLoading('');
    });
  }

  const [itemData, setItemData] = useState(new Map());
    const imageUploadClickableArea = useRef(new Map());
    const homePageHeaderUploadClickableArea = useRef(null);
    const logoUploadClickableArea = useRef(null);

    const handleAreaClick = function (itemId) {
      if(!isLoading) {
        imageUploadClickableArea.current.get(itemId).click();
      }
    }

    const removeItem = function (itemId) {
      itemData.set(itemId, {});
      setItemData(new Map(itemData));
    }

  return (
    <div>
      <div>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Header:
        </Typography>

        <TextField required id="news-header" label="Header" value={header} onChange={handleHeaderInputValueChange} error={!isHeaderCorrect} />
        <br />
        <br />
      </div>
      <div>
      <Button variant="contained" color="primary" disabled={isLoading} onClickCapture={handleHomePageAreaClick} >{!isLoading && <span>Upload new header image</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
<br/>
<Button variant="contained" color="primary" disabled={isLoading} onClickCapture={handleLogoAreaClick} >{!isLoading && <span>Upload new logo</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>

      <input id='homePageHeaderInput' onChange={(e) => {handleHomePageHeaderInputChange(e)}} ref={homePageHeaderUploadClickableArea}
                type="file"
                hidden
              />
            <input id='logoInput' onChange={(e) => {handleLogoInputChange(e)}} ref={logoUploadClickableArea}
                type="file"
                hidden
              />
      <Typography component="h1" variant="h6" color="inherit" noWrap>
          Images:
        </Typography>
        {!isSelectedImagesCorrect && <div className="imagesInputErrorMsg">
          Please select at least one image
        </div>}
      <ImageList cols={6} rowHeight={164} className="photosInput">
      {Array.from(itemData.keys()).map((itemId) => {
        return <ImageListItem key={itemId}>
          {Object.keys(itemData.get(itemId)).length === 0 && <div onClick={() => handleAreaClick(itemId)} className="emptyPhotoInput">
          <input id={itemId} onChange={(e) => {handleImageInputChange(e, itemId)}} ref={(element) => imageUploadClickableArea.current.set(itemId, element)}
                type="file"
                hidden
              />
            <AddAPhoto/>{(imageIdLoading === itemId) && <CircularProgress color="primary" size={17} thickness={10} />}</div>}
          {Object.keys(itemData.get(itemId)).length !== 0 &&<div>
            
                        <div className="removeButton" onClick={() => removeItem(itemId)} ><Delete color="error"></Delete></div>
               <img
            src={'/fstor/' + itemData.get(itemId).pathSmall}
            srcSet={'/fstor/' + itemData.get(itemId).pathSmall}
            alt='tie'
            loading="lazy"
          />
          </div>          }

        </ImageListItem>

          })}
    </ImageList>

      </div>
      <div>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          Details:
        </Typography>
        <textarea id="editor" name="editor" />
      </div>
      <div>
        <Button className="submit-button" variant="contained" color="primary" disabled={isLoading} onClickCapture={() =>saveItemDetails(itemData)} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Apply</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
        <Button className="cancel-button" variant="contained" color="primary" disabled={isLoading} onClickCapture={cancel} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Cancel</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
      </div>
    </div>
  );
}

export default HomePageEditor;
