import React, { createContext, useContext } from "react";

const ShoppingCartContext = createContext({});

export const useShoppingCart = function () {
    return useContext(ShoppingCartContext)
}

const ShoppingCartContextProvider = ({ children }) => {

    const getSelectedTilesMap = function () {
        const selectedTilesString = localStorage.getItem('shoppingCartSelectedTiles');
        let selectedTilesMap = null;
        if (selectedTilesString && selectedTilesString !== "undefined") {
            selectedTilesMap = new Map(JSON.parse(selectedTilesString));
        } else {
            selectedTilesMap = new Map();
        }

        return selectedTilesMap;
    }

    const getSelectedItemDetailsMap = function () {
        const selectedItemDetailsString = localStorage.getItem('shoppingCartSelectedItemDetails');
        let selectedItemDetailsMap = null;
        if (selectedItemDetailsString && selectedItemDetailsString !== "undefined") {
            selectedItemDetailsMap = new Map(JSON.parse(selectedItemDetailsString));
        } else {
            selectedItemDetailsMap = new Map();
        }

        return selectedItemDetailsMap;
    }

    const getItemsCount = function () {
        return getSelectedTilesMap().size + getSelectedItemDetailsMap().size;
    }

    const [itemsCount, setItemsCount] = React.useState(getItemsCount());

    const addTileToCart = function (tile) {
        let selectedTilesMap = getSelectedTilesMap();
        let selectedItemDetailsMap = getSelectedItemDetailsMap();

        const commercialData = tile.commercialData;
        if (!selectedTilesMap.has(commercialData.id) && !selectedItemDetailsMap.has(commercialData.id)) {
            selectedTilesMap.set(commercialData.id, tile);
            localStorage.setItem('shoppingCartSelectedTiles', JSON.stringify(Array.from(selectedTilesMap.entries())));
            setItemsCount(itemsCount+1);
        }
    }

    const addItemDetailsToCart = function (itemDetails) {
        let selectedTilesMap = getSelectedTilesMap();
        let selectedItemDetailsMap = getSelectedItemDetailsMap();

        const commercialData = itemDetails.commercialData;
        if (!selectedTilesMap.has(commercialData.id) && !selectedItemDetailsMap.has(commercialData.id)) {
            selectedItemDetailsMap.set(commercialData.id, itemDetails);
            localStorage.setItem('shoppingCartSelectedItemDetails', JSON.stringify(Array.from(selectedItemDetailsMap.entries())));
            setItemsCount(itemsCount+1);
        }
    }


    const deleteTileFromCart = function (tile) {
        let selectedTilesMap = getSelectedTilesMap();

        const commercialData = tile.commercialData;

            selectedTilesMap.delete(commercialData.id);
            localStorage.setItem('shoppingCartSelectedTiles', JSON.stringify(Array.from(selectedTilesMap.entries())));
            setItemsCount(itemsCount-1);

    }

    const deleteItemDetailsFromCart = function (itemDetails) {
        let selectedItemDetailsMap = getSelectedItemDetailsMap();

        const commercialData = itemDetails.commercialData;

            selectedItemDetailsMap.delete(commercialData.id);
            localStorage.setItem('shoppingCartSelectedItemDetails', JSON.stringify(Array.from(selectedItemDetailsMap.entries())));
            setItemsCount(itemsCount-1);

    }

    const clearCart = function () {
        localStorage.setItem('shoppingCartSelectedItemDetails', JSON.stringify([]));
        localStorage.setItem('shoppingCartSelectedTiles', JSON.stringify([]));
        setItemsCount(0);
    }






    return (
        <ShoppingCartContext.Provider
            value={{
                addTileToCart,
                addItemDetailsToCart,
                itemsCount,
                getSelectedTilesMap,
                getSelectedItemDetailsMap,
                deleteTileFromCart,
                deleteItemDetailsFromCart,
                clearCart
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    )
}

export default ShoppingCartContextProvider;