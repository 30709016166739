import { Breadcrumbs, Button, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, Link, Paper, Radio, RadioGroup, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Checkbox } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, ArrowUpward, FlightTakeoff } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useShoppingCart } from "util/ShoppingCartContextProvider";
import './shoppingOrder.css';
import { t } from "i18next";
import SEO from "util/SEO";

function getSteps() {
    return [t('order_shipping_details'), t('order_delivery_payment_method'), t('order_summary')];
}

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const ShoppingOrderPage = ({ orderService, exceptionHandlerService }) => {


    const {
        getSelectedTilesMap,
        getSelectedItemDetailsMap,
        clearCart,
        itemsCount
    } = useShoppingCart();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        orderService.submitOrder({
            billingAddress: {
                name: name,
                surname: surname,
                address: address,
                zipCode: zipcode,
                city: city,
                country: country,
                emailAddress: email
            },
            shippingMethod: {
                id: shippingMethodId
            },
            paymentMethod: {
                id: paymentMethodId
            },
            selectedItemDetails: Array.from(getSelectedItemDetailsMap()).map(([key, value]) => value),
            selectedTiles: Array.from(getSelectedTilesMap()).map(([key, value]) => value)
        })
            .then(response => {
                if (response.error) {
                    exceptionHandlerService.showErrorToast('Unexpected error occured');
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    clearCart();
                    history.push('/');
                    exceptionHandlerService.showInfoToast('Order was submitted successfully. Please check your email');
                }
            }).catch(e => {
                setIsLoading(false);
                exceptionHandlerService.showErrorToast('Unexpected error occured');
            });
        console.log('ok');
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [shippingMethods, setShippingMethods] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [shippingMethod, setShippingMethod] = React.useState('');
    const [shippingMethodId, setShippingMethodId] = React.useState(0);
    const [paymentMethodId, setPaymentMethodId] = React.useState(0);

    const [name, setName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [zipcode, setZipCode] = React.useState('');
    const [zipcodeInputCorrect, setZipcodeInputCorrect] = React.useState(true);
    const [emailInputCorrect, setEmailInputCorrect] = React.useState(true);
    const [phone, setPhone] = React.useState('');
    const [phoneInputCorrect, setPhoneInputCorrect] = React.useState(true);
    const [totalCost, setTotalCost] = React.useState(0);
    const [totalCostWithoutDelivery, setTotalCostWithoutDelivery] = React.useState(0);
    const [totalCostCurrency, setTotalCostCurrency] = React.useState('');
    const [shippingCost, setShippingCost] = React.useState(0);
    const [shippingCostCurrency, setShippingCostCurrency] = React.useState('');
    const history = useHistory();

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleSurnameChange = (event) => {
        setSurname(event.target.value);
    }

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    }

    const handleCityChange = (event) => {
        setCity(event.target.value);
    }

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    }


    const handleEmailChange = (event) => {
        var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (event.target.value.match(validRegex)) {
            setEmailInputCorrect(true);
        } else {
            setEmailInputCorrect(false);
        }
        setEmail(event.target.value);
    }

    const handleZipCodeChange = (event) => {
        const zipcodeInput = event.target.value;
        if (zipcodeInput && +zipcodeInput !== NaN && +zipcodeInput > 0 && zipcodeInput.length <= 10) {
            setZipcodeInputCorrect(true);
        } else {
            setZipcodeInputCorrect(false);
        }
        setZipCode(event.target.value);
    }

    const handlePhoneChange = (event) => {
        const phoneInput = event.target.value;
        const phoneNumberRegex = /^\+?[0-9]+$/;
        if (phoneInput && phoneInput.length <= 14 && phoneInput.match(phoneNumberRegex)) {
            setPhoneInputCorrect(true);
        } else {
            setPhoneInputCorrect(false);
        }
        setPhone(event.target.value);
    }


    const [isLoading, setIsLoading] = React.useState(false);

    const handlePaymentMethodChange = (event) => {
        const selPaymentMethod = paymentMethods.filter(sm => sm.name === event.target.value)[0];
        console.log(selPaymentMethod);
        setPaymentMethod(event.target.value);
        setPaymentMethodId(selPaymentMethod.id);
    };
    const handleShippingMethodChange = (event) => {
        setShippingMethod(event.target.value);
        calculateTotalCostWithShipping(event.target.value);
    };

    const resolveShippingMethodLabel = (shippingMethod) => {
        return t(resolveShippingMethodName(shippingMethod.name)) + ' ' + (+shippingMethod.serviceCost > 0 ? (shippingMethod.serviceCost + ' ' + shippingMethod.serviceCostCurrency) : '');
    }

    const resolvePaymentMethodName = (name) => {
        if (name === 'PRZELEWY_24') {
            return 'order_payment_method_przelewy_24'
        }
        if (name === 'BLIK') {
            return 'order_payment_method_blik'
        }
        if (name === 'PAYMENT_UPON_RECEIPT') {
            return 'order_payment_payment_upon_receipt'
        }
        if (name === 'BANK_TRANSFER') {
            return 'order_payment_bank_transfer'
        }
    }

    const resolveShippingMethodName = (name) => {
        if (name === 'Poczta Polska') {
            return 'order_shipping_method_pp'
        }
        if (name === 'Pickup at the point (Żabka, Orlen, Ruch, Poczta Polska)') {
            return 'order_shipping_method_pickup_at_point'
        }
        if (name === 'Personal Reception') {
            return 'order_shipping_method_personal_reception'
        }
        if (name === 'Pocztex 48') {
            return 'order_shipping_method_pocztex'
        }
        if (name === 'Paczkomat InPost') {
            return 'order_shipping_method_in_post'
        }
        if (name === 'Orlen Paczka') {
            return 'order_shipping_method_orlen_paczka'
        }
    }

    const paymentMethodsJsx = paymentMethods.map(paymentMethod => <FormControlLabel value={paymentMethod.name} disabled={!paymentMethod.enabled} control={<Radio />} label={t(resolvePaymentMethodName(paymentMethod.name))} />);

    const shippingMethodsJsx = shippingMethods.map(shippingMethod => <FormControlLabel value={shippingMethod.name} disabled={!shippingMethod.enabled} control={<Radio />} label={resolveShippingMethodLabel(shippingMethod)} />);

    const calculateTotalCostWithShipping = function (selectedShippingMethod) {
        const selShippingMethod = shippingMethods.filter(sm => sm.name === selectedShippingMethod)[0];
        console.log(selShippingMethod);
        let smCost;
        let smCostCurrency;

        smCost = +selShippingMethod.serviceCost;
        smCostCurrency = selShippingMethod.serviceCostCurrency;
        setShippingMethodId(selShippingMethod.id);

        const selectedItemDetails = getSelectedItemDetailsMap();
        const selectedTiles = getSelectedTilesMap();
        let smTotalCost = 0;
        Array.from(selectedItemDetails).forEach(([key, value]) => {
            smTotalCost += +value.commercialData.cost;
        });
        Array.from(selectedTiles).forEach(([key, value]) => {
            smTotalCost += +value.commercialData.cost;
        });

        setTotalCostWithoutDelivery(smTotalCost);
        if (smCost) {
            smTotalCost += smCost;
        }
        setTotalCost(smTotalCost);
        setTotalCostCurrency('PLN');
        setShippingCost(smCost);
        setShippingCostCurrency('PLN');
    }

    useEffect(() => {
        setIsLoading(true);
        const fetchNews = async () => {
            try {
                const result = await orderService.getOrderPageMetadata();
                if (result?.paymentMethods?.length > 0) {
                    setPaymentMethods(result?.paymentMethods);
                }
                if (result?.shippingMethods?.length > 0) {
                    setShippingMethods(result?.shippingMethods);
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        };

        fetchNews();

    }, []);

    const [siteRulesAccepted, setSiteRulesAccepted] = React.useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = React.useState(false);
    const handleSiteRulesAcceptanceChange = (event) => {
        setSiteRulesAccepted(event.target.checked);
    }
    const handlePrivacyPolicyAcceptanceChange = (event) => {
        setPrivacyPolicyAccepted(event.target.checked);
    }

    return (<>
    <SEO title={t('breadcrumbs_order')} description={t('breadcrumbs_order')} name="Pearl Art Store" image={null}/>
        <Container maxWidth="lg" className="breadcrumbs">
            <Grid key="breadcrumbs" item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        {t('breadcrumbs_home')}
                    </Link>
                    <Link underline="hover" color="inherit" href="/cart">
                        {t('breadcrumbs_cart')}
                    </Link>
                    <Typography color="primary">{t('breadcrumbs_order')}</Typography>
                </Breadcrumbs>

            </Grid>

        </Container>

        <Container className="container">
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div className="content">

                {activeStep == 0 &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="item-content-header">
                            <Typography variant="h6" color="primary" gutterBottom>{t('order_billing_address')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="name" label={t('order_billing_address_name')} onChange={handleNameChange} value={name} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="surname" label={t('order_billing_address_surname')} onChange={handleSurnameChange} value={surname} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField className="billing-address-input" variant="outlined" required id="address" label={t('order_billing_address_address')} onChange={handleAddressChange} value={address} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="zipcode" label={t('order_billing_address_zipcode')} onChange={handleZipCodeChange} value={zipcode} error={!zipcodeInputCorrect} />
                            {!zipcodeInputCorrect && <Typography color='error'>{t('order_billing_address_zipcode_error')}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="city" label={t('order_billing_address_city')} onChange={handleCityChange} value={city} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="country" label={t('order_billing_address_country')} onChange={handleCountryChange} value={country} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" variant="outlined" required id="email" label={t('order_billing_address_email')} onChange={handleEmailChange} value={email} error={!emailInputCorrect} />
                            {!emailInputCorrect && <Typography color='error'>{t('order_billing_address_email_error')}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField className="billing-address-input" required variant="outlined" id="phone" label={t('order_billing_address_phone')} onChange={handlePhoneChange} value={phone} error={!phoneInputCorrect} />
                            {!phoneInputCorrect && <Typography color='error'>{t('order_billing_address_phone_error')}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>

                        </Grid>
                    </Grid>}
                {activeStep == 1 &&
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={8} md={8}>
                            <Typography variant="h6" color="primary" gutterBottom>{t('order_shipping_method')}</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" value={shippingMethod} onChange={handleShippingMethodChange}>
                                    {shippingMethodsJsx}
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant="h6" color="primary" gutterBottom>{t('order_payment_method')}</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" value={paymentMethod} onChange={handlePaymentMethodChange}>
                                    {paymentMethodsJsx}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                {activeStep == 2 &&
                    <Grid container spacing={1}>

                        <Grid item xs={12} sm={9} md={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className="item-content-header">
                                    <Typography variant="h6" color="primary" gutterBottom>{t('order_billing_details')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_name')}: {name}
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_surname')}: {surname}
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_address')}: {address}
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_zipcode')}: {zipcode}
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_city')}: {city}
                                </Grid>
                                <Grid item xs={12}>
                                    {t('order_billing_address_country')}:  {country}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className="item-content-header">
                                    <Typography variant="h6" color="primary" gutterBottom>{t('order_shipping_method')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {resolveShippingMethodLabel(shippingMethods.filter(sm => sm.name === shippingMethod)[0])}
                                </Grid>

                                <Grid item xs={12} className="item-content-header">
                                    <Typography variant="h6" color="primary" gutterBottom>{t('order_payment_method')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {t(resolvePaymentMethodName(paymentMethods.filter(pm => pm.name === paymentMethod)[0].name))}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h6" color="primary" gutterBottom>{t('order_payment_details')}</Typography>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('order_payment_details_item')}</TableCell>
                                            <TableCell align="right">{t('order_payment_details_count')}</TableCell>
                                            <TableCell align="right">{t('order_payment_details_cost')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from(getSelectedTilesMap()).map(([key, value]) => (
                                            <TableRow
                                                key={value.header}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {value.header}
                                                </TableCell>
                                                <TableCell align="right">1</TableCell>
                                                <TableCell align="right">{value.commercialData.cost + ' ' + value.commercialData.currency}</TableCell>
                                            </TableRow>
                                        ))}
                                        {Array.from(getSelectedItemDetailsMap()).map(([key, value]) => (
                                            <TableRow
                                                key={value.header}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {value.header}
                                                </TableCell>
                                                <TableCell align="right">1</TableCell>
                                                <TableCell align="right">{value.commercialData.cost + ' ' + value.commercialData.currency}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={3} />
                                            <TableCell colSpan={1}>{t('order_payment_details_total')}</TableCell>
                                            <TableCell align="right">{totalCostWithoutDelivery + ' ' + shippingCostCurrency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1}>{t('order_payment_details_delivery_cost')}</TableCell>
                                            <TableCell align="right">{shippingCost + ' ' + shippingCostCurrency}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1}>{t('order_payment_details_total')} + {t('order_payment_details_delivery_cost')}</TableCell>
                                            <TableCell align="right">{totalCost + ' ' + totalCostCurrency}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControlLabel control={<Checkbox
                                        checked={siteRulesAccepted}
                                        onChange={handleSiteRulesAcceptanceChange}
                                        name="menu"
                                        color="primary" />} label={<>{t('order_accept_site_rules')}<Link color="secondary" href="/footer-left/termsAndConditions">{t('order_accept_site_rules_link')}</Link></> } />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControlLabel control={<Checkbox
                                        checked={privacyPolicyAccepted}
                                        onChange={handlePrivacyPolicyAcceptanceChange}
                                        name="menu"
                                        color="primary" />} label={<>{t('order_accept_privacy_policy')}<Link color="secondary" href="/footer-left/privacyPolicy">{t('order_accept_privacy_policy_link')}</Link></> } />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <div style={{wordWrap: "break-word"}}>{t('order_user_notice')}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
            <Grid container spacing={1} className="buttons">
                <Grid item xs={6}>
                    <Button className="buttons-left"
                        disabled={activeStep === 0}
                        onClick={handleBack} color="primary" variant="contained"
                    >
                        <ArrowBackIos></ArrowBackIos> {t('order_back')}
                    </Button>
                </Grid>
                <Grid item xs={6}>

                    {activeStep !== steps.length - 1 &&
                        <Button className="buttons-right" variant="contained" color='primary' onClick={handleNext} disabled={isLoading || itemsCount === 0 || !((activeStep == 0 && name !== '' && surname !== '' && address !== '' && zipcode !== '' && zipcodeInputCorrect === true && phoneInputCorrect === true && city !== '' && country !== '' && email !== '' && emailInputCorrect === true) || (activeStep == 1 && paymentMethod !== '' && shippingMethod !== ''))}>

                            {!isLoading && <>{t('order_next')} <ArrowForwardIos></ArrowForwardIos></>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}
                        </Button>
                    }

                    {activeStep === steps.length - 1 &&
                        <Button className="buttons-right-submit" variant="contained" color='secondary' onClick={handleSubmit} disabled={isLoading || itemsCount === 0 || !siteRulesAccepted || !privacyPolicyAccepted}>
                            {!isLoading && <>{t('order_submit')} <FlightTakeoff></FlightTakeoff></>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}
                        </Button>

                    }
                </Grid>
            </Grid>



        </Container>

    </>);
}

export default ShoppingOrderPage;