class SectionCacheUtils {

    static add = function (section) {
        if (section?.path) {
            const sectionsCacheString = localStorage.getItem('sectionsCache');
            let sectionsCacheMap = null;
            if (sectionsCacheString && sectionsCacheString !== "undefined") {
                sectionsCacheMap = new Map(JSON.parse(sectionsCacheString));
            } else {
                sectionsCacheMap = new Map();
            }

            sectionsCacheMap.set(section.path, section);
            localStorage.setItem('sectionsCache', JSON.stringify(Array.from(sectionsCacheMap.entries())));
        } else {
            console.log("Section or path is empty. Section name" + section?.name)
        }
    }

    static addAll = function (sectionList) {
        if (sectionList) {
            sectionList.forEach(section => {
                SectionCacheUtils.add(section);
            });
        }

    }

    static getByPath = function (sectionPath) {
        const sectionsCacheString = localStorage.getItem('sectionsCache');
        if (sectionsCacheString && sectionsCacheString !== "undefined") {
            const sectionsCacheMap = new Map(JSON.parse(sectionsCacheString));
            return sectionsCacheMap.get(sectionPath);
        }

        return null;
    }
}

export default SectionCacheUtils;