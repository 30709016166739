class SectionService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveRootSections(country) {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/sections/root?country=${country}`);
    }

    async retrieveSectionsByPathAndCountry(sectionPath, country) {
        const resolvedSectionPath = sectionPath ? sectionPath : null;
        const resolvedCountry = country ? country : null;
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/sections?sectionPath=${resolvedSectionPath}&country=${resolvedCountry}`);
    }

    retrieveAllSections() {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/sections`);
    }

    addSection(section) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(section)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/sections', requestOptions);
    }

    async deleteSection(sectionId) {
        /*TODO: replace with reverse proxy */
        const requestOptions = {
            method: 'DELETE',
            body: {}
        };
        return this.httpRequestExecutor.execute(`/api/sections/${sectionId}`, requestOptions);
    }

}

export default SectionService;