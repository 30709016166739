// @ts-ignore
import { Button, Slider, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Paper, Select, TextField, Typography, Switch, Checkbox, FormControlLabel, TimelineIcon } from '@material-ui/core';
import { Add, ContactMail, Dashboard, Delete, Edit, Home, Info, Subject, Timeline } from '@material-ui/icons';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { t } from 'i18next';
import React from 'react'
// @ts-ignore
import { NavLink, useHistory } from 'react-router-dom';
import AuthorityUtils from 'util/AuthorityUtils';
import { useLanguageAndRegionContext } from 'util/LanguageAndRegionContextProvider';
import './SectionBar.css';
import ConfirmationDialog from 'util/ConfirmationDialog';
import SectionUtils from 'util/SectionUtils';

const SectionBar = ({ sectionService, tileService, exceptionHandlerService, handleSectionItemClick, sectionsInput }) => {
    const history = useHistory();
    const resolveSectionIcon = function (iconName) {
        switch (iconName) {
            case 'home': {
                return (<Home />);
            }
            case 'dashboard': {
                return (<Dashboard />);
            }
            case 'info': {
                return (<Info />);
            }
            case 'contactMail': {
                return (<ContactMail />);
            }
            default: {
                return (<Dashboard />);
            }
        }
    }

    const [sections, setSections] = React.useState(sectionsInput ? sectionsInput : []);
    const [tilesSections, setTilesSections] = React.useState([]);
    const [allSections, setAllSections] = React.useState([]);
    const [parentSectionTiles, setParentSectionTiles] = React.useState([]);
    const [parentSectionTilesLoading, setParentSectionTilesLoading] = React.useState(false);
    const [isSectionListLoading, setIsSectionListLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [editSectionWindowOpen, setEditSectionWindowOpen] = React.useState(false);
    // @ts-ignore
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [sectionsListLoading, setSectionsListLoading] = React.useState(false);
    const [sectionName, setSectionName] = React.useState('');
    const [sectionNameInputCorrect, setSectionNameInputCorrect] = React.useState(true);
    const [sectionPath, setSectionPath] = React.useState('');
    const [tilesPerLine, setTilesPerLine] = React.useState(3);
    const [sectionType, setSectionType] = React.useState('tiles');
    const [parentSection, setParentSection] = React.useState('None');
    const [editableSection, setEditableSection] = React.useState({});
    const [parentSectionTile, setParentSectionTile] = React.useState('None');
    const [sectionPathInputCorrect, setSectionPathInputCorrect] = React.useState(true);
    const [tilesPerLineInputCorrect, setTilesPerLineInputCorrect] = React.useState(true);
    const [pageSizeInputCorrect, setPageSizeInputCorrect] = React.useState(true);
    const [duplicatePathFound, setDuplicatePathFound] = React.useState(false);
    const [sectionIcon, setSectionIcon] = React.useState('dashboard');
    const [parentSectionTileShouldBeSelected, setParentSectionTileShouldBeSelected] = React.useState(false);
    const [atLeastOneGroupShouldBeSelected, setAtLeastOneGroupShouldBeSelected] = React.useState(false);
    const [sectionDeleteWarningOpen, setSectionDeleteWarningOpen] = React.useState(false);
    const [sectionGroupCheckboxState, setSectionGroupCheckboxState] = React.useState({
        menu: true,
        footerLeft: false,
        footerMiddle: false,
        footerRight: false,
    });
    const {
        // @ts-ignore
        currentRegion
    } = useLanguageAndRegionContext();
    const [country, setCountry] = React.useState(currentRegion);
    const [countryInputValid, setCountryInputValid] = React.useState(true);

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setCountryInputValid(true);
    };


    const handleClose = function () {
        resetModalState();

    }

    const validateInputs = function (sectionNameInputCorrectCustom, sectionPathInputCorrectCustom) {
        let sectionNameICorrect = false;
        let sectionPathICorrect = false;
        if (typeof sectionNameInputCorrectCustom === "boolean") {
            sectionNameICorrect = sectionNameInputCorrectCustom;
        } else {
            sectionNameICorrect = sectionNameInputCorrect;
        }
        if (typeof sectionPathInputCorrectCustom === "boolean") {
            sectionPathICorrect = sectionPathInputCorrectCustom;
        } else {
            sectionPathICorrect = sectionPathInputCorrect;
        }
        return tilesPerLineInputCorrect && sectionNameICorrect && sectionPathICorrect && countryInputValid;
    }

    const handleSectionNameInputValueChange = function (e) {
        setSectionNameInputCorrect(nameInputCorrect(e.target.value));
        setSectionName(e.target.value);
    }

    const handleSectionPathInputValueChange = function (e) {
        setSectionPathInputCorrect(textFieldValueCorrect(e.target.value));
        setSectionPath(e.target.value);
    }

    const nameInputCorrect = function (val) {
        var pattern = /^[A-Za-z0-9- ]+$/;
        if (val && val.length > 0 && val.length < 50 && val.match(pattern)) {
            return true;
        }

        return false;
    }

    const textFieldValueCorrect = function (val) {
        var pattern = /^[A-Za-z0-9-]+$/;
        if (val && val.length > 0 && val.length < 50 && val.match(pattern)) {
            return true;
        }

        return false;
    }

    const handleTilesPerLineInputValueChange = function (e) {
        const tillesPerLineInput = e.target.value;
        // @ts-ignore
        if (tillesPerLineInput && +tillesPerLineInput !== NaN && +tillesPerLineInput > 0 && +tillesPerLineInput <= 4) {
            setTilesPerLineInputCorrect(true);
        } else {
            setTilesPerLineInputCorrect(false);
        }
        setTilesPerLine(e.target.value);
    }
    
    const handlePageSizeInputValueChange = function (e) {
        const pageSizeInput = e.target.value;
        // @ts-ignore
        if (pageSizeInput && +pageSizeInput !== NaN && +pageSizeInput > 0 && +pageSizeInput <= 100) {
            setPageSizeInputCorrect(true);
        } else {
            setPageSizeInputCorrect(false);
        }
        setPageSize(pageSizeInput);
    }

    const handleSectionDeleteWarningOpen = (section) => {
        setEditableSection(section);
        setSectionDeleteWarningOpen(true);
    }

    const handleSectionDeleteWarningProceed = () => {
        setSectionDeleteWarningOpen(false);
        deleteSection(editableSection.id);
        resetModalState();
    }

    const handleSectionDeleteWarningCancel = () => {
        setSectionDeleteWarningOpen(false);
        resetModalState();
    }

    const deleteSection = function (sectionId) {
        setIsSectionListLoading(true);
        // @ts-ignore
        sectionService.deleteSection(sectionId).then(res => {
            setSections(sections.filter(s => s.id !== sectionId));
            setIsSectionListLoading(false);
        }).catch(e => {
            console.log(e);
            setIsSectionListLoading(false);
        });
    }


    const resetModalState = function () {
        setFieldDefaults();
        setOpen(false);
        setEditSectionWindowOpen(false);
        setEditableSection({});
    }

    const setFieldDefaults = function () {
        setSectionNameInputCorrect(true);
        setSectionPathInputCorrect(true);
        setSectionName('');
        setSectionPath('');
        setParentSectionTile('None');
        setParentSection('None');
        setParentSectionTiles([]);
        setSectionIcon('dashboard');
        setTilesPerLine(3);
        setTilesPerLineInputCorrect(true);
        setCustomTileHeightEnabled(false);
        setShadeIfNoDescendants(true);
        setIsFlattened(false);
        setPaginationTopEnabled(false);
        setPaginationBottomEnabled(false);
        setPageSize(null);
        setPageSizeInputCorrect(true);
        setOrderingEnabled(false);
        setCustomTileHeightScale(50);
        setDuplicatePathFound(false);
        setParentSectionTileShouldBeSelected(false);
        setAtLeastOneGroupShouldBeSelected(false);
        setPostHeightScale(10);
        setPostStaticHeightEnabled(false);
        setSectionGroupCheckboxState({
            menu: true,
            footerLeft: false,
            footerMiddle: false,
            footerRight: false,
        });
    }

    // @ts-ignore
    const handleSectionIconSelect = function (event, newIcon) {
        setSectionIcon(newIcon);
    }

    const loadAvailableSections = function () {
        if (tilesSections.length === 0 || allSections.length === 0) {
            setSectionsListLoading(true);
            sectionService.retrieveAllSections().then(result => {
                setTilesSections(result.filter(s => s.type == "tiles"));
                setAllSections(result);
                setSectionsListLoading(false);
            }).catch(e => {
                console.log(e);
                setSectionsListLoading(false);
            });
        }

    }


    const loadAvailableSectionTiles = function () {
        if (parentSection && parentSection !== 'None') {
            let parentSectionFiltered = tilesSections.filter(s => s.id === parentSection);
            let parentSectionPath = null;
            if (parentSectionFiltered && parentSectionFiltered.length > 0) {
                parentSectionPath = parentSectionFiltered[0].path;
            }
            if (parentSectionPath) {
                setParentSectionTilesLoading(true);
                tileService.retrieveTilesBySectionPathAndCountry(parentSectionPath, currentRegion).then(result => {
                    setParentSectionTiles(result);
                    setParentSectionTilesLoading(false);
                }).catch(e => {
                    console.log(e);
                    setParentSectionTilesLoading(false);
                });
            }
        }

    }

    const handleUpdate = function () {
        if (sectionGroupCheckboxState.footerLeft === false
            && sectionGroupCheckboxState.footerMiddle === false
            && sectionGroupCheckboxState.footerRight === false
            && sectionGroupCheckboxState.menu === false
            && (parentSection === "None" || parentSection === '' || !parentSection)) {
            setAtLeastOneGroupShouldBeSelected(true);
        } else {
            // @ts-ignore
            handleSaveUpdate(editableSection.id, editableSection.type, editableSection.isRoot);

        }
    }

    const handleSave = function () {
        if (sectionGroupCheckboxState.footerLeft === false
            && sectionGroupCheckboxState.footerMiddle === false
            && sectionGroupCheckboxState.footerRight === false
            && sectionGroupCheckboxState.menu === false
            && (parentSection === "None" || parentSection === '' || !parentSection)) {
            setAtLeastOneGroupShouldBeSelected(true);
        } else if (parentSectionTile === 'None' && parentSection !== 'None') {
            setParentSectionTileShouldBeSelected(true);
        } else {
            handleSaveUpdate(null, null, null);
        }
    }

    const handleSaveUpdate = function (sectionId, type, isRootUpd) {
        const sectionNameCorrect = nameInputCorrect(sectionName);
        const sectionPathCorrect = textFieldValueCorrect(sectionPath);
        setSectionNameInputCorrect(sectionNameCorrect);
        setSectionPathInputCorrect(sectionPathCorrect);
        const isValid = validateInputs(sectionNameCorrect, sectionPathCorrect);
        if (!isValid) {
            return;
        }
        let parentSectionObj = null;
        let parentTileObj = null;
        let isRoot = isRootUpd ? isRootUpd : true;
        if (parentSection && parentSection !== 'None') {
            parentSectionObj = {
                id: parentSection
            }
            parentTileObj = {
                id: parentSectionTile
            }
            isRoot = false;
        }
        let groups = [];
        for (let group in sectionGroupCheckboxState) {
            if (sectionGroupCheckboxState[group] === true) {
                groups.push(group);
            }
        }
        setSaveLoading(true);
        let sectionConfigs = null;
        if (sectionType === "tiles") {
            sectionConfigs = [{
                key: "tilesPerLine",
                value: '' + tilesPerLine
            }, {
                key: "shadeIfNoDescendants", value: '' + shadeIfNoDescendants
            }, {
                key: "isFlattened", value: '' + isFlattened
            },
            {
                key: "orderingEnabled", value: '' + orderingEnabled
            },
            {
                key: "paginationTopEnabled", value: '' + paginationTopEnabled
            },
            {
                key: "paginationBottomEnabled", value: '' + paginationBottomEnabled
            }];

            if (customTileHeightEnabled === true) {
                sectionConfigs.push({
                    key: "customTileHeightScale",
                    value: '' + (customTileHeightScale / 100)
                });
                sectionConfigs.push(
                    {
                        key: "customTileHeightEnabled",
                        value: '' + customTileHeightEnabled
                    });
            }
            if(paginationTopEnabled  === true || paginationBottomEnabled === true) {
                sectionConfigs.push(
                    {
                        key: "pageSize",
                        value: '' + pageSize
                    });
            }
        }
        if (sectionType === "posts" && postStaticHeightEnabled === true) {
            sectionConfigs = [{
                key: "postHeightScale",
                value: '' + (postHeightScale / 100)
            },

            {
                key: "postStaticHeightEnabled",
                value: '' + postStaticHeightEnabled
            }];
        }

        sectionService.addSection({
            id: sectionId,
            name: sectionName,
            parentSection: parentSectionObj,
            parentTile: parentTileObj,
            path: sectionPath,
            type: type ? type : sectionType,
            sectionConfigs: sectionConfigs,
            icon: sectionIcon,
            isRoot: isRoot,
            countryName: country,
            groups: groups
        }).then(response => {
            if (response.error && response.status === 401) {
                exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
                resetModalState();
            } else if (response.error) {
                if (response.message && response.message === 'Provided section path already used by another section') {
                    setDuplicatePathFound(true);
                } else {
                    exceptionHandlerService.showErrorToast('Unexpected error occured');
                    resetModalState();
                }

            } else {
                if (isRoot) {
                    setSections([...sections, response]);
                }
                resetModalState();
            }

        }).catch(e => {
            console.log(e);
            resetModalState();
        });
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleEditSectionClickOpen = () => {
        setEditSectionWindowOpen(true);
    };
    const handleViewStatisticsClickOpen = () => {
        history.push("/statistics")
    };
    const handleOrderManagementClickOpen = () => {
        history.push("/order-management")
    }
    const handleChange = (event) => {
        setSectionType(event.target.value);
    };
    const handleGroupInputChange = (event) => {
        setSectionGroupCheckboxState({ ...sectionGroupCheckboxState, [event.target.name]: event.target.checked });
    };
    const handleParentSectionChange = (event) => {
        setParentSection(event.target.value);
        setParentSectionTile('None');
    };

    const handleEditableSectionChange = (value) => {
        setEditableSection(value);
        let parSection = value.parentSection;
        if (value && parSection && parSection.id) {
            setParentSection(parSection.id);
        } else {
            setParentSection('');
        }
        if (value && value.parentTile && value.parentTile.id) {

            if (parSection) {
                if (parSection.path) {
                    setParentSectionTilesLoading(true);
                    tileService.retrieveTilesBySectionPathAndCountry(parSection.path, currentRegion).then(result => {

                        setParentSectionTiles(result);
                        setParentSectionTilesLoading(false);
                        setParentSectionTile(value.parentTile.id);
                    }).catch(e => {
                        console.log(e);
                        setParentSectionTilesLoading(false);
                    });
                }
            }
        } else {
            setParentSectionTile('None');
        }
        if (value && value.type) {
            setSectionType(value.type);
        }
        if (value && value.groups) {
            let sectionGroupCheckboxStateLocal = { ...sectionGroupCheckboxState };
            for (let group in sectionGroupCheckboxStateLocal) {
                sectionGroupCheckboxStateLocal[group] = false;
            }
            value.groups.forEach(group => {
                sectionGroupCheckboxStateLocal[group] = true;
            });
            setSectionGroupCheckboxState(sectionGroupCheckboxStateLocal);
        }

        if (value && value.name) {
            setSectionName(value.name);
        } else {
            setSectionName('');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'tilesPerLine').length > 0) {
            setTilesPerLine(value.sectionConfigs.filter(sc => sc.key === 'tilesPerLine')[0].value);
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'pageSize').length > 0) {
            setPageSize(value.sectionConfigs.filter(sc => sc.key === 'pageSize')[0].value);
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled').length > 0) {
            setCustomTileHeightEnabled(value.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants').length > 0) {
            setShadeIfNoDescendants(value.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'isFlattened').length > 0) {
            setIsFlattened(value.sectionConfigs.filter(sc => sc.key === 'isFlattened')[0].value === 'true');
        }

        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'paginationTopEnabled').length > 0) {
            setPaginationTopEnabled(value.sectionConfigs.filter(sc => sc.key === 'paginationTopEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'paginationBottomEnabled').length > 0) {
            setPaginationBottomEnabled(value.sectionConfigs.filter(sc => sc.key === 'paginationBottomEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'orderingEnabled').length > 0) {
            setOrderingEnabled(value.sectionConfigs.filter(sc => sc.key === 'orderingEnabled')[0].value === 'true');
        }

        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale').length > 0) {
            setCustomTileHeightScale(+value.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale')[0].value * 100);
        }

        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled').length > 0) {
            setPostStaticHeightEnabled(value.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'postHeightScale').length > 0) {
            setPostHeightScale(+value.sectionConfigs.filter(sc => sc.key === 'postHeightScale')[0].value * 100);
        }


        if (value && value.icon) {
            setSectionIcon(value.icon);
        } else {
            setSectionIcon('');
        }
        if (value && value.path && value.path.length > 0) {
            const p = value.path.split('/').slice(-1)[0];
            setSectionPath(p);
        } else {
            setSectionPath('');
        }
        if (value && value.countryName && value.countryName.length > 0) {
            setCountry(value.countryName);
        }
    };

    const handleParentSectionTileChange = (event) => {
        setParentSectionTile(event.target.value);
    };

    const editableSectionSelected = () => {
        return editableSection && Object.keys(editableSection).length !== 0
    }

    const [customTileHeightEnabled, setCustomTileHeightEnabled] = React.useState(false);
    const [customTileHeightScale, setCustomTileHeightScale] = React.useState(10);

    const [shadeIfNoDescendants, setShadeIfNoDescendants] = React.useState(true);

    const [isFlattened, setIsFlattened] = React.useState(false);
    const [paginationTopEnabled, setPaginationTopEnabled] = React.useState(false);
    const [paginationBottomEnabled, setPaginationBottomEnabled] = React.useState(false);
    const [orderingEnabled, setOrderingEnabled] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(null)

    const [postStaticHeightEnabled, setPostStaticHeightEnabled] = React.useState(false);
    const [postHeightScale, setPostHeightScale] = React.useState(10);


    const handleSwitch = (e) => {
        setCustomTileHeightEnabled(e.target.checked);
    }
    const handleShadeIfNoDescendantsSwitch = (e) => {
        setShadeIfNoDescendants(e.target.checked);
    }
    const handleIsFlattenedSwitch = (e) => {
        setIsFlattened(e.target.checked);
    }
    const handlePaginationTopEnabledSwitch = (e) => {
        setPaginationTopEnabled(e.target.checked);
    }
    const handlePaginationBottomEnabledSwitch = (e) => {
        setPaginationBottomEnabled(e.target.checked);
    }
    const handleOrderingEnabledSwitch = (e) => {
        setOrderingEnabled(e.target.checked);
    }

    // @ts-ignore
    const handleSliderChange = (e, value) => {
        if (value) {
            setCustomTileHeightScale(value);
        }
    }
    const handlePostSwitch = (e) => {
        setPostStaticHeightEnabled(e.target.checked);
    }
    // @ts-ignore
    const handlePostSliderChange = (e, value) => {
        if (value) {
            setPostHeightScale(value);
        }
    }

    return (
        <div>
            {isSectionListLoading && <CircularProgress color="primary" size={37} thickness={10} />}
            {!isSectionListLoading && (!sections || (sections && sections.length === 0)) && <div>No Data to display</div>}

            <List>
                {!isSectionListLoading && sections && sections.length > 0 && sections
                    .filter(section => SectionUtils.shouldShowSection(section.requiredAuthority?.name, section.groups))
                    .map(section => (
                        <ListItem key={section.id} button component={NavLink} to={"/_/" + section.path} onClick={handleSectionItemClick}>
                            <ListItemIcon>
                                {resolveSectionIcon(section.icon)}
                            </ListItemIcon>
                            <ListItemText primary={section.name} />
                            {AuthorityUtils.userHasAuthority("UPDATE_SECTION") && <ListItemSecondaryAction>
                                <IconButton className="delete-section-button" edge="end" aria-label="delete" onClick={() => handleSectionDeleteWarningOpen(section)}>
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>}
                        </ListItem>
                    ))}
            </List>
            <Divider />
            {AuthorityUtils.userHasAuthority("UPDATE_SECTION") &&
                <List>
                    <ListSubheader inset>Admin panel</ListSubheader>
                    <ListItem button onClick={handleClickOpen}>
                        <ListItemIcon>
                            <Add />
                        </ListItemIcon>
                        <ListItemText primary={t('admin_add_new_section')} />
                    </ListItem>
                    <ListItem button onClick={handleEditSectionClickOpen}>
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText primary={t('admin_edit_section')} />
                    </ListItem>
                    <ListItem button onClick={handleViewStatisticsClickOpen}>
                        <ListItemIcon>
                            <Timeline />
                        </ListItemIcon>
                        <ListItemText primary={t('admin_view_statistics')} />
                    </ListItem>
                    <ListItem button onClick={handleOrderManagementClickOpen}>
                        <ListItemIcon>
                            <Subject />
                        </ListItemIcon>
                        <ListItemText primary={t('admin_order_management')} />
                    </ListItem>
                </List>}



            <Dialog disableBackdropClick onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" >
                    Add new section
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={1} xs={12}>
                        <Grid item xs={4}>
                            <InputLabel id="section-type-select-label">Type</InputLabel>
                            <Select
                                labelId="section-type-select-label"
                                id="demo-simple-select"
                                value={sectionType}
                                onChange={handleChange}>
                                <MenuItem value="tiles">Tiles</MenuItem>
                                <MenuItem value="posts">Posts</MenuItem>
                                <MenuItem value="post">Post</MenuItem>
                                <MenuItem value="details">Item Details</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="parent-section-select-label">Parent section</InputLabel>
                            <Select
                                labelId="parent-section-select-label"
                                id="parent-section-select"
                                onOpen={loadAvailableSections}
                                value={parentSection}
                                onChange={handleParentSectionChange}>
                                <MenuItem value="None">None</MenuItem>
                                {sectionsListLoading && <MenuItem value="Loading">Loading data...</MenuItem>}
                                {!sectionsListLoading &&
                                    tilesSections.map(section => (
                                        <MenuItem key={section.id} value={section.id}>{section.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="parent-section-tile-select-label">Parent section tile</InputLabel>
                            <Select
                                error={parentSectionTileShouldBeSelected}
                                labelId="parent-section-tile-select-label"
                                id="parent-section-tile-select"
                                onOpen={loadAvailableSectionTiles}
                                value={parentSectionTile}
                                onChange={handleParentSectionTileChange}
                                disabled={parentSection === 'None'}>
                                <MenuItem value="None">None</MenuItem>
                                {parentSectionTilesLoading && <MenuItem value="Loading">Loading data...</MenuItem>}
                                {!parentSectionTilesLoading &&
                                    parentSectionTiles.map(t => (
                                        <MenuItem key={t.id} value={t.id}>{t.header}</MenuItem>
                                    ))
                                }
                            </Select>
                            {parentSectionTileShouldBeSelected && <Typography color='error'>Parent section tile should be selected</Typography>}

                        </Grid>

                        <Grid item xs={6}>
                            <Typography>Section Icon:</Typography>
                            <ToggleButtonGroup
                                value={sectionIcon}
                                exclusive
                                onChange={handleSectionIconSelect}
                                aria-label="Section Icon"
                            >
                                <ToggleButton disabled={parentSection !== 'None'} value="dashboard" aria-label="Dashboard">
                                    <Dashboard />
                                </ToggleButton>
                                <ToggleButton disabled={parentSection !== 'None'} value="home" aria-label="Home">
                                    <Home />
                                </ToggleButton>
                                <ToggleButton disabled={parentSection !== 'None'} value="info" aria-label="Info">
                                    <Info />
                                </ToggleButton>
                                <ToggleButton disabled={parentSection !== 'None'} value="contactMail" aria-label="ContactMail">
                                    <ContactMail />
                                </ToggleButton>
                            </ToggleButtonGroup>

                        </Grid>
                        <Grid item xs={6}>
                            Appeared in:
                            <Grid item xs={12}>
                                <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                                    checked={sectionGroupCheckboxState.menu}
                                    onChange={handleGroupInputChange}
                                    name="menu"
                                    color="primary" />} label='Menu' />
                            </Grid>
                            <Grid item xs={12}>                     <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerLeft}
                                onChange={handleGroupInputChange}
                                name="footerLeft"
                                color="primary"
                            />} label='Footer Left' /></Grid>
                            <Grid item xs={12}> <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerMiddle}
                                onChange={handleGroupInputChange}
                                name="footerMiddle"
                                color="primary"
                            />} label='Footer Middle' /></Grid>
                            <Grid item xs={12}>  <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerRight}
                                onChange={handleGroupInputChange}
                                name="footerRight"
                                color="primary"
                            />} label='Footer Right' /></Grid>
                            {atLeastOneGroupShouldBeSelected && <Typography color='error'>At Least one place should be selected</Typography>}

                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="section-name" label="Section Name" value={sectionName} onChange={handleSectionNameInputValueChange} error={!sectionNameInputCorrect} />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="section-path" label="Section Path" value={sectionPath} onChange={handleSectionPathInputValueChange} error={!sectionPathInputCorrect || duplicatePathFound} />
                            {duplicatePathFound && <Typography color='error'>Specified path is already used by another section</Typography>}

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="filled-select-country"
                                select
                                label="Country"
                                value={country}
                                onChange={handleCountryChange}
                                helperText="Please select your country"
                                variant="filled"
                                className={`${countryInputValid ? '' : 'error'}`}
                            >
                                <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                                <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                                <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                                <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
                            </TextField>
                        </Grid>

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Is Flattened:</Typography>
                            <Switch checked={isFlattened} onChange={handleIsFlattenedSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Pagination Top Enabled:</Typography>
                            <Switch checked={paginationTopEnabled} onChange={handlePaginationTopEnabledSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Pagination Bottom Enabled:</Typography>
                            <Switch checked={paginationBottomEnabled} onChange={handlePaginationBottomEnabledSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                        <TextField required id="page-size" label="Page Size" value={pageSize} onChange={handlePageSizeInputValueChange} error={!pageSizeInputCorrect} disabled={!paginationBottomEnabled && !paginationTopEnabled}/>
                            {!pageSizeInputCorrect && <Typography color='error'>Page size should be between 1 and 100</Typography>}
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Ordering Enabled:</Typography>
                            <Switch checked={orderingEnabled} onChange={handleOrderingEnabledSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <TextField required id="tiles-per-line" label="Number Of Tiles Per Line" value={tilesPerLine} onChange={handleTilesPerLineInputValueChange} error={!tilesPerLineInputCorrect} />
                            {!tilesPerLineInputCorrect && <Typography color='error'>Number of tiles per line should be between 1 and 4</Typography>}
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Shade if no descendants:</Typography>
                            <Switch checked={shadeIfNoDescendants} onChange={handleShadeIfNoDescendantsSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Custom Tile Height Enabled:</Typography>
                            <Switch checked={customTileHeightEnabled} onChange={handleSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                            <Typography>Custom Tile Height Scale ( % of screen height):</Typography>
                            <Slider style={{ width: 200 }} disabled={!customTileHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handleSliderChange} value={customTileHeightScale} />
                        </Grid>}

                        {sectionType && sectionType === "posts" && <Grid item xs={12}>
                            <Typography>Post Static Height Enabled:</Typography>
                            <Switch checked={postStaticHeightEnabled} onChange={handlePostSwitch} />
                        </Grid>}

                        {sectionType && sectionType === "posts" && <Grid item xs={12}>
                            <Typography>Post Height Scale ( % of screen height):</Typography>
                            <Slider style={{ width: 200 }} disabled={!postStaticHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handlePostSliderChange} value={postHeightScale} />
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleSave} color="secondary" disabled={sectionsListLoading || parentSectionTilesLoading || validateInputs() === false}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog disableBackdropClick onClose={handleClose} aria-labelledby="customized-dialog-title" open={editSectionWindowOpen}>
                <DialogTitle id="customized-dialog-title" >
                    Edit section
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={1} xs={12}>
                        <Grid item xs={4}>
                            <InputLabel id="editable-section-select-label">Editable section</InputLabel>
                            <Autocomplete id="parent-section-select-autocomplete"
                                loading={sectionsListLoading}
                                disableClearable
                                options={allSections}
                                getOptionLabel={(section) => section.name}
                                renderInput={(params) => <TextField {...params} label="Select section to edit" />}
                                onOpen={loadAvailableSections}
                                renderOption={(props, input) => {
                                    const matches = match(props.name, input.inputValue, { insideWords: true });
                                    const parts = parse(props.name, matches);
                                    return (
                                        <List id={props.id}>
                                            <ListItem>
                                                <ListItemText primary={
                                                    <li>
                                                        <div>
                                                            {parts.map((part, index) => (
                                                                <span
                                                                    key={index}
                                                                    style={{
                                                                        fontWeight: part.highlight ? 700 : 400,
                                                                    }}>
                                                                    {part.text}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </li>
                                                }
                                                    secondary={"Type - " + props.type} />
                                            </ListItem>
                                        </List>
                                    )
                                }}
                                value={editableSection}
                                // @ts-ignore
                                onInputChange={(event, newInputValue) => {
                                    setSectionsListLoading(true);
                                    const value = allSections.find(section => section.name === newInputValue);
                                    
                                    if (value) {
                                        sectionService.retrieveSectionsByPathAndCountry(value.path, value.countryName).then(result => {
                                              if (result.length != 1) {
                                                throw Error('Unacceptable response received, expected only one section found by path');
                                              }
                                              setFieldDefaults();
                                              handleEditableSectionChange(result[0]);
                                            setSectionsListLoading(false);
                                        }).catch(e => {
                                            console.log(e);
                                            setSectionsListLoading(false);
                                        });
                                    }


                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="parent-section-select-label" disabled={!editableSectionSelected()}>Parent section</InputLabel>
                            <Select
                                disabled={!editableSectionSelected()}
                                labelId="parent-section-select-label"
                                id="parent-section-select"
                                onOpen={loadAvailableSections}
                                value={parentSection}
                                onChange={handleParentSectionChange}>
                                <MenuItem value="None">None</MenuItem>
                                {sectionsListLoading && <MenuItem value="Loading">Loading data...</MenuItem>}
                                {!sectionsListLoading &&
                                    tilesSections.map(section => (
                                        <MenuItem key={section.id} value={section.id}>{section.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="parent-section-tile-select-label" disabled={!editableSectionSelected() || parentSectionTilesLoading}>Parent section tile</InputLabel>
                            {parentSectionTilesLoading && <CircularProgress color="primary" size={17} thickness={10} />}
                            <Select
                                labelId="parent-section-tile-select-label"
                                id="parent-section-tile-select"
                                onOpen={loadAvailableSectionTiles}
                                value={parentSectionTile}
                                onChange={handleParentSectionTileChange}
                                disabled={!editableSectionSelected() || parentSectionTilesLoading}

                            >
                                <MenuItem value="None">None</MenuItem>

                                {
                                    parentSectionTiles.map(t => (
                                        <MenuItem key={t.id} value={t.id}>{t.header}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Typography>Section Icon:</Typography>
                        <Grid item xs={6}>

                            <ToggleButtonGroup
                                value={sectionIcon}
                                exclusive
                                onChange={handleSectionIconSelect}
                                aria-label="Section Icon"
                            >
                                <ToggleButton disabled={!editableSectionSelected()} value="dashboard" aria-label="Dashboard">
                                    <Dashboard />
                                </ToggleButton>
                                <ToggleButton disabled={!editableSectionSelected()} value="home" aria-label="Home">
                                    <Home />
                                </ToggleButton>
                                <ToggleButton disabled={!editableSectionSelected()} value="info" aria-label="Info">
                                    <Info />
                                </ToggleButton>
                                <ToggleButton disabled={!editableSectionSelected()} value="contactMail" aria-label="ContactMail">
                                    <ContactMail />
                                </ToggleButton>
                            </ToggleButtonGroup>

                        </Grid>
                        <Grid item xs={6}>
                            Appeared in:
                            <Grid item xs={12}>
                                <FormControlLabel disabled={!editableSectionSelected() || (parentSection !== "None" && parentSection !== '')} control={<Checkbox
                                    checked={sectionGroupCheckboxState.menu}
                                    onChange={handleGroupInputChange}
                                    name="menu"
                                    color="primary" />} label='Menu' />
                            </Grid>
                            <Grid item xs={12}>                     <FormControlLabel disabled={!editableSectionSelected() || (parentSection !== "None" && parentSection !== '')} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerLeft}
                                onChange={handleGroupInputChange}
                                name="footerLeft"
                                color="primary"
                            />} label='Footer Left' /></Grid>
                            <Grid item xs={12}> <FormControlLabel disabled={!editableSectionSelected() || (parentSection !== "None" && parentSection !== '')} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerMiddle}
                                onChange={handleGroupInputChange}
                                name="footerMiddle"
                                color="primary"
                            />} label='Footer Middle' /></Grid>
                            <Grid item xs={12}>  <FormControlLabel disabled={!editableSectionSelected() || (parentSection !== "None" && parentSection !== '')} control={<Checkbox
                                checked={sectionGroupCheckboxState.footerRight}
                                onChange={handleGroupInputChange}
                                name="footerRight"
                                color="primary"
                            />} label='Footer Right' /></Grid>
                            {atLeastOneGroupShouldBeSelected && <Typography color='error'>At Least one place should be selected</Typography>}

                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="section-name" disabled={!editableSectionSelected()} label="Section Name" value={sectionName} onChange={handleSectionNameInputValueChange} error={!sectionNameInputCorrect} />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="section-path" disabled={!editableSectionSelected()} label="Section Path" value={sectionPath} onChange={handleSectionPathInputValueChange} error={!sectionPathInputCorrect || duplicatePathFound} />
                            {duplicatePathFound && <Typography color='error'>Specified path is already used by another section</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="filled-select-country"
                                select
                                label="Country"
                                value={country}
                                onChange={handleCountryChange}
                                helperText="Please select your country"
                                variant="filled"
                                className={`${countryInputValid ? '' : 'error'}`}
                                disabled={!editableSectionSelected()}
                            >
                                <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                                <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                                <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                                <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
                            </TextField>
                        </Grid>

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Is Flattened:</Typography>
                                <Switch checked={isFlattened} onChange={handleIsFlattenedSwitch} />
                            </Grid>}


                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Pagination Top Enabled:</Typography>
                                <Switch checked={paginationTopEnabled} onChange={handlePaginationTopEnabledSwitch} />
                            </Grid>}

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Pagination Bottom Enabled:</Typography>
                                <Switch checked={paginationBottomEnabled} onChange={handlePaginationBottomEnabledSwitch} />
                            </Grid>}
                        
                            {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                            <TextField required id="page-size" label="Page Size" value={pageSize} onChange={handlePageSizeInputValueChange} error={!pageSizeInputCorrect} disabled={!paginationBottomEnabled && !paginationTopEnabled}/>
                            {!pageSizeInputCorrect && <Typography color='error'>Page size should be between 1 and 100</Typography>}
                        </Grid>}


                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Ordering Enabled:</Typography>
                                <Switch checked={orderingEnabled} onChange={handleOrderingEnabledSwitch} />
                            </Grid>}

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>

                                <TextField required id="tiles-per-line" label="Number Of Tiles Per Line" value={tilesPerLine} onChange={handleTilesPerLineInputValueChange} error={!tilesPerLineInputCorrect} />
                                {!tilesPerLineInputCorrect && <Typography color='error'>Number of tiles per line should be between 1 and 4</Typography>}
                            </Grid>}

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Shade if no descendants:</Typography>
                                <Switch checked={shadeIfNoDescendants} onChange={handleShadeIfNoDescendantsSwitch} />
                            </Grid>}

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Custom Tile Height Enabled:</Typography>
                                <Switch checked={customTileHeightEnabled} onChange={handleSwitch} />
                            </Grid>}

                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "tiles" && <Grid item xs={12}>
                                <Typography>Custom Tile Height Scale ( % of screen height):</Typography>
                                <Slider style={{ width: 200 }} disabled={!customTileHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handleSliderChange} value={customTileHeightScale} />
                            </Grid>}
                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "posts" && <Grid item xs={12}>
                                <Typography>Post Static Height Enabled:</Typography>
                                <Switch checked={postStaticHeightEnabled} onChange={handlePostSwitch} />
                            </Grid>}
                        {editableSectionSelected() && editableSection.
                            // @ts-ignore
                            type === "posts" && <Grid item xs={12}>
                                <Typography>Post Height Scale ( % of screen height):</Typography>
                                <Slider style={{ width: 200 }} disabled={!postStaticHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handlePostSliderChange} value={postHeightScale} />
                            </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleUpdate} color="secondary" disabled={!editableSectionSelected() || sectionsListLoading || parentSectionTilesLoading || validateInputs() === false}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
            {sectionDeleteWarningOpen && <ConfirmationDialog
                message={t('section_editor_tile_removal_msg')}
                onClose={handleSectionDeleteWarningCancel}
                onConfirm={handleSectionDeleteWarningProceed}
                open={sectionDeleteWarningOpen}
                title={t('section_editor_tile_removal')}
            />}


        </div>
    );
}

export default SectionBar;