import { Breadcrumbs, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, GridList, GridListTile, GridListTileBar, IconButton, ImageList, ImageListItem, ImageListItemBar, InputAdornment, InputLabel, Link, List, ListItem, ListItemText, ListSubheader, makeStyles, MenuItem, Select, Slider, Switch, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Add, AddShoppingCart, ArrowDropDown, Delete, Edit } from "@material-ui/icons";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthorityUtils from "util/AuthorityUtils";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import { useShoppingCart } from "util/ShoppingCartContextProvider";
import Tile from "./Tile";
import './TilePage.css';
import SectionEditor from "components/section-bar/SectionEditor";
import ConfirmationDialog from "util/ConfirmationDialog";
import { Pagination } from "@material-ui/lab";
import SEO from "util/SEO";

const styles = props => makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '80%',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  gridListItem: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    "&:hover": {
      boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important',
      transition: 'all 200ms ease-out'
    }
  },
  glImage: {
    left: '50%',
    height: '100%',
    position: 'relative',
    transform: 'translateX(-50%)',
    padding: '10px'
  },
  addNewGridList: {
    width: '400px'
  },
  addNewGridListTileBar: {
    width: '400px !important'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  addFab: {
    position: 'absolute',
    bottom: '5vh',
    right: '3vw'
  },
  addTileModalProgress: {
    position: 'absolute',
    left: '44%',
    top: '46%',
    zIndex: 1
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)',
  },
  test: {
    "&&:hover": {
      backgroundColor: "#c62e54"
    },
  }
}));

const TilePage = ({ tileService, exceptionHandlerService, sectionPath, section, sectionService, fileService }) => {
  const outerButtons = true;
  const history = useHistory();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [childSectionDeleteWarningOpen, setChildSectionDeleteWarningOpen] = React.useState(false);
  const [tileDeleteWarningOpen, setTileDeleteWarningOpen] = React.useState(false);
  const [base64loading, setBase64loading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [editorImage, setEditorImage] = React.useState(null);
  const [newHeader, setNewHeader] = React.useState("");
  const imageUploadClickableArea = useRef(null);
  const [imageAreaValid, setImageAreaValid] = React.useState(true);
  const [tileHeaderInputValid, setTileHeaderInputValid] = React.useState(true);

  const [languageInputValid, setLanguageInputValid] = React.useState(true);
  const [countryInputValid, setCountryInputValid] = React.useState(true);

  const [tilesPerLine, setTilesPerLine] = React.useState(3);
  const [pageSize, setPageSize] = React.useState(null);
  const [customTileHeightScale, setCustomTileHeightScale] = React.useState(1);
  const [customTileHeightEnabled, setCustomTileHeightEnabled] = React.useState(false);
  const [shadeIfNoDescendants, setShadeIfNoDescendants] = React.useState(true);
  const [paginationBottomEnabled, setPaginationBottomEnabled] = React.useState(false);
  const [paginationTopEnabled, setPaginationTopEnabled] = React.useState(false);
  const [orderingEnabled, setOrderingEnabled] = React.useState(false);
  const [isFlattened, setIsFlattened] = React.useState(false);
  let classes = styles({ scale: tilesPerLine, customTileHeightEnabled: customTileHeightEnabled, customTileHeight: customTileHeightScale })();
  const [language, setLanguage] = React.useState('');
  const [country, setCountry] = React.useState(currentRegion);
  const [editPageLoading, setEditPageLoading] = React.useState(false);
  const [tileChildSection, setTileChildSection] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [listTiles, setListTiles] = useState([]);
  const [isTileListLoading, setIsTileListLoading] = useState(false);
  const [editableTile, setEditableTile] = useState(null);
  const [addSectionModalOpen, setAddSectionModalOpen] = React.useState(false);
  const [editSectionModalOpen, setEditSectionModalOpen] = React.useState(false);
  const [saveAddSectionModalOpen, setSaveAddSectionModalOpen] = React.useState(false);
  const [saveEditSectionModalOpen, setSaveEditSectionModalOpen] = React.useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100);

  const [orderBy, setOrderBy] = useState('UPDATED_DATE,DESC');



  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    resetModalState();
    setIsTileListLoading(true);
    const fetchTiles = async () => {
      try {
        let pageSize = null;
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'tilesPerLine').length > 0) {
          setTilesPerLine(+section.sectionConfigs.filter(sc => sc.key === 'tilesPerLine')[0].value);
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'pageSize').length > 0) {
          pageSize = +section.sectionConfigs.filter(sc => sc.key === 'pageSize')[0].value;
          setPageSize(pageSize);
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale').length > 0) {
          setCustomTileHeightScale(+section.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale')[0].value);
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled').length > 0) {
          setCustomTileHeightEnabled(section.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled')[0].value);
        } else {
          setCustomTileHeightEnabled(false);
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants').length > 0) {
          setShadeIfNoDescendants(section.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants')[0].value === 'true');
        } else {
          setShadeIfNoDescendants(true);
        }
        let isPaginationTopEnabled = false;
        let isPaginationBottomEnabled = false;
        let isOrderingEnabled = false;

        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'paginationTopEnabled').length > 0) {
          isPaginationTopEnabled = section.sectionConfigs.filter(sc => sc.key === 'paginationTopEnabled')[0].value === 'true';
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'paginationBottomEnabled').length > 0) {
          isPaginationBottomEnabled = section.sectionConfigs.filter(sc => sc.key === 'paginationBottomEnabled')[0].value === 'true';
        }
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'orderingEnabled').length > 0) {
          isOrderingEnabled = section.sectionConfigs.filter(sc => sc.key === 'orderingEnabled')[0].value === 'true';
        }

        let flat = false;
        if (section?.sectionConfigs && section.sectionConfigs.filter(sc => sc.key === 'isFlattened').length > 0) {
          flat = section.sectionConfigs.filter(sc => sc.key === 'isFlattened')[0].value === 'true';
        }  

        let result;
        if(flat == true) {
          let response = await tileService.searchTiles(
            null, 
            currentRegion, 
            ( isPaginationTopEnabled || isPaginationBottomEnabled ) ? page : null, 
            ( isPaginationTopEnabled || isPaginationBottomEnabled ) ?  pageSize : null, 
            isOrderingEnabled ? orderBy.split(",")[0] : null, 
            isOrderingEnabled ? orderBy.split(",")[1] : null,
            true
            );
            setTotalPages(response.total);
            result = response.payload;
        } else {
          //result = await tileService.retrieveTilesBySectionPathAndCountry(sectionPath, currentRegion);
          let response = await tileService.searchTiles(
            sectionPath, 
            currentRegion, 
            ( isPaginationTopEnabled || isPaginationBottomEnabled ) ? page : null, 
            ( isPaginationTopEnabled || isPaginationBottomEnabled ) ?  pageSize : null, 
            isOrderingEnabled ? orderBy.split(",")[0] : null, 
            isOrderingEnabled ? orderBy.split(",")[1] : null,
            false
            );
            setTotalPages(response.total);
            result = response.payload;
        }
         
        setIsFlattened(flat);
        setPaginationTopEnabled(isPaginationTopEnabled);
        setPaginationBottomEnabled(isPaginationBottomEnabled);
        setOrderingEnabled(isOrderingEnabled);
        setListTiles(result);
        setIsTileListLoading(false);
      } catch (e) {
        console.log(e);
        setIsTileListLoading(false);
      }
    };

    fetchTiles();

  }, [sectionPath, page, orderBy]);


  const handleAddSectionModalOpen = () => {
    setAddSectionModalOpen(true);
  };

  const handleEditSectionModalOpen = () => {
    setEditSectionModalOpen(true);
  };

  const handleSaveAddSectionModalOpen = () => {
    setSaveAddSectionModalOpen(true);
  };

  const handleSaveEditSectionModalOpen = () => {
    setSaveEditSectionModalOpen(true);
  };

  const handleCancel = (e) => {
    resetModalState();
  }
  const handleSave = (e) => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    console.log(e);
    setSaveLoading(true);
    tileService.addTile(createTile()).then(response => {
      if (response.error && response.status === 401) {
        exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
        resetModalState();
      } else if (response.error) {
        exceptionHandlerService.showErrorToast('Unexpected error occured');
        resetModalState();
      } else {
        setListTiles([...listTiles, response]);
        resetModalState();
      }

    }).catch(e => {
      console.log(e);
      resetModalState();
    });
  };

  const createTile = () => {
    return {
      id: null,
      header: newHeader,
      image: editorImage,
      author: {
        id: localStorage.getItem('id'),
        nickname: localStorage.getItem('nickname')
      },
      section: {
        path: sectionPath
      },
      childSection: tileChildSection,
      commercialData: {
        languageName: language,
        countryName: country
      }
    }
  }


  const handleUpdate = (e) => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    setSaveLoading(true);
    editableTile.header = newHeader;
    editableTile.image = editorImage;
    editableTile.childSection = tileChildSection;

    if (editableTile.commercialData) {
      editableTile.commercialData.languageName = language
      editableTile.commercialData.countryName = country
    } else {
      editableTile.commercialData = {
        languageName: language,
        countryName: country
      }
    }
    tileService.updateTile(editableTile).then(response => {
      if (response.error && response.status === 401) {
        exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
        resetModalState();
      } else if (response.error) {
        exceptionHandlerService.showErrorToast('Unexpected error occured');
        resetModalState();
      } else {
        const filteredList = listTiles.filter(tile => tile.id !== editableTile.id);
        setListTiles([...filteredList, response]);
        resetModalState();
      }

    }).catch(e => {
      console.log(e);
      resetModalState();
    });
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    setLanguageInputValid(true);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryInputValid(true);
  };

  const validateInputs = function () {
    let imageInputValid = true;
    let textInputValid = true;
    if (!editorImage) {
      setImageAreaValid(false);
      imageInputValid = false;
    }
    if (!newHeader) {
      setTileHeaderInputValid(false);
      textInputValid = false;
    }
    if (!language) {
      setLanguageInputValid(false);
      textInputValid = false;
    }
    if (!country) {
      setCountryInputValid(false);
      textInputValid = false;
    }
    return imageInputValid && countryInputValid && languageInputValid && textInputValid;
  }

  const resetModalState = function () {
    setOpen(false);
    setEditOpen(false);
    setSaveLoading(false);
    setEditorImage(null);
    setNewHeader("");
    setLanguage("");
    //setCountry("");
    setImageAreaValid(true);
    setTileHeaderInputValid(true);
    setLanguageInputValid(true);
    setCountryInputValid(true);
    setCustomTileHeightScale(1);
    setCustomTileHeightEnabled(false);
    setEditableTile(null);
    setTileChildSection(null);
    setTilesPerLine(3);
    setPageSize(null);
  }

  const handleHeaderInputChange = function (e) {
    if (e.target.value) {
      setTileHeaderInputValid(true);
    }
    setNewHeader(e.target.value);
  }
  const handleImageInputChange = function (e) {
    let image = e.target.files[0];
    setBase64loading(true);
    fileService.addTempFile(image)
      .then(response => {
        if (response.error && response.status === 401) {
          exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
          setBase64loading(false);
        } else if (response.error) {
          exceptionHandlerService.showErrorToast('Unexpected error occured');
          setBase64loading(false);
        } else {
          setEditorImage(response);
          setBase64loading(false);
          setImageAreaValid(true);
        }

      }).catch(e => {
        setBase64loading(false);
      });
  }

  const handleAreaClick = function () {
    imageUploadClickableArea.current.click();
  }

  const stopPropagation = function (e) {
    e.stopPropagation();
  }

  const handleEdit = (tile, e) => {
    setEditOpen(true);
    setEditPageLoading(true);
    const fetchTile = async () => {
      try {
        const result = await tileService.retrieveTileById(tile.id);
        if (result) {
          setLanguage(result.commercialData.languageName);
          setEditorImage(result.body ? { pathMedium: result.body } : result.image);
          setNewHeader(result.header);
          setEditableTile(result);
          if (result.childSection && result.childSection.id) {
            setTileChildSection(result.childSection);
          }

        }
        setEditPageLoading(false);
      } catch (e) {
        console.log(e);
        setEditPageLoading(false);
      }
    };

    fetchTile();
    stopPropagation(e);
  }

  const routeToChildPage = function (tile) {
    if (tile.childSection && tile.childSection.path && tile.childSection?.type) {
      history.push("/_/" + tile.childSection.path);
    }
  }

  const breadcrumbsLinks = function () {
    const words = sectionPath.split('/');
    let currentLink = "/_";
    let currentWordIndex = 1;
    return words.map(pathItem => {
      currentLink = currentLink + "/" + pathItem;
      if (currentWordIndex < words.length) {
        currentWordIndex++;
        return (<Link key={currentWordIndex} underline="hover" color="inherit" href={currentLink}>
          {pathItem}
        </Link>);
      } else if (currentWordIndex === words.length) {
        currentWordIndex++;
        return (<Typography color="primary">{pathItem}</Typography>);
      }
    });
  }


  const calculateTileHeight = function () {
    let height = null;
    if (customTileHeightEnabled) {
      height = 90 * customTileHeightScale;
    } else {
      height = 90 / tilesPerLine;
    }

    if (isMobile && height > 70) {
      height = height / 2;
    }
    return height + 'vh';
  }

  const {
    addTileToCart,
    addItemDetailsToCart,
    getItemsCount
  } = useShoppingCart();

  const addToCart = function (event, tile) {
    event.stopPropagation();
    addTileToCart(tile);
    exceptionHandlerService.showInfoToast(t('common_item_was_added_to_cart'));
  }

  const handleSectionSaveUpdateSuccess = (section) => {
    setAddSectionModalOpen(false);
    setEditSectionModalOpen(false);
    setSaveAddSectionModalOpen(false);
    setSaveEditSectionModalOpen(false);
    setTileChildSection(section);
  }

  const handleSectionsModalClose = () => {
    setAddSectionModalOpen(false);
    setEditSectionModalOpen(false);
    setSaveAddSectionModalOpen(false);
    setSaveEditSectionModalOpen(false);
  }

  const handleDeleteSection = () => {
    setTileChildSection(null);
  }

  const handleChildSectionDeleteWarningCancel = () => {
    setChildSectionDeleteWarningOpen(false);
  }

  const handleChildSectionDeleteWarningOpen = () => {
    setChildSectionDeleteWarningOpen(true);
  }

  const handleTileDeleteWarningOpen = (tile, e) => {
    stopPropagation(e);
    setEditableTile(tile);
    setTileDeleteWarningOpen(true);
  }

  const handleTileDeleteWarningProceed = () => {
    setTileDeleteWarningOpen(false);
    handleDelete(editableTile.id);
    resetModalState();
  }

  const handleTileDeleteWarningCancel = () => {
    setTileDeleteWarningOpen(false);
    resetModalState();
  }

  const handleDelete = (tileId) => {
    setIsTileListLoading(true);
    tileService.deleteTile(tileId).then(res => {
      setListTiles(listTiles.filter(t => t.id !== tileId));
      setIsTileListLoading(false);
    }).catch(e => {
      console.log(e);
      setIsTileListLoading(false);
    });
  }

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  return (

    <div className={classes.root}>
      <SEO title={section?.name ? section?.name : ""} description="" name="Pearl Art Store" image={null}/>
      <Container maxWidth="lg" className="breadcrumbs">
        <Grid key="breadcrumbs" item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t('breadcrumbs_home')}
            </Link>
            {breadcrumbsLinks()}
          </Breadcrumbs>

        </Grid>
      </Container>
      
      {isTileListLoading && <CircularProgress color="primary" size={37} thickness={10} />}
      {!isTileListLoading && (!listTiles || (listTiles && listTiles.length === 0)) && <div>{t('tile_page_no_data_to_display')}</div>}
      {!isTileListLoading && outerButtons && listTiles && listTiles.length > 0 && <Grid className={classes.gridList} container columns={isMobile ? 1 : tilesPerLine}>
      <Grid  item xs={12} sm={12} md={4} style={{justifyContent: "center"}}>
        </Grid>
          <Grid  item xs={12} sm={12} md={4} style={{justifyContent: "center"}}>
          {paginationTopEnabled &&<div style={{display: "flex", justifyContent: "center"}}>
            <Pagination 
              color="primary"  
              size={isMobile ? "medium" : "large"} 
              count={totalPages}
              page={page}
              onChange={handlePageChange}/>
          </div>}
        </Grid>
        <Grid  item xs={12} sm={12} md={4} style={{textAlign: isMobile ? "center" : "right"}}>
        {orderingEnabled && 
        <FormControl className={classes.formControl}>
        <InputLabel htmlFor="order-by-select" className={classes.inputLabel}>
        {t("tile_page_order_by")}
        </InputLabel>

        <Select label="order-by-select" value={orderBy} onChange={handleOrderByChange}>
        <MenuItem value="HEADER,ASC">{t("tile_page_sorting_name_asc")}</MenuItem>
        <MenuItem value="HEADER,DESC">{t("tile_page_sorting_name_desc")}</MenuItem>
        <MenuItem value="UPDATED_DATE,ASC">{t("tile_page_sorting_oldest_first")}</MenuItem>
        <MenuItem value="UPDATED_DATE,DESC">{t("tile_page_sorting_newest_first")}</MenuItem>
        <MenuItem value="COST,ASC">{t("tile_page_sorting_cost_asc")}</MenuItem>
        <MenuItem value="COST,DESC">{t("tile_page_sorting_cost_desc")}</MenuItem>
      </Select>
      </FormControl>
      }
      </Grid>
        {listTiles.map(tile => (
          <Grid onClick={() => routeToChildPage(tile)} style={{ padding: "10px" }} className={classes.gridListItem} item xs={12} sm={12} md={12 / tilesPerLine} key={tile.id}>
            <Grid container>

              <Grid item xs={12} sm={12} md={12} style={{ opacity: (tile.childSection?.type || !shadeIfNoDescendants ? 1 : 0.3) }}>
                <Tile tile={tile} routeToChildPage={routeToChildPage} customTileHeightEnabled={customTileHeightEnabled} customTileHeightScale={customTileHeightScale} tilesPerLine={tilesPerLine} isMobile={isMobile}></Tile>
              </Grid>

              <Grid style={{ textAlign: 'center', padding: '5px', opacity: (tile.childSection?.type || !shadeIfNoDescendants ? 1 : 0.3) }} item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1" display="inline">
                  {tile.header}
                </Typography>
                <Typography variant="body2" display="inline" style={{ color: 'white', position: 'absolute', top: '10px', right: '20px' }}>by {tile.author.nickname}</Typography>

              </Grid>

              {tile.commercialData?.cost && tile.childSection?.type && tile.childSection?.type == 'details' && <Grid style={{ textAlign: 'center', padding: '5px' }} item xs={12} sm={12} md={12}>
                <Typography variant="h6" color="secondary">{(tile.commercialData.cost + ' ' + tile.commercialData.currency)}</Typography>
              </Grid>}

              {!tile.commercialData?.cost && tile.childSection?.type && tile.childSection?.type == 'details' && <Grid style={{ textAlign: 'center', padding: '5px' }} item xs={12} sm={12} md={12}>
                <Typography variant="h6" color="secondary">- - -</Typography>
              </Grid>}

              {tile.childSection?.type && tile.childSection?.type == 'details' && <Grid style={{ textAlign: 'center', padding: '5px' }} item xs={12} sm={12} md={12}>
                <Chip disabled={!tile.commercialData?.cost || !tile.commercialData?.inStock} className={classes.test} color="secondary" icon={<AddShoppingCart />} label={tile.commercialData?.cost && tile.commercialData?.inStock ? t("tile_page_add_to_cart") : t("tile_page_out_of_stock")} onClick={(e) => addToCart(e, tile)} />
              </Grid>}

              {AuthorityUtils.userHasAuthority("UPDATE_TILE") && <Grid style={{ textAlign: 'center', padding: '5px' }} item xs={12} sm={12} md={12}>
                <Chip className={classes.test} color="secondary" icon={<Delete />} label={t('common_delete')} onClick={(e) => handleTileDeleteWarningOpen(tile, e)} />
                <Chip className={classes.test} color="secondary" icon={<Edit />} label={t('common_edit')} onClick={(e) => handleEdit(tile, e)} />
              </Grid>}
            </Grid>

          </Grid>
        ))}
        <Grid  item xs={12} sm={12} md={12} style={{justifyContent: "center"}}>
        {paginationBottomEnabled && <div style={{display: "flex", justifyContent: "center"}}>
          <Pagination 
              color="primary"  
              size={isMobile ? "medium" : "large"} 
              count={totalPages}
              page={page}
              onChange={handlePageChange}/>
          </div>}
        </Grid>
      </Grid>
      }
      {!outerButtons && <ImageList cols={isMobile ? 1 : tilesPerLine} className={classes.gridList}>

        {!isTileListLoading && listTiles && listTiles.length > 0 && listTiles.map(tile => (
          <ImageListItem key={tile.id} style={{ padding: "10px", height: calculateTileHeight() }} >
            {tile.body && <img onClick={() => routeToChildPage(tile)} src={tile.body} alt={tile.header} />}
            {!tile.body && tile.image && <img onClick={() => routeToChildPage(tile)} src={"/fstor/" + tile.image.pathMedium} alt={tile.header} />}
            <ImageListItemBar
              classes={{
                root: classes.titleBar
              }}
              title={tile.header + (tile.commercialData?.cost ? ' - ' + tile.commercialData.cost + ' ' + tile.commercialData.currency : '')}
              subtitle={<span>by: {tile.author.nickname}</span>}
              actionIcon={<div>
                {tile.commercialData?.cost && <IconButton className={classes.icon} onClick={(e) => addToCart(e, tile)}>
                  <AddShoppingCart color="secondary" />
                </IconButton>}
                {AuthorityUtils.userHasAuthority("UPDATE_TILE") &&
                  <IconButton onClick={(e) => handleTileDeleteWarningOpen(tile, e)} className={classes.icon}>
                    <Delete />
                  </IconButton>}
              </div>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      }
      <Dialog id="add-new-tile" disableBackdropClick aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_add_new_tile_header')}
        </DialogTitle>
        <DialogContent dividers>
          {(base64loading || saveLoading || editPageLoading) && <div style={{ height: '50vh' }}><CircularProgress className={classes.addTileModalProgress} color="primary" size={37} thickness={10} /></div>}
          {(!base64loading && !saveLoading && !editPageLoading) && <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} style={{ minHeight: '50vh' }}>
              <GridList cols={3} className={classes.addNewGridList}>
                <GridListTile
                  className={`add-new-grid-list-tile ${imageAreaValid ? '' : 'error'}`}
                  onClick={handleAreaClick}
                >
                  <input onChange={handleImageInputChange} ref={imageUploadClickableArea}
                    type="file"
                    hidden
                  />
                  <Typography className="click-to-load-file-text">
                    <span className="click">{t('tile_page_editor_click_here')}</span>{t('tile_page_editor_to_load_picture')}
                  </Typography>
                  <img src={editorImage && editorImage.pathMedium ? (editorImage.pathMedium.startsWith('data') ? editorImage.pathMedium : "/fstor/" + editorImage.pathMedium) : ''} />
                </GridListTile>
              </GridList>

            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_header')}</Typography>
                  <TextField className="tile-editor-input" required id="tile-header" label="Header" value={newHeader} onChange={handleHeaderInputChange} error={!tileHeaderInputValid} />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_language')}</Typography>
                  <FormControl className="tile-editor-input">
                    <InputLabel id="language-select-label">{t('tile_page_editor_language')}</InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={language}
                      onChange={handleLanguageChange}
                      error={!languageInputValid}
                    >
                      <MenuItem id="language-ru" value="Русский">Русский</MenuItem>
                      <MenuItem id="language-pl" value="Polski">Polski</MenuItem>
                      <MenuItem id="language-en" value="English">English</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_country')}</Typography>
                  <FormControl className="tile-editor-input">
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      value={country}
                      onChange={handleCountryChange}
                      disabled={true}
                    >
                      <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                      <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                      <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                      <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_child_section')}</Typography>
                  {tileChildSection && <div>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_name')}</Typography>
                    <Typography style={{ color: ('black') }}> {tileChildSection?.name ? tileChildSection?.name : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_type')}</Typography>
                    <Typography style={{ color: 'black' }}> {tileChildSection?.type ? tileChildSection?.type : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_path')}</Typography>
                    <Typography style={{ color: 'black' }}> {tileChildSection?.path ? tileChildSection?.path : t('tile_page_editor_not_defined')}</Typography>

                  </div>}
                  {!tileChildSection && <div>
                    <Typography style={{ fontWeight: 'bold', color: tileChildSection ? 'grey' : 'black' }}>{t('tile_page_editor_name')}</Typography>
                    <Typography style={{ color: ('grey') }}> {tileChildSection?.name ? tileChildSection?.name : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'grey' }}>{t('tile_page_editor_type')}</Typography>
                    <Typography style={{ color: 'grey' }}> {tileChildSection?.type ? tileChildSection?.type : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'grey' }}>{t('tile_page_editor_path')}</Typography>
                    <Typography style={{ color: 'grey' }}> {tileChildSection?.path ? tileChildSection?.path : t('tile_page_editor_not_defined')}</Typography>

                  </div>}
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={tileChildSection} color="secondary" variant="outlined" onClick={handleSaveAddSectionModalOpen}>
                    {t('tile_page_editor_add')}
                  </Button>
                  <Button disabled={!tileChildSection} color="secondary" variant="outlined" onClick={handleSaveEditSectionModalOpen}>
                    {t('tile_page_editor_edit')}
                  </Button>
                  <Button disabled={!tileChildSection} color="secondary" variant="outlined" onClick={handleChildSectionDeleteWarningOpen}>
                    {t('tile_page_editor_delete')}
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>}
        </DialogContent>
        <DialogActions>
          <Button disabled={base64loading || saveLoading || editPageLoading} autoFocus onClick={handleCancel} color="secondary">
            {t('tile_page_editor_cancel')}
          </Button>
          <Button disabled={base64loading || saveLoading || editPageLoading} autoFocus onClick={handleSave} color="secondary">
            {t('tile_page_editor_save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick aria-labelledby="customized-dialog-title" open={editOpen} fullWidth maxWidth="md">
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_edit_tile_header')}
        </DialogTitle>
        <DialogContent dividers>
          {(base64loading || saveLoading || editPageLoading) && <div style={{ height: '50vh' }}><CircularProgress className={classes.addTileModalProgress} color="primary" size={37} thickness={10} /></div>}
          {(!base64loading && !saveLoading && !editPageLoading) && <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} style={{ minHeight: '50vh' }}>
              <GridList cols={3} className={classes.addNewGridList}>
                <GridListTile
                  className={`add-new-grid-list-tile ${imageAreaValid ? '' : 'error'}`}
                  onClick={handleAreaClick}
                >
                  <input onChange={handleImageInputChange} ref={imageUploadClickableArea}
                    type="file"
                    hidden
                  />
                  <Typography className="click-to-load-file-text">
                    <span className="click">{t('tile_page_editor_click_here')}</span>{t('tile_page_editor_to_load_picture')}
                    
                  </Typography>
                  <img src={editorImage && editorImage.pathMedium ? (editorImage.pathMedium.startsWith('data') ? editorImage.pathMedium : "/fstor/" + editorImage.pathMedium) : ''} />
                </GridListTile>
              </GridList>

            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_header')}</Typography>
                  <TextField className="tile-editor-input" required id="tile-header" label="Header" value={newHeader} onChange={handleHeaderInputChange} error={!tileHeaderInputValid} />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_language')}</Typography>
                  <FormControl className="tile-editor-input">
                    <InputLabel id="language-select-label">{t('tile_page_editor_language')}</InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={language}
                      onChange={handleLanguageChange}
                      error={!languageInputValid}
                    >
                      <MenuItem id="language-ru" value="Русский">Русский</MenuItem>
                      <MenuItem id="language-pl" value="Polski">Polski</MenuItem>
                      <MenuItem id="language-en" value="English">English</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_country')}</Typography>
                  <FormControl className="tile-editor-input">
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      value={country}
                      onChange={handleCountryChange}
                      disabled={true}
                    >
                      <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                      <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                      <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                      <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h6" color="inherit" noWrap>{t('tile_page_editor_child_section')}</Typography>
                  {tileChildSection && <div>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_name')}</Typography>
                    <Typography style={{ color: ('black') }}> {tileChildSection?.name ? tileChildSection?.name : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_type')}</Typography>
                    <Typography style={{ color: 'black' }}> {tileChildSection?.type ? tileChildSection?.type : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'black' }}>{t('tile_page_editor_path')}</Typography>
                    <Typography style={{ color: 'black' }}> {tileChildSection?.path ? tileChildSection?.path : t('tile_page_editor_not_defined')}</Typography>

                  </div>}
                  {!tileChildSection && <div>
                    <Typography style={{ fontWeight: 'bold', color: tileChildSection ? 'grey' : 'black' }}>{t('tile_page_editor_name')}</Typography>
                    <Typography style={{ color: ('grey') }}> {tileChildSection?.name ? tileChildSection?.name : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'grey' }}>{t('tile_page_editor_type')}</Typography>
                    <Typography style={{ color: 'grey' }}> {tileChildSection?.type ? tileChildSection?.type : t('tile_page_editor_not_defined')}</Typography>
                    <Typography style={{ fontWeight: 'bold', color: 'grey' }}>{t('tile_page_editor_path')}</Typography>
                    <Typography style={{ color: 'grey' }}> {tileChildSection?.path ? tileChildSection?.path : t('tile_page_editor_not_defined')}</Typography>

                  </div>}
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={tileChildSection} color="secondary" variant="outlined" onClick={handleAddSectionModalOpen}>
                    {t('tile_page_editor_add')}
                  </Button>
                  <Button disabled={!tileChildSection} color="secondary" variant="outlined" onClick={handleEditSectionModalOpen}>
                    {t('tile_page_editor_edit')}
                  </Button>
                  <Button disabled={!tileChildSection} color="secondary" variant="outlined" onClick={handleChildSectionDeleteWarningOpen}>
                    {t('tile_page_editor_delete')}
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>}
        </DialogContent>
        <DialogActions>
          <Button disabled={base64loading || saveLoading || editPageLoading} autoFocus onClick={handleCancel} color="secondary">
            {t('tile_page_editor_cancel')}
          </Button>
          <Button disabled={base64loading || saveLoading || editPageLoading} autoFocus onClick={handleUpdate} color="secondary">
            {t('tile_page_editor_save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick id="add-section-dialog" aria-labelledby="customized-dialog-title" open={addSectionModalOpen}>
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_editor_add_new_section')}
        </DialogTitle>
        <DialogContent dividers>
          <SectionEditor
            typeSelectionEnabled={true}
            sectionService={sectionService}
            tileService={tileService}
            exceptionHandlerService={exceptionHandlerService}
            parentSectionIncoming={editableTile?.section ? editableTile.section : null}
            parentSectionTileIncoming={editableTile}
            sectionTypeIncoming={"details"}
            countrySelectionEnabled={false}
            onSuccess={(section) => handleSectionSaveUpdateSuccess(section)}
            onClose={handleSectionsModalClose}
            parentSelectionEnabled={false}
            performBackendCall={false}
            existingSection={null} />
        </DialogContent>
      </Dialog>
      <Dialog disableBackdropClick id="edit-section-dialog" aria-labelledby="customized-dialog-title" open={editSectionModalOpen}>
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_editor_edit_section')}
        </DialogTitle>
        <DialogContent dividers>
          <SectionEditor
            typeSelectionEnabled={false}
            sectionService={sectionService}
            tileService={tileService}
            exceptionHandlerService={exceptionHandlerService}
            parentSectionIncoming={editableTile?.section ? editableTile.section : null}
            parentSectionTileIncoming={editableTile}
            sectionTypeIncoming={"details"}
            countrySelectionEnabled={false}
            onSuccess={(section) => handleSectionSaveUpdateSuccess(section)}
            onClose={handleSectionsModalClose}
            parentSelectionEnabled={false}
            performBackendCall={false}
            existingSection={tileChildSection} />
        </DialogContent>
      </Dialog>
      <Dialog disableBackdropClick id="save-add-section-dialog" aria-labelledby="customized-dialog-title" open={saveAddSectionModalOpen}>
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_editor_add_new_section')}
        </DialogTitle>
        <DialogContent dividers>
          <SectionEditor
            typeSelectionEnabled={true}
            sectionService={sectionService}
            tileService={tileService}
            exceptionHandlerService={exceptionHandlerService}
            parentSectionIncoming={section}
            parentSectionTileIncoming={createTile()}
            sectionTypeIncoming={"details"}
            countrySelectionEnabled={false}
            onSuccess={(section) => handleSectionSaveUpdateSuccess(section)}
            onClose={handleSectionsModalClose}
            parentSelectionEnabled={false}
            performBackendCall={false}
            existingSection={null} />
        </DialogContent>
      </Dialog>
      <Dialog disableBackdropClick id="save-edit-section-dialog" aria-labelledby="customized-dialog-title" open={saveEditSectionModalOpen}>
        <DialogTitle id="customized-dialog-title" >
          {t('tile_page_editor_edit_section')}
        </DialogTitle>
        <DialogContent dividers>
          <SectionEditor
            typeSelectionEnabled={false}
            sectionService={sectionService}
            tileService={tileService}
            exceptionHandlerService={exceptionHandlerService}
            parentSectionIncoming={section}
            parentSectionTileIncoming={createTile()}
            sectionTypeIncoming={"details"}
            countrySelectionEnabled={false}
            onSuccess={(section) => handleSectionSaveUpdateSuccess(section)}
            onClose={handleSectionsModalClose}
            parentSelectionEnabled={false}
            performBackendCall={false}
            existingSection={tileChildSection} />
        </DialogContent>
      </Dialog>
      {tileDeleteWarningOpen && <ConfirmationDialog 
        message={t('section_editor_tile_removal_msg')}
        onClose={handleTileDeleteWarningCancel}
        onConfirm={handleTileDeleteWarningProceed}
        open={tileDeleteWarningOpen}
        title={t('section_editor_tile_removal')}
        />}
       {childSectionDeleteWarningOpen && <ConfirmationDialog 
        message={t('section_editor_child_section_removal_msg')}
        onClose={handleChildSectionDeleteWarningCancel}
        onConfirm={handleDeleteSection}
        open={childSectionDeleteWarningOpen}
        title={t('section_editor_child_section_removal')}
        />}
      {AuthorityUtils.userHasAuthority("UPDATE_TILE") && <Fab color="primary" aria-label="add" onClick={handleClickOpen} className={classes.addFab}>
        <Add />
      </Fab>}
    </div>
  );
}

export default TilePage;