import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { t } from "i18next";

const ConfirmationDialog = ({open, onClose, onConfirm, title, message}) => {

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('common_cancel')}
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
            {t('common_confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      );
}

export default ConfirmationDialog;