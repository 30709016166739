import './Comments.css'
import React, { useState } from "react";
import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, makeStyles, Paper, TextField } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Delete } from '@material-ui/icons';
import DateUtils from 'util/DateUtils';


const useStyles = makeStyles((theme) => ({
    addNewComment: {
        boxShadow: 'unset'
    },
    avatar: {
        backgroundColor: red[500],
    },

}));

const CommentsPanel = ({commentableEntityId, commentsService, initialComments, handleCommentsListChange}) => {
    const classes = useStyles();
    const [listComments, setListComments] = useState(initialComments ? initialComments : []);
    const [newComment, setNewComment] = useState('');
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [commentIdDeleteInProgress, setCommentIdDeleteInProgress] = useState(0);

    const handleNewCommentInputValueChange = function (e) {
        setNewComment(e.target.value);
    }

    const addComment = function (event) {
        setSaveInProgress(true);
        commentsService.addComment({
            body: newComment,
            author: {
                id: localStorage.getItem('id')
            },
            post: {
                id: commentableEntityId
            }
        })
            .then(response => {

                if (response.error && response.status === 401) {
                    this.exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
                    setSaveInProgress(false);
                } else if (response.error) {
                    this.exceptionHandlerService.showErrorToast('Unexpected error occured');
                    setSaveInProgress(false);
                } else {
                    const updatedListComments = [...listComments];
                    updatedListComments.push({
                        id: response.id,
                        body: newComment,
                        createdDate: response.createdDate,
                        author: {
                            id: localStorage.getItem('id'),
                            nickname: localStorage.getItem('nickname')
                        },
                        post: {
                            id: commentableEntityId
                        }
                    });
                    setListComments(updatedListComments);
                    setNewComment('');
                    setSaveInProgress(false);
                    handleCommentsListChange(updatedListComments);
                }
            }).catch(e => {
                console.log(e);
                setSaveInProgress(false);
            });

    }

    const userHasCommentPermission = function () {
        return JSON.parse(localStorage.getItem('authorities'))?.includes("USERS");
    }

    const userHasDeleteCommentPermission = function (authorId) {
        return (localStorage.getItem('id') && +localStorage.getItem('id') === +authorId) || JSON.parse(localStorage.getItem('authorities'))?.includes("ADMINISTRATORS");
    }


    const resolveAddCommentInputPlaceholder = function () {
        return userHasCommentPermission() ? "Add your comment here" : "You need to be authorized to be able to enter comments"
    }

    
    const handleDeleteCommentEvent = function(comment) {
        setDeleteInProgress(true);
        setCommentIdDeleteInProgress(comment.id);
        commentsService.deleteComment(comment.id)
        .then(response => {
            if (response.error && response.status === 401) {
                this.exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
                setDeleteInProgress(false);
                setCommentIdDeleteInProgress(0);
            } else if (response.error) {
                this.exceptionHandlerService.showErrorToast('Unexpected error occured');
                setDeleteInProgress(false);
                setCommentIdDeleteInProgress(0);
            } else {
                const updatedListComments = [...listComments];
                const index = updatedListComments.indexOf(comment);
                if (index > -1) {
                    updatedListComments.splice(index, 1);
                }
                setListComments(updatedListComments);
                setDeleteInProgress(false);
                setCommentIdDeleteInProgress(0);
                handleCommentsListChange(updatedListComments);
            }
        }).catch(e => {
            console.log(e);
            setDeleteInProgress(false);
            setCommentIdDeleteInProgress(0);
        });
    }


    const comments = listComments.map(commentsItem =>
        <div>
            <Divider />
            <Card id={commentsItem.id} className={classes.addNewComment}>
                <CardHeader
                    action={userHasDeleteCommentPermission(commentsItem.author.id) && <IconButton onClick={() => handleDeleteCommentEvent(commentsItem)} aria-label="settings">
                        {deleteInProgress && commentIdDeleteInProgress === commentsItem.id && <CircularProgress color="primary" size={17} thickness={10} />}
                        {( !deleteInProgress || commentIdDeleteInProgress !== commentsItem.id ) && <Delete />}
                    </IconButton>
                    }
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            R
                        </Avatar>
                    }
                    title={commentsItem.author.nickname}
                    subheader={commentsItem?.createdDate ? DateUtils.convertToPresentableDate(commentsItem.createdDate) : ''}
                />
                <CardContent>
                    {commentsItem.body}
                </CardContent>
            </Card>
        </div>
    );

    


    return (
    <div>
        {comments}
                    <Divider />
                    <Card className={classes.addNewComment}>
                        <CardContent>
                            <TextField disabled={!userHasCommentPermission()} value={newComment} onChange={handleNewCommentInputValueChange} className="add-news-comment-text-field"
                                placeholder={resolveAddCommentInputPlaceholder()}
                                multiline
                                rows={2}
                                rowsMax={7}
                            /><div className="add-news-comment-button"><Button disabled={saveInProgress || !userHasCommentPermission()} variant="contained" color="primary" onClick={addComment}>{!saveInProgress && <span>Add</span>}{saveInProgress && <CircularProgress color="primary" size={17} thickness={10} />}</Button></div>
                        </CardContent>
                    </Card>
    </div>
    );
};

export default CommentsPanel