import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { CircularProgress } from '@material-ui/core';
import "./HomePageGallery.css";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function HomePageGallery(props) {
  const { classes, itemDetails, images, isLoading } = props;
  const { t, i18n } = useTranslation();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="https://mui.com/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          {t('home_page_gallery_header')}
        </Typography>
        <Swiper
                  style={{'swiper-button-next': {backgroundColor: 'red'}}}
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={5}
                  slidesPerView={1}
                  navigation
                  color="green"
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {isLoading && <CircularProgress color="primary" size={37} thickness={10}/>}
                  {!isLoading && itemDetails && images}
                  {!isLoading && !itemDetails.id && <div>{t('home_page_gallery_header_no_data')}No Data to display</div> }
                </Swiper>
      </Container>
    </section>
  );
}

HomePageGallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePageGallery);
