import AuthorityUtils from "./AuthorityUtils";

class SectionUtils {

    static shouldShowSection = function (authority, groups) {
        let show = false;

        if (authority) {
            show = AuthorityUtils.userHasAuthority(authority);
        } else {
            show = true;
        }

        if (groups && groups.length > 0 && groups.includes('menu')) {
            show = true;
        } else
            if (groups && groups.length === 0) {
                show = true;
            } else
                if (!groups) {
                    show = true;
                } else {
                    show = false;
                }


        return show;
    }
}

export default SectionUtils;