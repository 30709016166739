
class ExceptionHandlerService {
    
    constructor(sharedUserData, history, toast) {
        this.sharedUserData = sharedUserData;
        this.history = history;
        this.toast = toast;
    }

    handleMissingAuthResponse() {
        localStorage.removeItem('id');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('nickname');
        localStorage.removeItem('authorities');
        this.sharedUserData({});
        this.history.push(        {
            pathname: '/signin',
            search: '?errorMessage=Your Access Credentials were expired, please Sign In again'
        });
    }

    handleSignOut() {
        localStorage.removeItem('id');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('nickname');
        localStorage.removeItem('authorities');
        this.sharedUserData({});
        this.history.push(        {
            pathname: '/news'
        });
    }

    // toast types are success, danger, info, warning
    showErrorToast(message) {
        this.toast.current.showToast('warning', message);
    }

    showInfoToast(message) {
        this.toast.current.showToast('info', message);
    }
}

export default ExceptionHandlerService;