import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import { useHistory } from 'react-router-dom';
import { Chip, IconButton, Menu, MenuItem } from '@material-ui/core';
import './AppFooter.css';
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import { useTranslation } from "react-i18next";
import SectionUtils from 'util/SectionUtils';

function Copyright() {
  return (
    <Typography align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Pearl Art Store
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];


export default function AppFooter({ sharedUserData, sections }) {
  const classes = useStyles();
  const history = useHistory();
  const goToPage = function (path, pageName) {
    history.push({
      pathname: path,
      state: pageName
    });
  }

  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);
  const [regionAnchorEl, setRegionAnchorEl] = React.useState(null);

  const handleLanguageMenu = function (event) {
    setLanguageAnchorEl(event.currentTarget);
  }

  const handleChangeLanguage = (e) => {
    changeLanguage(e.target.textContent);
    handleClose();
    goToPage("/");
  }

  const handleRegionMenu = function (event) {
    setRegionAnchorEl(event.currentTarget);
  }

  const handleChangeRegion = (e) => {
    changeRegion(e.target.textContent);
    handleClose();
    goToPage("/");
  }

  const handleClose = () => {
    setLanguageAnchorEl(null);
    setRegionAnchorEl(null);
  };
  const { t, i18n } = useTranslation();

  const {
    changeLanguage,
    changeRegion,
    currentLanguage,
    currentRegion
  } = useLanguageAndRegionContext();

  const tagCloud = () => {
    return sections
      .filter(section => SectionUtils.shouldShowSection(section.requiredAuthority?.name, section.groups))
      .map(section => {
        return <Chip label={section.name} component="a" href={"/_/" + section.path} clickable color='secondary' style={{ margin: '4px' }} />;
      })
  }
  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={8} sm={6} md={4}>
            <div style={{ position: 'relative', left: '30%' }}>
              <Typography variant="h6" marked="left" gutterBottom>
                {t('footer_customer_service')}
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-left/helpAndContactUs", "Help & Contact Us")}>{t('footer_customer_service_help')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-left/termsAndConditions", "Terms & Conditions")}>{t('footer_customer_service_terms')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-left/privacyPolicy", "Privacy Policy")}>{t('footer_customer_service_privacy_policy')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-left/cookiesPolicy", "Cookies Policy")}>{t('footer_customer_service_cookies_policy')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={handleLanguageMenu}>{t('footer_customer_language')}:</Link>
                  <IconButton className="lang"
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleLanguageMenu}
                    color="inherit"
                  >
                    {currentLanguage}
                  </IconButton>
                  <Menu
                    id="menu-appbar3"
                    anchorEl={languageAnchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(languageAnchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={(e) => handleChangeLanguage(e)}>English</MenuItem>
                    {sharedUserData.firstname &&
                      <MenuItem onClick={(e) => handleChangeLanguage(e)}>Русский</MenuItem>
                    }
                    <MenuItem onClick={(e) => handleChangeLanguage(e)}>Polski</MenuItem>
                  </Menu>

                </li>
                <li className={classes.listItem}>
                  <Link onClick={handleRegionMenu}>{t('footer_customer_region')}:</Link>
                  <IconButton className="lang"
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleRegionMenu}
                    color="inherit"
                  >
                    {currentRegion}
                  </IconButton>
                  <Menu
                    id="menu-appbar3"
                    anchorEl={regionAnchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(regionAnchorEl)}
                    onClose={handleClose}
                  >
                    {/*<MenuItem onClick={(e) => handleChangeRegion(e)}>United Kingdom</MenuItem>
                  <MenuItem onClick={(e) => handleChangeRegion(e)}>Россия</MenuItem>
                <MenuItem onClick={(e) => handleChangeRegion(e)}>Беларусь</MenuItem>*/}
                    <MenuItem onClick={(e) => handleChangeRegion(e)}>Polska</MenuItem>
                  </Menu>
                </li>
              </ul>
            </div>

          </Grid>
          <Grid item xs={8} sm={6} md={4}>
            <div style={{ position: 'relative', left: '30%' }}>
              <Typography variant="h6" marked="left" gutterBottom>
                {t('footer_company')}
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-center/post/AboutUs", "About Us")}>{t('footer_company_about')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link href="/footer-center/posts/Blog">{t('footer_company_blog')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-center/post/OrderTracking", "Order Tracking")}>{t('footer_company_tracking')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-center/post/Faqs", "Faqs")}>{t('footer_company_faq')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link onClick={() => goToPage("/footer-center/post/Contact", "Contact")}>{t('footer_company_contact')}</Link>
                </li>
                <li className={classes.listItem}>
                  <Link href="/signin">{t('footer_company_login')}</Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={8} sm={6} md={4}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ position: 'relative', left: '30%' }}>
                  <Typography variant="h6" marked="left" gutterBottom>
                    {t('footer_social_media')}
                  </Typography>
                  <ul className={classes.list}>
                    <li className={classes.listItem}>
                      <Link href="/">{t('footer_social_media_instagram')}</Link>
                    </li>
                    <li className={classes.listItem}>
                      <Link href="/">{t('footer_social_media_pinterest')}</Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ position: 'relative', left: '30%', width: '70%' }}>
                  <Typography variant="h6" marked="left" gutterBottom>
                    {t('footer_learn_more')}
                  </Typography>
                  {tagCloud()}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
