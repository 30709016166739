class NewsFeedService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveNews() {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/posts`);

        //return Promise.resolve(this.news);
    }

    async retrieveNewsBySectionPathAndCountry(sectionPath, country) {
        const resolvedSectionPath = sectionPath ? sectionPath : null;
        const resolvedCountry = country ? country : null;
        return this.httpRequestExecutor.execute(`/api/posts?sectionPath=${resolvedSectionPath}&country=${resolvedCountry}`);
    }

    async retrieveNewsByPathAndCountry(path, country) {
        return this.httpRequestExecutor.execute(`/api/posts` + ( path ? ("?path=" + path) : '') + ( country ? ("&country=" + country) : ''));
    }

    async addNews(news, createSectionIfNotExists) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(news)
        };
        /*TODO: replace with reverse proxy */
        if(createSectionIfNotExists && createSectionIfNotExists === true) {
            return this.httpRequestExecutor.execute('/api/posts?createSectionIfNotExists=true', requestOptions);
        } else {
            return this.httpRequestExecutor.execute('/api/posts', requestOptions);
        }
        
    }

    async updateNews(news) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(news)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/posts', requestOptions);
    }

    async deleteNews(newsId) {
        /*TODO: replace with reverse proxy */
        const requestOptions = {
            method: 'DELETE',
            body: {}
        };
        return this.httpRequestExecutor.execute(`/api/posts/${newsId}`, requestOptions);
    }

}

export default NewsFeedService;