import { Button, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Switch, Checkbox, TextField, Typography } from "@material-ui/core";
import { ContactMail, Dashboard, Home, Info } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useLanguageAndRegionContext } from 'util/LanguageAndRegionContextProvider';
import { useTranslation } from "react-i18next";

const SectionEditor = ({
    sectionService,
    tileService,
    exceptionHandlerService,
    parentSectionIncoming,
    parentSectionTileIncoming,
    sectionTypeIncoming,
    countrySelectionEnabled,
    parentSelectionEnabled,
    typeSelectionEnabled,
    onSuccess,
    onClose,
    performBackendCall,
    existingSection
}) => {

    const [sectionsListLoading, setSectionsListLoading] = React.useState(false);
    const [sectionType, setSectionType] = React.useState(sectionTypeIncoming ? sectionTypeIncoming : 'tiles');
    const [parentSection, setParentSection] = React.useState(parentSectionIncoming ? parentSectionIncoming.id : 'None');
    const [parentSectionTile, setParentSectionTile] = React.useState(parentSectionTileIncoming ? parentSectionTileIncoming.id : 'None');
    const [sectionIcon, setSectionIcon] = React.useState('dashboard');
    const [tilesSections, setTilesSections] = React.useState(parentSectionIncoming ? [parentSectionIncoming] : []);
    const [parentSectionTiles, setParentSectionTiles] = React.useState(parentSectionTileIncoming ? [parentSectionTileIncoming] : []);
    const [postStaticHeightEnabled, setPostStaticHeightEnabled] = React.useState(false);
    const [parentSectionTilesLoading, setParentSectionTilesLoading] = React.useState(false);
    const [customTileHeightEnabled, setCustomTileHeightEnabled] = React.useState(false);
    const [sectionName, setSectionName] = React.useState('');
    const [sectionPath, setSectionPath] = React.useState('');
    const [shadeIfNoDescendants, setShadeIfNoDescendants] = React.useState(true);
    const [postHeightScale, setPostHeightScale] = React.useState(10);
    const [countryInputValid, setCountryInputValid] = React.useState(true);
    const [parentSectionTileShouldBeSelected, setParentSectionTileShouldBeSelected] = React.useState(false);
    const [atLeastOneGroupShouldBeSelected, setAtLeastOneGroupShouldBeSelected] = React.useState(false);
    const [duplicatePathFound, setDuplicatePathFound] = React.useState(false);
    const [tilesPerLineInputCorrect, setTilesPerLineInputCorrect] = React.useState(true);
    const [sectionNameInputCorrect, setSectionNameInputCorrect] = React.useState(true);
    const [sectionPathInputCorrect, setSectionPathInputCorrect] = React.useState(true);
    const [tilesPerLine, setTilesPerLine] = React.useState(3);
    const [customTileHeightScale, setCustomTileHeightScale] = React.useState(10);
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [sections, setSections] = React.useState([]);
    // @ts-ignore
    const { currentRegion } = useLanguageAndRegionContext();
    const [country, setCountry] = React.useState(currentRegion);
    const [sectionGroupCheckboxState, setSectionGroupCheckboxState] = React.useState({
        menu: true,
        footerLeft: false,
        footerMiddle: false,
        footerRight: false,
    });
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (existingSection) {
            applyDataFromExistingSection(existingSection);
        }
    }, [existingSection]);

    const applyDataFromExistingSection = (value) => {
        let parSection = value.parentSection;
        if (value && parSection && parSection.id) {
            setParentSection(parSection.id);
        } else if (!parentSection) {
            setParentSection('');
        }
        if (value && value.parentTile && value.parentTile.id) {

            if (parSection) {
                if (parSection.path) {
                    setParentSectionTilesLoading(true);
                    tileService.retrieveTilesBySectionPathAndCountry(parSection.path, currentRegion).then(result => {

                        setParentSectionTiles(result);
                        setParentSectionTilesLoading(false);
                        setParentSectionTile(value.parentTile.id);
                    }).catch(e => {
                        console.log(e);
                        setParentSectionTilesLoading(false);
                    });
                }
            }
        } else if (!parentSectionTile) {
            setParentSectionTile('None');
        }
        if (value && value.type) {
            setSectionType(value.type);
        }
        if (value && value.groups) {
            let sectionGroupCheckboxStateLocal = { ...sectionGroupCheckboxState };
            for (let group in sectionGroupCheckboxStateLocal) {
                sectionGroupCheckboxStateLocal[group] = false;
            }
            value.groups.forEach(group => {
                sectionGroupCheckboxStateLocal[group] = true;
            });
            setSectionGroupCheckboxState(sectionGroupCheckboxStateLocal);
        }

        if (value && value.name) {
            setSectionName(value.name);
        } else {
            setSectionName('');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'tilesPerLine').length > 0) {
            setTilesPerLine(value.sectionConfigs.filter(sc => sc.key === 'tilesPerLine')[0].value);
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled').length > 0) {
            setCustomTileHeightEnabled(value.sectionConfigs.filter(sc => sc.key === 'customTileHeightEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants').length > 0) {
            setShadeIfNoDescendants(value.sectionConfigs.filter(sc => sc.key === 'shadeIfNoDescendants')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale').length > 0) {
            setCustomTileHeightScale(+value.sectionConfigs.filter(sc => sc.key === 'customTileHeightScale')[0].value * 100);
        }

        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled').length > 0) {
            setPostStaticHeightEnabled(value.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled')[0].value === 'true');
        }
        if (value && value.sectionConfigs && value.sectionConfigs.filter(sc => sc.key === 'postHeightScale').length > 0) {
            setPostHeightScale(+value.sectionConfigs.filter(sc => sc.key === 'postHeightScale')[0].value * 100);
        }


        if (value && value.icon) {
            setSectionIcon(value.icon);
        } else {
            setSectionIcon('');
        }
        if (value && value.path && value.path.length > 0) {
            const p = value.path.split('/').slice(-1)[0];
            setSectionPath(p);
        } else {
            setSectionPath('');
        }
        if (value && value.countryName && value.countryName.length > 0) {
            setCountry(value.countryName);
        }
    };

    const handleGroupInputChange = (event) => {
        setSectionGroupCheckboxState({ ...sectionGroupCheckboxState, [event.target.name]: event.target.checked });
    };

    const handleSectionTypeChange = (event) => {
        setSectionType(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setCountryInputValid(true);
    };

    const loadAvailableSections = () => {
        if (tilesSections.length === 0) {
            setSectionsListLoading(true);
            sectionService.retrieveAllSections().then(result => {
                setTilesSections(result.filter(s => s.type == "tiles"));
                setSectionsListLoading(false);
            }).catch(e => {
                console.log(e);
                setSectionsListLoading(false);
            });
        }

    }

    const handleParentSectionChange = (event) => {
        setParentSection(event.target.value);
        setParentSectionTile('None');
    };

    const handleParentSectionTileChange = (event) => {
        setParentSectionTile(event.target.value);
    };

    const handleSectionIconSelect = (event, newIcon) => {
        setSectionIcon(newIcon);
    }

    const handleSectionNameInputValueChange = (e) => {
        setSectionNameInputCorrect(nameInputCorrect(e.target.value));
        setSectionName(e.target.value);
    }

    const nameInputCorrect = (val) => {
        var pattern = /^[A-Za-z0-9- ]+$/;
        if (val && val.length > 0 && val.length < 50 && val.match(pattern)) {
            return true;
        }

        return false;
    }

    const handleSectionPathInputValueChange = (e) => {
        setSectionPathInputCorrect(textFieldValueCorrect(e.target.value));
        setSectionPath(e.target.value);
    }

    const handleSwitch = (e) => {
        setCustomTileHeightEnabled(e.target.checked);
    }

    const textFieldValueCorrect = (val) => {
        var pattern = /^[A-Za-z0-9-]+$/;
        if (val && val.length > 0 && val.length < 50 && val.match(pattern)) {
            return true;
        }

        return false;
    }

    const handleShadeIfNoDescendantsSwitch = (e) => {
        setShadeIfNoDescendants(e.target.checked);
    }

    const handlePostSwitch = (e) => {
        setPostStaticHeightEnabled(e.target.checked);
    }


    const handleTilesPerLineInputValueChange = (e) => {
        const tillesPerLineInput = e.target.value;
        // @ts-ignore
        if (tillesPerLineInput && +tillesPerLineInput !== NaN && +tillesPerLineInput > 0 && +tillesPerLineInput <= 4) {
            setTilesPerLineInputCorrect(true);
        } else {
            setTilesPerLineInputCorrect(false);
        }
        setTilesPerLine(e.target.value);
    }

    // @ts-ignore
    const handleSliderChange = (e, value) => {
        if (value) {
            setCustomTileHeightScale(value);
        }
    }

    // @ts-ignore
    const handlePostSliderChange = (e, value) => {
        if (value) {
            setPostHeightScale(value);
        }
    }

    const loadAvailableSectionTiles = () => {
        if (parentSection && parentSection !== 'None') {
            let parentSectionFiltered = tilesSections.filter(s => s.id === parentSection);
            let parentSectionPath = null;
            if (parentSectionFiltered && parentSectionFiltered.length > 0) {
                parentSectionPath = parentSectionFiltered[0].path;
            }
            if (parentSectionPath) {
                setParentSectionTilesLoading(true);
                tileService.retrieveTilesBySectionPathAndCountry(parentSectionPath, currentRegion).then(result => {
                    setParentSectionTiles(result);
                    setParentSectionTilesLoading(false);
                }).catch(e => {
                    console.log(e);
                    setParentSectionTilesLoading(false);
                });
            }
        }

    }

    const validateInputs = function (sectionNameInputCorrectCustom, sectionPathInputCorrectCustom) {
        let sectionNameICorrect = false;
        let sectionPathICorrect = false;
        if (typeof sectionNameInputCorrectCustom === "boolean") {
            sectionNameICorrect = sectionNameInputCorrectCustom;
        } else {
            sectionNameICorrect = sectionNameInputCorrect;
        }
        if (typeof sectionPathInputCorrectCustom === "boolean") {
            sectionPathICorrect = sectionPathInputCorrectCustom;
        } else {
            sectionPathICorrect = sectionPathInputCorrect;
        }
        return tilesPerLineInputCorrect && sectionNameICorrect && sectionPathICorrect && countryInputValid;
    }

    const handleClose = function () {
        resetState();
        onClose();
    }

    const resetState = function () {
        setSectionNameInputCorrect(true);
        setSectionPathInputCorrect(true);
        setSectionName('');
        setSectionPath('');
        setParentSectionTile('None');
        setParentSection('None');
        setParentSectionTiles([]);
        setSectionIcon('dashboard');
        setTilesPerLine(3);
        setTilesPerLineInputCorrect(true);
        setCustomTileHeightEnabled(false);
        setShadeIfNoDescendants(true);
        setCustomTileHeightScale(50);
        setDuplicatePathFound(false);
        setParentSectionTileShouldBeSelected(false);
        setAtLeastOneGroupShouldBeSelected(false);
        setPostHeightScale(10);
        setPostStaticHeightEnabled(false);
        setSectionGroupCheckboxState({
            menu: true,
            footerLeft: false,
            footerMiddle: false,
            footerRight: false,
        });
    }

    const handleSave = function () {
        if (sectionGroupCheckboxState.footerLeft === false
            && sectionGroupCheckboxState.footerMiddle === false
            && sectionGroupCheckboxState.footerRight === false
            && sectionGroupCheckboxState.menu === false
            && (parentSection === "None" || parentSection === '' || !parentSection)) {
            setAtLeastOneGroupShouldBeSelected(true);
        } else if (parentSectionTile === 'None' && parentSection !== 'None') {
            setParentSectionTileShouldBeSelected(true);
        } else {
            handleSaveUpdate();
        }
    }

    const handleSaveUpdate = function () {
        const sectionNameCorrect = nameInputCorrect(sectionName);
        const sectionPathCorrect = textFieldValueCorrect(sectionPath);
        setSectionNameInputCorrect(sectionNameCorrect);
        setSectionPathInputCorrect(sectionPathCorrect);
        const isValid = validateInputs(sectionNameCorrect, sectionPathCorrect);
        if (!isValid) {
            return;
        }
        let parentSectionObj = null;
        let parentTileObj = null;
        let isRoot = existingSection?.isRoot ?? true;
        if (parentSection && parentSection !== 'None') {
            parentSectionObj = {
                id: parentSection
            }
            parentTileObj = {
                id: parentSectionTile
            }
            isRoot = false;
        }
        let groups = [];
        for (let group in sectionGroupCheckboxState) {
            if (sectionGroupCheckboxState[group] === true) {
                groups.push(group);
            }
        }
        setSaveLoading(true);
        let newSectionConfigs = null;
        if (sectionType === "tiles") {
            newSectionConfigs = [{
                key: "tilesPerLine",
                value: '' + tilesPerLine
            }, {
                key: "shadeIfNoDescendants", value: '' + shadeIfNoDescendants
            }];

            if (customTileHeightEnabled === true) {
                newSectionConfigs.push({
                    key: "customTileHeightScale",
                    value: '' + (customTileHeightScale / 100)
                });
                newSectionConfigs.push(
                    {
                        key: "customTileHeightEnabled",
                        value: '' + customTileHeightEnabled
                    });
            }


        }
        if (sectionType === "posts" && postStaticHeightEnabled === true) {
            newSectionConfigs = [{
                key: "postHeightScale",
                value: '' + (postHeightScale / 100)
            },

            {
                key: "postStaticHeightEnabled",
                value: '' + postStaticHeightEnabled
            }];
        }

        let mergedSectionConfigs = [];
        if (existingSection?.sectionConfigs && newSectionConfigs) {
            existingSection.sectionConfigs.forEach(sc => {
                const scNew = { ...sc };
                const matchingNewSectionConfig = newSectionConfigs.filter(nsc => nsc.key === scNew.key);
                if (matchingNewSectionConfig.length > 0) {
                    scNew.value = matchingNewSectionConfig[0].value;
                    mergedSectionConfigs.push(scNew);
                }
            })
            newSectionConfigs.forEach(nsc => {
                const matchingExistingSectionConfig = mergedSectionConfigs.filter(sc => sc.key === nsc.key);
                if (matchingExistingSectionConfig.length === 0) {
                    mergedSectionConfigs.push(nsc);
                }
            })
        } else if (existingSection?.sectionConfigs) {
            mergedSectionConfigs = existingSection?.sectionConfigs;
        } else {
            mergedSectionConfigs = newSectionConfigs;
        }


        const sectionToPersist = {
            id: existingSection?.id,
            name: sectionName,
            parentSection: parentSectionObj,
            parentTile: parentTileObj,
            path: sectionPath,
            type: sectionType,
            sectionConfigs: mergedSectionConfigs,
            icon: sectionIcon,
            isRoot: isRoot,
            countryName: country,
            groups: groups
        };
        if (performBackendCall) {


            sectionService.addSection(sectionToPersist).then(response => {
                if (response.error && response.status === 401) {
                    exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
                    resetState();
                } else if (response.error) {
                    if (response.message && response.message === 'Provided section path already used by another section') {
                        setDuplicatePathFound(true);
                    } else {
                        exceptionHandlerService.showErrorToast('Unexpected error occured');
                        resetState();
                    }

                } else {
                    if (isRoot) {
                        setSections([...sections, response]);
                    }
                    resetState();
                    onSuccess(response);
                }

            }).catch(e => {
                console.log(e);
                resetState();
            });

        } else {
            onSuccess(sectionToPersist);
        }
    }


    return (<>
        <Grid container spacing={1} xs={12}>
            <Grid item xs={4}>
                <InputLabel id="section-type-select-label">{t('section_editor_section_type')}</InputLabel>
                <Select
                    disabled={!typeSelectionEnabled}
                    labelId="section-type-select-label"
                    id="demo-simple-select"
                    value={sectionType}
                    onChange={handleSectionTypeChange}
                >
                    <MenuItem value="tiles">{t("section_editor_section_type_tiles")}</MenuItem>
                    <MenuItem value="posts">{t("section_editor_section_type_posts")}</MenuItem>
                    <MenuItem value="post">{t("section_editor_section_type_post")}</MenuItem>
                    <MenuItem value="details">{t("section_editor_section_type_item_details")}</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3}>
                <InputLabel id="parent-section-select-label">{t("section_editor_parent_section")}</InputLabel>
                <Select
                    disabled={!parentSelectionEnabled}
                    labelId="parent-section-select-label"
                    id="parent-section-select"
                    onOpen={loadAvailableSections}
                    value={parentSection}
                    onChange={handleParentSectionChange}

                >
                    <MenuItem value="None">None</MenuItem>
                    {sectionsListLoading && <MenuItem value="Loading">{t("section_editor_loading_data")}</MenuItem>}
                    {!sectionsListLoading &&
                        tilesSections.map(section => (
                            <MenuItem key={section.id} value={section.id}>{section.name}</MenuItem>
                        ))
                    }
                </Select>
            </Grid>
            <Grid item xs={3}>
                <InputLabel id="parent-section-tile-select-label">{t("section_editor_parent_section_tile")}</InputLabel>
                <Select
                    error={parentSectionTileShouldBeSelected}
                    labelId="parent-section-tile-select-label"
                    id="parent-section-tile-select"
                    onOpen={loadAvailableSectionTiles}
                    value={parentSectionTile}
                    onChange={handleParentSectionTileChange}
                    disabled={parentSection === 'None' || !parentSelectionEnabled}

                >
                    <MenuItem value="None">None</MenuItem>
                    {parentSectionTilesLoading && <MenuItem value="Loading">{t("section_editor_loading_data")}</MenuItem>}
                    {!parentSectionTilesLoading &&
                        parentSectionTiles.map(t => (
                            <MenuItem key={t.id} value={t.id}>{t.header}</MenuItem>
                        ))
                    }
                </Select>
                {parentSectionTileShouldBeSelected && <Typography color='error'>{t("section_editor_parent_section_should_be_selected")}</Typography>}

            </Grid>

            <Grid item xs={6}>
                <Typography>{t("section_editor_section_icon")}</Typography>
                <ToggleButtonGroup
                    value={sectionIcon}
                    exclusive
                    onChange={handleSectionIconSelect}
                    aria-label="Section Icon"
                >
                    <ToggleButton disabled={parentSection !== 'None'} value="dashboard" aria-label="Dashboard">
                        <Dashboard />
                    </ToggleButton>
                    <ToggleButton disabled={parentSection !== 'None'} value="home" aria-label="Home">
                        <Home />
                    </ToggleButton>
                    <ToggleButton disabled={parentSection !== 'None'} value="info" aria-label="Info">
                        <Info />
                    </ToggleButton>
                    <ToggleButton disabled={parentSection !== 'None'} value="contactMail" aria-label="ContactMail">
                        <ContactMail />
                    </ToggleButton>
                </ToggleButtonGroup>

            </Grid>
            <Grid item xs={6}>
                {t('section_editor_section_location')}
                <Grid item xs={12}>
                    <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                        checked={sectionGroupCheckboxState.menu && parentSection == 'None'}
                        onChange={handleGroupInputChange}
                        name="menu"
                        color="primary" />} label={t('section_editor_section_location_menu')} />
                </Grid>
                <Grid item xs={12}>                     <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                    checked={sectionGroupCheckboxState.footerLeft && parentSection == 'None'}
                    onChange={handleGroupInputChange}
                    name="footerLeft"
                    color="primary"
                />} label={t('section_editor_section_location_footer_left')} /></Grid>
                <Grid item xs={12}> <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                    checked={sectionGroupCheckboxState.footerMiddle && parentSection == 'None'}
                    onChange={handleGroupInputChange}
                    name="footerMiddle"
                    color="primary"
                />} label={t('section_editor_section_location_footer_middle')} /></Grid>
                <Grid item xs={12}>  <FormControlLabel disabled={parentSection !== 'None'} control={<Checkbox
                    checked={sectionGroupCheckboxState.footerRight && parentSection == 'None'}
                    onChange={handleGroupInputChange}
                    name="footerRight"
                    color="primary"
                />} label={t('section_editor_section_location_footer_right')} /></Grid>
                {atLeastOneGroupShouldBeSelected && <Typography color='error'>{t('section_editor_section_location_err_msg')}</Typography>}

            </Grid>
            <Grid item xs={12}>
                <TextField required id="section-name" label={t('section_editor_section_name')} value={sectionName} onChange={handleSectionNameInputValueChange} error={!sectionNameInputCorrect} />

            </Grid>
            <Grid item xs={12}>
                <TextField required id="section-path" label={t('section_editor_section_path')} value={sectionPath} onChange={handleSectionPathInputValueChange} error={!sectionPathInputCorrect || duplicatePathFound} />
                {duplicatePathFound && <Typography color='error'>{t('section_editor_path_already_used')}</Typography>}

            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!countrySelectionEnabled}
                    id="filled-select-country"
                    select
                    label={t('section_editor_country')}
                    value={country}
                    onChange={handleCountryChange}
                    helperText={t('section_editor_country_select_msg')}
                    variant="filled"
                    className={`${countryInputValid ? '' : 'error'}`}
                >
                    <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
                    <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
                    <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
                    <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
                </TextField>
            </Grid>
            {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                <TextField required id="tiles-per-line" label={t('section_editor_tiles_per_line_label')} value={tilesPerLine} onChange={handleTilesPerLineInputValueChange} error={!tilesPerLineInputCorrect} />
                {!tilesPerLineInputCorrect && <Typography color='error'>{t('section_editor_tiles_per_line_limitation_msg')}</Typography>}
            </Grid>}

            {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                <Typography>{t('section_editor_shade_if_no_descendants')}</Typography>
                <Switch checked={shadeIfNoDescendants} onChange={handleShadeIfNoDescendantsSwitch} />
            </Grid>}

            {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                <Typography>{t('section_editor_custom_tile_height_enabled')}</Typography>
                <Switch checked={customTileHeightEnabled} onChange={handleSwitch} />
            </Grid>}

            {sectionType && sectionType === "tiles" && <Grid item xs={12}>
                <Typography>{t('section_editor_custom_tile_height_scale')}</Typography>
                <Slider style={{ width: 200 }} disabled={!customTileHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handleSliderChange} value={customTileHeightScale} />
            </Grid>}

            {sectionType && sectionType === "posts" && <Grid item xs={12}>
                <Typography>{t('section_editor_post_static_height_enabled')}</Typography>
                <Switch checked={postStaticHeightEnabled} onChange={handlePostSwitch} />
            </Grid>}

            {sectionType && sectionType === "posts" && <Grid item xs={12}>
                <Typography>{t('section_editor_post_height_scale')}</Typography>
                <Slider style={{ width: 200 }} disabled={!postStaticHeightEnabled} step={10} about='dasdas' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" onChange={handlePostSliderChange} value={postHeightScale} />
            </Grid>}
            <Grid item xs={12}>
                <Button autoFocus onClick={handleClose} color="secondary">
                {t('section_editor_cancel_button')}
                </Button>
                <Button autoFocus onClick={handleSave} color="secondary" disabled={sectionsListLoading || parentSectionTilesLoading || validateInputs() === false}>
                {t('section_editor_save_changes')}
                </Button>
            </Grid>
        </Grid>
    </>);
};

export default SectionEditor;