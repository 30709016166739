import { Badge, Box, Breadcrumbs, Button, Container, Divider, Grid, IconButton, ImageList, ImageListItem, Link, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Delete, ShoppingCart } from "@material-ui/icons";
import Paper from "components/modules/components/Paper";
import FormButton from "components/modules/form/FormButton";
import React, { useEffect } from "react";
import { useShoppingCart } from "util/ShoppingCartContextProvider";
import "./shoppingCart.css";
import { t } from "i18next";
import SEO from "util/SEO";


const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundImage: 'url(/static/onepirate/appCurvyLines.png)',
        backgroundRepeat: 'no-repeat',
    },
    paper: {
        padding: theme.spacing(4, 3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(8, 6),
        },
    },
}));

const ShoppingCartPage = () => {

    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const f1 = function () {

    }

    const f2 = function () {

    }

    const {
        getSelectedTilesMap,
        getSelectedItemDetailsMap,
        deleteItemDetailsFromCart,
        deleteTileFromCart,
        itemsCount
    } = useShoppingCart();
    
    const [totalCost, setTotalCost] = React.useState(0);
    useEffect(() => {
        console.log(getSelectedTilesMap());
        console.log(getSelectedItemDetailsMap());
        calculateTotalCost();
      }, []);

      const handleDeleteTile = function(val) {
        deleteTileFromCart(val);
        calculateTotalCost();

      }

      const handleDeleteItemDetails = function(val) {
        deleteItemDetailsFromCart(val);
        calculateTotalCost();
    }

      const getItemsFromSelectedTiles = function() {
        console.log('ok');
        return Array.from( getSelectedTilesMap() ).map(([key, value]) => 
        <Grid item xs={12}>
        <Paper className="item">
            <Grid container spacing={2}>
                {!isMobile &&
                    <Grid item xs={4}>
                        <ImageList rowHeight={160} cols={1}>

                            {value.body && <ImageListItem key="somekey" cols={1}>
                                <img src={value.body} ></img>
                            </ImageListItem>}
                            {!value.body && <ImageListItem key="somekey" cols={1}>
                                <img src={'/fstor/' + value.image.pathSmall} ></img>
                            </ImageListItem>}

                        </ImageList>

                    </Grid>}
                <Grid item xs={12} sm={12} md={8} >

                    <Grid container spacing={0} className="item-content">
                        <Grid item xs={11} className="item-content-header">
                            <Typography component={Link} variant="h6" color="primary" href={'_/' + value.childSection.path} gutterBottom>{value.header}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={() => handleDeleteTile(value)}
                                size="medium"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                            >

                                <Delete/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className="item-content-body">
                            {isMobile &&

                                <ImageList rowHeight={160} cols={1}>

                                    <ImageListItem key="somekey" cols={1}>
                                        <img src={value.body} ></img>
                                    </ImageListItem>

                                </ImageList>

                            }
                            <Typography variant="subtitle1" color="primary" gutterBottom>Quantity: 1</Typography>
                            
                         </Grid>
                        <Grid item xs={12} className="item-content-footer">
                            <Divider/>
                            <Typography className="item-cost" component="h2" variant="h6" color="primary" gutterBottom>Price: {value.commercialData.cost} {value.commercialData.currency}</Typography>

                        </Grid>

                    </Grid>




                </Grid>
            </Grid>




        </Paper>
    </Grid>);
      }


      const getItemsFromSelectedItemData = function() {
        console.log('ok');
        return Array.from( getSelectedItemDetailsMap() ).map(([key, value]) => 
        <Grid item xs={12}>
        <Paper className="item">
            <Grid container spacing={2}>
                {!isMobile &&
                    <Grid item xs={4}>
                        {value.images && 
                        <ImageList rowHeight={160} cols={1}>

                            <ImageListItem key="somekey" cols={1}>
                                <img src={'/fstor/' + value.images[0].pathSmall} ></img>
                            </ImageListItem>

                        </ImageList>}

                    </Grid>}
                <Grid item xs={12} sm={12} md={8} >

                    <Grid container spacing={0} className="item-content">
                        <Grid item xs={11} className="item-content-header">
                            <Typography component={Link} variant="h6" color="primary" gutterBottom href={'_/' + value.section.path}>{value.header}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={() => handleDeleteItemDetails(value)}
                                size="medium"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                            >

                                <Delete />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className="item-content-body">
                            {isMobile && value.images && 
                                <ImageList rowHeight={160} cols={1}>

                                    <ImageListItem key="somekey" cols={1}>
                                        <img src={'/fstor/' + value.images[0].pathSmall} ></img>
                                    </ImageListItem>

                                </ImageList>       
                            }
                            <Typography variant="subtitle1" color="primary" gutterBottom>{t('shopping_cart_quantity')}: 1</Typography>                       </Grid>
                        <Grid item xs={12} className="item-content-footer">
                            <Divider />
                            <Typography className="item-cost" component="h2" variant="h6" color="primary" gutterBottom>{t('shopping_cart_price')}: {value.commercialData.cost} {value.commercialData.currency}</Typography>

                        </Grid>

                    </Grid>




                </Grid>
            </Grid>




        </Paper>
    </Grid>);
      }

      const calculateTotalCost = function () {
        const selectedItemDetails = getSelectedItemDetailsMap();
        const selectedTiles = getSelectedTilesMap();
        let totalCost = 0;
        Array.from( selectedItemDetails  ).forEach(([key, value]) => {
            totalCost += +value.commercialData.cost;
        });
        Array.from( selectedTiles  ).forEach(([key, value]) => {
            totalCost += +value.commercialData.cost;
        });
        setTotalCost(totalCost);
      }

    return (

        <div>
            <SEO title={t('breadcrumbs_cart')} description={t('breadcrumbs_cart')} name="Pearl Art Store" image={null}/>
            <Container maxWidth="lg" className="breadcrumbs">
                <Grid key="breadcrumbs" item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            {t('breadcrumbs_home')}
                        </Link>
                        <Typography color="primary">{t('breadcrumbs_cart')}</Typography>
                    </Breadcrumbs>

                </Grid>
                
            </Container>
            
            <Container className="container">
                
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={12} md={8}>
                        <Grid container spacing={2}>
                            {getItemsFromSelectedTiles()}
                            {getItemsFromSelectedItemData()}
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper className="go-to-order">
                            <div className="go-to-order-content">
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>{t('shopping_cart_total')}: {totalCost} PLN</Typography>
                            </div>
                            <div className="go-to-order-button">

                                <FormButton
                                    disabled={itemsCount === 0}
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                    onClickCapture={f1}
                                    onKeyPress={f2}
                                    href="/order"
                                >
                                    {t('shopping_cart_go_to_order')}
                                </FormButton>
                            </div>

                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );

}

export default ShoppingCartPage;