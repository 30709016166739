import { Badge, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { ShoppingCart } from '@material-ui/icons';
import { useShoppingCart } from "./ShoppingCartContextProvider";
import "./shoppingCart.css";

const ShoppingCartComponent = () => {
    const {
        addTileToCart,
        addItemDetailsToCart,
        itemsCount
      } = useShoppingCart();

      useEffect(() => {
      }, []);

    return (
        <div className="scw">            <IconButton className="sc"
            size="medium"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
        >
            <Badge hidden={itemsCount == 0} badgeContent={itemsCount} color="secondary">
                <ShoppingCart /></Badge>
        </IconButton></div>
    );
}

export default ShoppingCartComponent;