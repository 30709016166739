class TileService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveTiles() {
        return this.httpRequestExecutor.execute(`/api/tiles`);
    }

    async retrieveTileById(tileId) {
        return this.httpRequestExecutor.execute(`/api/tiles/${tileId}`);
    }

    async retrieveTilesBySectionPathAndCountry(sectionPath, country) {
        const resolvedSectionPath = sectionPath ? sectionPath : null;
        const resolvedCountry = country ? country : null;
        return this.httpRequestExecutor.execute(`/api/tiles?sectionPath=${resolvedSectionPath}&country=${resolvedCountry}`);
    }

    async searchTiles(sectionPath, country, pageNumber, pageSize, orderBy, orderDirection, excludeIntermediate) {
        const resolvedSectionPath = sectionPath ? sectionPath : null;
        const resolvedCountry = country ? country : null;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "excludeIntermediate": excludeIntermediate,
                "sectionCountry": resolvedCountry,
                "sectionPath": resolvedSectionPath,
                "pageNumber": pageNumber,
                "pageSize": pageSize,
                "orderBy": orderBy,
                "orderDirection": orderDirection,
            })
        };
        return this.httpRequestExecutor.execute(`/api/tiles/search`, requestOptions);
    }

    addTile(tile) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(tile)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/tiles', requestOptions);
    }

    updateTile(tile) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(tile)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/tiles', requestOptions);
    }

    async deleteTile(tileId) {
        /*TODO: replace with reverse proxy */
        const requestOptions = {
            method: 'DELETE',
            body: {}
        };
        return this.httpRequestExecutor.execute(`/api/tiles/${tileId}`, requestOptions);
    }

}

export default TileService;