class FileService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    addTempFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        const requestOptions = {
            method: 'POST',
           /// headers: { 'Content-Type': 'multipart/form-data' },
            body: formData
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/files/images/temp', requestOptions);
    }

    updateHomePageHeader(file) {
        const formData = new FormData();
        formData.append('file', file);
        const requestOptions = {
            method: 'POST',
           /// headers: { 'Content-Type': 'multipart/form-data' },
            body: formData
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/files/images/homepage/header', requestOptions);
    }

    updateLogo(file) {
        const formData = new FormData();
        formData.append('file', file);
        const requestOptions = {
            method: 'POST',
           /// headers: { 'Content-Type': 'multipart/form-data' },
            body: formData
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/files/images/logo', requestOptions);
    }

}

export default FileService;