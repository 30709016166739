class LoginService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }
    
    async login(username, password) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': this.createBasicAuthValue(username, password) }
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/users?name=${username}`, requestOptions);
    }

    signout() {
        const requestOptions = {
            method: 'POST'
        };
        return this.httpRequestExecutor.execute(`/api/logout`, requestOptions);
    }

    createBasicAuthValue(user, password) {
        var tok = user + ':' + password;
        var hash = Buffer.from(tok).toString('base64');
        return "Basic " + hash;
      }
}

export default LoginService;