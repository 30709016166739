class ItemDetailsService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveItemDetails() {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/itemDetails`);

        //return Promise.resolve(this.news);
    }

    async retrieveItemDetailsBySectionPathAndCountry(sectionPath, country) {
        const resolvedSectionPath = sectionPath ? sectionPath : null;
        const resolvedCountry = country ? country : null;
        return this.httpRequestExecutor.execute(`/api/itemDetails?sectionPath=${resolvedSectionPath}&country=${resolvedCountry}`);
    }

    async addItemDetails(itemDetails) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDetails)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/itemDetails', requestOptions);
    }

    async updateItemDetails(itemDetails) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDetails)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/itemDetails', requestOptions);
    }
}

export default ItemDetailsService;