import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import "./StatisticsPageItem.css";

export default function StatisticsPageItem({ title, itemHeight, children }) {
    return <>
        <div id="statisticsPageItem">
            <Card style={{ height: itemHeight }}>
                <CardHeader
                    title={title}
                />
                <CardContent id="content">
                    {children}
                </CardContent>
            </Card>

        </div>
    </>
}