import React, { useState } from 'react';

const WelcomePage = () => {
    const firstName = localStorage.getItem('firstname');
    const lastName = localStorage.getItem('lastname');
    return (

        <div>
            Welcome, {firstName} {lastName}
        </div>

    );
}

export default WelcomePage;
