import React, { useEffect, useRef, useState } from "react";
import { Jodit } from "jodit";
import { Button, CircularProgress, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import './NewsEditor.css';
import "jodit/build/jodit.css";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";

const NewsEditor = ({ newsFeedService, exceptionHandlerService, newsItem, setOpen, updateEventHandler, sectionPath, createSectionIfNotExists, group, usePathEditor, sectionType, sectionName }) => {

  var editor = useRef(null);
  const [header, setHeader] = useState(newsItem?.header ? newsItem.header.slice() : "");
  const [path, setPath] = useState(newsItem?.path ? newsItem.path.split('/').slice(-1)[0] : "");
  const [isPathCorrect, setIsPathCorrect] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ed, setEd] = useState(null);
  const [isHeaderCorrect, setIsHeaderCorrect] = useState(true);
  const history = useHistory();

  const {
    currentLanguage,
    currentRegion
  } = useLanguageAndRegionContext();

  const [language, setLanguage] = React.useState(newsItem?.languageName ? newsItem.languageName : currentLanguage);
  const [country, setCountry] = React.useState(currentRegion);
  const [languageInputValid, setLanguageInputValid] = React.useState(true);
  const [countryInputValid, setCountryInputValid] = React.useState(true);


  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    setLanguageInputValid(true);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryInputValid(true);
  };

  const config = {
    height: "70vh",
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    iframe: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: '/api/files/images/posts/temp',

      isSuccess: function (resp) {
        return true;
      },
      process: function (resp) {
        console.log(resp);
        return {
          files: resp.files || ["file"], // {array} The names of uploaded files.
          path: "/fstor/" + resp.pathMedium,
          baseurl: "/fstor/" + resp.pathMedium,
          msg: "msg"
        };
      },
      defaultHandlerSuccess: function (response) { // `this` is the editor.
        const j = this;
        if (response.files && response.files.length) {
          response.files.forEach((filename, index) => { //edetor insertimg function
            const image = document.createElement('img');
            image.setAttribute('src', response.path);
            j.selection.insertHTML(image);
          });
        }
      }
    },
    toolbarAdaptive: false,
    link: {
      processVideoLink: true
    },
    selectionCellStyle: 'border: 1px double #1e88e5 !important;',
    useExtraClassesOptions: true,
    table: {
      data: {
        classList: {
          'table table-bordered, th table-bordered, td table-bordered': 'Bootstrap Bordered'
        }
      }
    }
  };

  useEffect(() => {
    const editor = Jodit.make("#editor", config);
    editor.workplace.style.cssText = 'width: auto; height: auto; min-height: 50vh; color: black';
    editor.value = newsItem?.body ? newsItem.body.slice() : "";
    setEd(editor);
    // returned function will be called on component unmount 
    return () => {
      document.querySelectorAll("[class^=jodit]").forEach(item => item.remove());
    }
  }, []);

  const handleHeaderInputValueChange = function (e) {
    setHeader(e.target.value);
    validateHeader(e.target.value);
  }

  const validateHeader = function (header) {
    if (!header) {
      setIsHeaderCorrect(false);
      return false;
    } else {
      setIsHeaderCorrect(true);
      return true;
    }
  }

  const saveNews = function () {
    console.log(ed.value);
    if (validateHeader(header) && (path ? validatePath(path) : true)) {
      setIsLoading(true);
      if (newsItem && newsItem.id) {
        newsFeedService.updateNews({
          id: newsItem.id,
          header: header,
          path: usePathEditor ? path : "",
          author: {
            id: localStorage.getItem('id'),
            nickname: localStorage.getItem('nickname')
          },
          section: {
            path: sectionPath,
            countryName: country
          },
          languageName: language,
          countryName: country,
          body: ed.value
        }).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            newsItem.header = header;
            newsItem.path = path;
            newsItem.body = ed.value;
            newsItem.updatedDate = new Date().toISOString();
            setIsLoading(false);
            setOpen(false);
            updateEventHandler(newsItem);
          }

        }).catch(e => {
          console.log(e);
          setIsLoading(false);
        });
      } else {
        const newsItemRequest = {
          id: null,
          header: header,
          path: usePathEditor ? path : "",
          author: {
            id: localStorage.getItem('id'),
            nickname: localStorage.getItem('nickname')
          },
          section: {
            path: sectionPath,
            groups: group ? [group] : undefined,
            type: sectionType,
            name: sectionName,
            countryName: country
          },
          languageName: language,
          countryName: country,
          body: ed.value
        };
        newsFeedService.addNews(newsItemRequest, createSectionIfNotExists).then(response => {
          if (response.error && response.status === 401) {
            exceptionHandlerService.showErrorToast('User is not authorized to perform this operation');
            setIsLoading(false);
          } else if (response.error) {
            exceptionHandlerService.showErrorToast('Unexpected error occured');
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOpen(false);
            console.log("Done");
            console.log(response);
            newsItemRequest.id = response.id;
            newsItemRequest.path = response.path;
            newsItemRequest.updatedDate = new Date().toISOString();
            updateEventHandler(newsItemRequest);
          }

        }).catch(e => {
          console.log(e);
          setIsLoading(false);
        });
      }
    }
  }

  const handleEnterPressedEvent = function (e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      saveNews();
    } else if (e.key === "Esc") {
      cancel();
    }
  }

  const cancel = function () {
    setOpen(false);
  }

  const textFieldValueCorrect = function (val) {
    var pattern = /^[A-Za-z0-9-]+$/;
    if (val && val.length > 0 && val.length < 50 && val.match(pattern)) {
      return true;
    }

    return false;
  }

  const handlePathInputValueChange = function (e) {
    const p = e.target.value;
    const correct = textFieldValueCorrect(p);
    setPath(p);
    setIsPathCorrect(correct);
  }

  const validatePath = function (path) {
    const correct = textFieldValueCorrect(path);
    setIsPathCorrect(correct);
    return correct;
  }

  return (
    <div>
      <Grid key="settings" container>
        <Grid key="header" item xs={4}>
          <TextField required id="news-header" className="news-header" label="Header" value={header} onChange={handleHeaderInputValueChange} error={!isHeaderCorrect} />
        </Grid>
        <Grid item xs={4}>
          {usePathEditor &&
            <div>
              <TextField required id="news-path" className="news-path" label="Path" value={path} onChange={handlePathInputValueChange} error={!isPathCorrect} />
            </div>}
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="filled-select-language"
            select
            label="Language"
            value={language}
            onChange={handleLanguageChange}
            helperText="Please select your language"
            variant="filled"
            className={`${languageInputValid ? '' : 'error'}`}
          >
            <MenuItem id="language-ru" value="Русский">Русский</MenuItem>
            <MenuItem id="language-pl" value="Polski">Polski</MenuItem>
            <MenuItem id="language-en" value="English">English</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="filled-select-country"
            select
            label="Country"
            value={country}
            onChange={handleCountryChange}
            helperText="Please select your country"
            variant="filled"
            disabled={true}
            className={`${countryInputValid ? '' : 'error'}`}
          >
            <MenuItem id="language-ru" value="Россия">Россия</MenuItem>
            <MenuItem id="language-by" value="Беларусь">Беларусь</MenuItem>
            <MenuItem id="language-uk" value="United Kingdom">United Kingdom</MenuItem>
            <MenuItem id="language-pl" value="Polska">Polska</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <div>
        <textarea id="editor" name="editor" />
      </div>
      <div>
        <Button className="submit-button" variant="contained" color="primary" disabled={isLoading || !isHeaderCorrect || !isPathCorrect} onClickCapture={saveNews} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Apply</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
        <Button className="cancel-button" variant="contained" color="primary" disabled={isLoading} onClickCapture={cancel} onKeyPress={handleEnterPressedEvent}>{!isLoading && <span>Cancel</span>}{isLoading && <CircularProgress color="primary" size={17} thickness={10} />}</Button>
      </div>
    </div>
  );
}

export default NewsEditor;
