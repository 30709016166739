import { Box, Breadcrumbs, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, Link, Paper, Radio, RadioGroup, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, ArrowUpward, FlightTakeoff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useShoppingCart } from "util/ShoppingCartContextProvider";
import './shoppingOrder.css';



const OrderManagementPage = ({ orderService, exceptionHandlerService }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [resumeProcessingInitiated, setResumeProcessingInitiated] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [orderDetailsWindowOpen, setOrderDetailsWindowOpen] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const [selectedOrderHistory, setSelectedOrderHistory] = useState([]);
    const [value, setValue] = useState(0);



    useEffect(() => {
        setIsLoading(true);
        const fetchOrders = async () => {
            try {
                const orderList = await orderService.findAll()
                if (orderList !== null && orderList.length > 0) {
                    setOrderList(orderList);
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        };

        fetchOrders();

    }, [value]);

    const openOrderDetailsPage = function(order) {
        const fetchOrderHistory = async () => {
            try {
                setSelectedOrder(order);
                const selectedOrderHistory = await orderService.getOrderHistory(order.orderNumber);
                setSelectedOrderHistory(selectedOrderHistory);
                setOrderDetailsWindowOpen(true);
            } catch (e) {
                console.log(e);
            }
        };

        fetchOrderHistory();

    }

    const closeOrderDetailsPage = function() {
        setSelectedOrder(null);
        setOrderDetailsWindowOpen(false);
    }

    const handleUpdate = function() {

    }

    const handleClose = function() {
        setOrderDetailsWindowOpen(false);
    }

    const reprocessError = function() {
        setResumeProcessingInitiated(true);
        orderService.reprocess(selectedOrder.id)
        .then(response => {
            exceptionHandlerService.showInfoToast('Reprocessing was initiated successfully');
            setOrderDetailsWindowOpen(false);
            setValue(value => value + 1)
            setResumeProcessingInitiated(false);
          }).catch(e => {
            exceptionHandlerService.showInfoToast('Reprocessing was initiated successfully');
            setOrderDetailsWindowOpen(false);
            setValue(value => value + 1)
            setResumeProcessingInitiated(false);
          });
    }

    const resumeProcessing = function() {
        setResumeProcessingInitiated(true);
        orderService.resumeProcessing(selectedOrder.id)
        .then(response => {
            exceptionHandlerService.showInfoToast('Process was initiated successfully');
            setOrderDetailsWindowOpen(false);
            setValue(value => value + 1)
            setResumeProcessingInitiated(false);
          }).catch(e => {
            exceptionHandlerService.showInfoToast('Process was initiated successfully');
            setOrderDetailsWindowOpen(false);
            setValue(value => value + 1)
            setResumeProcessingInitiated(false);
          });
    }

    return (<>
        <Container maxWidth="lg" className="breadcrumbs">
            <Grid key="breadcrumbs" item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="primary">Order Management</Typography>
                </Breadcrumbs>

            </Grid>

        </Container>

        <Container className="container">
           {isLoading && <CircularProgress color="primary" size={37} thickness={10} />}
           {!isLoading &&
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Number</TableCell>
                            <TableCell>Operation</TableCell>
                            <TableCell>Operation Status</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Last Updated Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderList.map(value => (
                            <TableRow onClick={() => openOrderDetailsPage(value)}
                                key={value.id}>
                                <TableCell>
                                    {value.orderNumber}
                                </TableCell>
                                <TableCell>
                                    {value.operation}
                                </TableCell>
                                <TableCell>
                                    {value.operationStatus}
                                </TableCell>
                                <TableCell>
                                    {value.createdDate}
                                </TableCell>
                                <TableCell>
                                    {value.updatedDate}
                                </TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </Container>
        <Dialog maxWidth={true} onClose={closeOrderDetailsPage} aria-labelledby="customized-dialog-title" open={orderDetailsWindowOpen}>
                <DialogTitle id="customized-dialog-title" >
                    Order details
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12}>
                        <Typography><Box fontWeight='fontWeightMedium' display='inline'>Buyer information</Box></Typography>
                    </Grid>
                    
                    <Grid container spacing={1} xs={12}>
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Email Address" value={selectedOrder?.billingAddress?.emailAddress} error={false} />
                    </Grid>
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Name" value={selectedOrder?.billingAddress?.name} error={false} />
                    </Grid>
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Surname" value={selectedOrder?.billingAddress?.surname} error={false} />
                    </Grid>                   
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="ZipCode" value={selectedOrder?.billingAddress?.zipCode} error={false} />
                    </Grid>
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="City" value={selectedOrder?.billingAddress?.city} error={false} />
                    </Grid>
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Country" value={selectedOrder?.billingAddress?.country} error={false} />
                    </Grid> 
                    <Grid item xs={12}>
                    <Typography><Box fontWeight='fontWeightMedium' display='inline'>Order details</Box></Typography> 
                    </Grid>
                         
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Created Date" value={selectedOrder?.createdDate} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Updated Date" value={selectedOrder?.updatedDate} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Operation" value={selectedOrder?.operation} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Operation Status" value={selectedOrder?.operationStatus} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Order Number" value={selectedOrder?.orderNumber} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Shipping Method" value={selectedOrder?.shippingMethod?.name} error={false} />
                    </Grid> 
                    <Grid item xs={3}>
                            <TextField required id="customer-email" disabled={false} label="Payment Method" value={selectedOrder?.paymentMethod?.name} error={false} />
                    </Grid> 
                    {selectedOrder?.errorDetails &&<Grid item xs={12}>
                     <Typography ><Box fontWeight='fontWeightMedium' color={'red'} display='inline'>Error details</Box></Typography> 
                    </Grid>} 
                    {selectedOrder?.errorDetails &&<Grid item xs={12}>
                            <TextField multiline fullWidth rows={4} maxRows={4} required id="customer-email" disabled={false} label="Error Details" value={selectedOrder?.errorDetails} error={false} />
                    </Grid> }
                    <Grid item xs={12}>
                    <Typography><Box fontWeight='fontWeightMedium' display='inline'>Selected order history</Box></Typography> 
                    </Grid>            
                    <Grid item xs={12}>
                    <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Number</TableCell>
                            <TableCell>Operation</TableCell>
                            <TableCell>Operation Status</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Last Updated Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedOrderHistory.map(value => (
                            <TableRow
                                key={value.id}>
                                <TableCell>
                                    {value.orderNumber}
                                </TableCell>
                                <TableCell>
                                    {value.operation}
                                </TableCell>
                                <TableCell>
                                    {value.operationStatus}
                                </TableCell>
                                <TableCell>
                                    {value.createdDate}
                                </TableCell>
                                <TableCell>
                                    {value.updatedDate}
                                </TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
</TableContainer>
                    </Grid>        
                  </Grid>
                  
                </DialogContent>
                <DialogActions>
                    {selectedOrder?.operationStatus === 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={reprocessError} color="secondary">
                        Reprocess error {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}
                    {selectedOrder?.operation === 'BANK_TRANSFER_RECEIVING' && selectedOrder?.operationStatus !== 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={resumeProcessing} color="secondary">
                        Bank Transfer Already Received {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}
                    {selectedOrder?.operation === 'MONEY_FROM_PAYMENT_SYSTEM_RECEIVING' && selectedOrder?.operationStatus !== 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={resumeProcessing} color="secondary">
                        Money already received {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}
                    {selectedOrder?.operation === 'PARCEL_CREATION' && selectedOrder?.operationStatus !== 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={resumeProcessing} color="secondary">
                        Parcel prepared {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}      
                    {selectedOrder?.operation === 'PARCEL_SENDING' && selectedOrder?.operationStatus !== 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={resumeProcessing} color="secondary">
                        Parcel sent {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}   
                    {selectedOrder?.operation === 'PARCEL_DELIVERY' && selectedOrder?.operationStatus !== 'ERROR' && <Button disabled={resumeProcessingInitiated} autoFocus onClick={resumeProcessing} color="secondary">
                        Parcel delivered {resumeProcessingInitiated && <CircularProgress color="primary" size={17} thickness={10} />}
                    </Button>}        
                    <Button autoFocus onClick={handleClose} color="secondary" disabled={resumeProcessingInitiated}>
                        Close
                    </Button>
                    <Button autoFocus onClick={handleUpdate} color="secondary" disabled={true}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>

    </>);
}

export default OrderManagementPage;