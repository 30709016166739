import { debounce, makeStyles } from '@material-ui/core';
import React from 'react';

const styles = props => makeStyles((theme) => ({
    item: {
        position: 'relative',
        display: 'block', // In case it's not rendered with a div.
        height: '100%',
        overflow: 'hidden',
      },
      /* Styles applied to an `img` element child, if needed to ensure it covers the item. */
      imgFullHeight: {
        height: '100%',
        transform: 'translateX(-50%)',
        position: 'relative',
        left: '50%',
      },
      /* Styles applied to an `img` element child, if needed to ensure it covers the item. */
      imgFullWidth: {
        width: '100%',
        position: 'relative',
        transform: 'translateY(-50%)',
        top: '50%',
      },
  }));

  const fit = (imgEl, classes) => {
    if (!imgEl || !imgEl.complete) {
      return;
    }
  
    if (
      imgEl.width / imgEl.height >
      imgEl.parentElement.offsetWidth / imgEl.parentElement.offsetHeight
    ) {
      imgEl.classList.remove(...classes.imgFullWidth.split(' '));
      imgEl.classList.add(...classes.imgFullHeight.split(' '));
    } else {
      imgEl.classList.remove(...classes.imgFullHeight.split(' '));
      imgEl.classList.add(...classes.imgFullWidth.split(' '));
    }
  };
  
  function ensureImageCover(imgEl, classes) {
    if (!imgEl) {
      return;
    }
  
    if (imgEl.complete) {
      fit(imgEl, classes);
    } else {
      imgEl.addEventListener('load', () => {
        fit(imgEl, classes);
      });
    }
  }
  
const Tile = ({
    tile,
    routeToChildPage,
    customTileHeightEnabled,
    customTileHeightScale,
    tilesPerLine,
    isMobile }) => {

    let classes = styles({scale: tilesPerLine, customTileHeightEnabled: customTileHeightEnabled, customTileHeight: customTileHeightScale})();


    const imgRef = React.useRef(null);

    React.useEffect(() => {
        ensureImageCover(imgRef.current, classes);
      });
    
      React.useEffect(() => {
        const handleResize = debounce(() => {
          fit(imgRef.current, classes);
        });
    
        window.addEventListener('resize', handleResize);
        return () => {
          handleResize.clear();
          window.removeEventListener('resize', handleResize);
        };
      }, [classes]);

      


    const calculateTileHeight = function () {
        let height = null;
        if (customTileHeightEnabled) {
            height = 80 * customTileHeightScale;
        } else {
            height = 80 / tilesPerLine;
        }

        if (isMobile && height > 70) {
            height = height / 2;
        }
        return height + 'vh';
    }

    return (
        <div style={{ overflow: "hidden", height: calculateTileHeight() }} className={classes.item}>
            {tile.body && !tile.image && <img ref={imgRef} src={tile.body} alt={tile.header} />}
            {tile.image && <img ref={imgRef} src={"/fstor/" + tile.image.pathMedium} alt={tile.header} />}
        </div>
    );
}

export default Tile;