import ExceptionHandlerService from "./ExceptionHandlerService";

class HttpRequestExecutor {
    constructor(exceptionHandlerService) {
        this.exceptionHandlerService = exceptionHandlerService;
    }

    execute = function (url, requestOptions) {
        return fetch(url, requestOptions).then(response => {
                return response.json().catch(() => {
                    if(!response.ok) {
                        this.exceptionHandlerService.showErrorToast('Unacceptable response received, body cant be parsed: Status - ' + response.status);
                        throw Error('Unacceptable response received, body cant be parsed: Status - ' + response.status);
                    }
                    return response;
                  });            
        } ).then(response => {
            if (response.status === 401) {
                if (response.message === 'Full authentication is required to access this resource') {
                    this.exceptionHandlerService.handleMissingAuthResponse();
                }
            }
            if (response.status === 403) {
                this.exceptionHandlerService.showErrorToast('User do not have enough permissions to perform this operation');
                throw Error('User do not have enough permissions to perform this operation');
            }

            return response;
        });
    }


}

export default HttpRequestExecutor;