import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'English',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      English: {
        translation: {
            sign_in_sign_in: "Sign In",
            sign_in_not_a_member_yet: "Not a member yet?",
            sign_in_sign_up_here: "Sign up here",
            sign_in_forgot_password: "Forgot password?",
            breadcrumbs_home: "Home",
            breadcrumbs_cart: "Cart",
            breadcrumbs_order: "Order",
            common_delete: "Delete",
            common_edit: "Edit",
            common_confirm: "Confirm",
            common_cancel: "Cancel",
            common_item_was_added_to_cart: "Item was added to cart",
            common_no_data_to_display: "No Data to display",
            common_cookies_concent_start: "The website uses cookies to provide services and in accordance with the ",
            common_cookies_concent_link_text: "cookie policy",
            common_cookies_concent_end: ". You can specify the conditions for storing or accessing cookies in your browser.",
            common_cookies_concent_button_accept: "Accept",
            header_logout: "Log Out",
            header_sign_in: "Sign In",
            header_sign_up: "Sign Up",
            home_page_header: "DIVERSE YOUR LOOK",
            home_page_description: "Transparent handmade jewelry with real flowers, beaded jewelry and more",
            home_page_to_catalog: "To Catalog",
            home_page_discover_the_experience: "Discover the experience",
            home_page_gallery_header: "Gallery",
            home_page_gallery_header_no_data: "No Data to display",
            footer_customer_service:"Customer Service",
            footer_customer_service_help:"Help & Contact Us",
            footer_customer_service_terms:"Terms & Conditions",
            footer_customer_service_privacy_policy:"Privacy policy",
            footer_customer_service_cookies_policy:"Cookies policy",
            footer_company:"Company",
            footer_company_about:"About Us",
            footer_company_blog:"Blog",
            footer_company_tracking:"Order Tracking",
            footer_company_faq:"FAQs",
            footer_company_contact:"Contact",
            footer_company_login:"Login",
            footer_social_media:"Social Media",
            footer_learn_more: "Explore",
            footer_social_media_instagram:"Instagram",
            footer_social_media_pinterest:"Pinterest",
            footer_customer_language: "Interface Language",
            footer_customer_region: "State",
            footer_handmade_jewelry_store: 'Handmade jewelry store',
            tile_page_order_by: "Order",
            tile_page_add_to_cart: "Add To Cart",
            tile_page_out_of_stock: "Out Of Stock",
            tile_page_no_data_to_display: "No Data to display",
            tile_page_add_new_tile_header:"Add new tile",
            tile_page_sorting_newest_first: "Newest first",
            tile_page_sorting_oldest_first: "Oldest first",
            tile_page_sorting_name_asc: "Name ascending",
            tile_page_sorting_name_desc: "Name descending",
            tile_page_sorting_cost_asc: "Cheapest first",
            tile_page_sorting_cost_desc: "Expensive first",
            tile_page_editor_click_here:"Click here",
            tile_page_editor_to_load_picture:" to load picture",
            tile_page_editor_header:"Header",
            tile_page_editor_language:"Language",
            tile_page_editor_country:"Country",
            tile_page_editor_child_section:"Child Section",
            tile_page_editor_name:"Name",
            tile_page_editor_type:"Type",
            tile_page_editor_path:"Path",
            tile_page_editor_not_defined:"Not Defined",
            tile_page_editor_add:"Add",
            tile_page_editor_edit:"Edit",
            tile_page_editor_delete:"Delete",
            tile_page_editor_cancel:"Cancel",
            tile_page_editor_save:"Save changes",
            tile_page_edit_tile_header:"Edit tile",
            tile_page_editor_add_new_section:"Add new section",
            tile_page_editor_edit_section:"Edit section",
            item_details_offer_language: "Offer Language",
            item_details_description: "Description",
            item_details_in_stock: "In Stock",
            news_page_news_removal:"Post removal",
            news_page_news_removal_msg:"Are you sure that you want to delete post?",
            admin_add_new_section: "Add new section",
            admin_edit_section: "Edit section",
            admin_view_statistics: "View Statistics",
            admin_order_management: "Order Management",
            section_editor_section_type: "Section Type",
            section_editor_section_name: "Section Name",
            section_editor_section_path: "Section Path ( latin letters a-z only )",
            section_editor_country: "Country",
            section_editor_country_select_msg: "Please select your country",
            section_editor_section_type_tiles: "Gallery / Tiles",
            section_editor_section_type_posts: "News / Posts Page",
            section_editor_section_type_item_details: "Product Details",
            section_editor_section_type_post: "Single Page",
            section_editor_parent_section: "Parent Section",
            section_editor_parent_section_tile: "Parent Section Tile",
            section_editor_loading_data: "Loading data...",
            section_editor_parent_section_should_be_selected: "Parent section tile should be selected",
            section_editor_section_icon: "Section Icon",
            section_editor_section_location: "Location of the section",
            section_editor_section_location_menu: "Menu",
            section_editor_section_location_footer_left: "Footer left",
            section_editor_section_location_footer_middle: "Footer middle",
            section_editor_section_location_footer_right: "Footer right",
            section_editor_section_location_err_msg: "At Least one place should be selected",
            section_editor_path_already_used: "Specified path is already used by another section",
            section_editor_tiles_per_line_label: "Number Of Tiles Per Line",
            section_editor_tiles_per_line_limitation_msg: "Number of tiles per line should be between 1 and 4",
            section_editor_shade_if_no_descendants: "Shade if there are no descendants",
            section_editor_custom_tile_height_enabled: "Custom Tile Height Enabled",
            section_editor_custom_tile_height_scale: "Custom Tile Height Scale ( % of screen height)",
            section_editor_post_static_height_enabled: "Post Static Height Enabled",
            section_editor_post_height_scale: "Post Height Scale ( % of screen height)",
            section_editor_cancel_button: "Cancel",
            section_editor_save_changes: "Save changes",
            section_editor_child_section_removal: "Child Section Removal",
            section_editor_tile_removal: "Tile Removal",
            section_editor_child_section_removal_msg: "Are you sure to remove child section? All child sections will be removed ( if they exists ) too and restore operation is not possible.",
            section_editor_tile_removal_msg: "Are you sure to remove tile? All child sections will be removed ( if they exists ) too. Also, the product will be removed from the order if any have already been made. Restore operation is not possible.",
            shopping_cart_go_to_order: "Go To Order",
            shopping_cart_total: "Total",
            shopping_cart_quantity: "Quantity",
            shopping_cart_price: "Price",
            order_billing_address: "Billing address",
            order_billing_details: "Billing details",
            order_payment_details: "Payment details",
            order_payment_details_item: "Item",
            order_payment_details_count: "Count",
            order_payment_details_cost: "Cost",
            order_billing_address_name: "Name",
            order_payment_details_total: "Total",
            order_payment_details_delivery_cost: "Delivery Cost",
            order_billing_address_surname: "Surname",
            order_billing_address_address: "Address",
            order_billing_address_zipcode: "Zip Code",
            order_billing_address_city: "City",
            order_billing_address_country: "Country",
            order_billing_address_email: "Email",
            order_billing_address_phone: "Phone",
            order_billing_address_zipcode_error: "ZipCode value should be numeric, and have 0-10 digits",
            order_billing_address_email_error: "Email value is incorrect",
            order_billing_address_phone_error: "Phone number should contain only numbers and optionally start with '+' and be less than 14 symbols",
            order_shipping_method: "Shipping Method",
            order_payment_method: "Payment Method",
            order_payment_method_przelewy_24: "Przelewy 24",
            order_payment_method_blik: "BLIK",
            order_payment_payment_upon_receipt: "Payment Upon Receipt",
            order_payment_bank_transfer: "Bank Transfer",
            order_shipping_method_pp: "Poczta Polska",
            order_shipping_method_pickup_at_point: "Pickup at the point (Żabka, Orlen, Ruch, Poczta Polska)",
            order_shipping_method_personal_reception: "Personal Reception",
            order_shipping_method_pocztex: "Pocztex 48",
            order_shipping_method_in_post: "Paczkomat InPost",
            order_shipping_method_orlen_paczka: "Orlen Paczka",
            order_back: "Back",
            order_next: "Next",
            order_submit: "Submit Order",
            order_shipping_details: "Client details",
            order_delivery_payment_method: "Method of delivery and payment",
            order_summary: "Summary",
            order_user_notice: "Soon after order submission, you will receive a message containing your order's identification number. This number will allow you to track the status of your order. Please remember to check your spam folder as well, as the email might be located there. Along with your ordered item, you will also receive an invoice.",
            order_accept_site_rules: "Accept ",
            order_accept_privacy_policy: "Accept ",
            order_accept_site_rules_link: "terms and conditions",
            order_accept_privacy_policy_link: "privacy policy",
        }
      },
      Русский: {
        translation: {
            sign_in_sign_in: "Вход",
            sign_in_not_a_member_yet: "Нет учетной записи?",
            sign_in_sign_up_here: "Зарегистрироваться можно тут",
            sign_in_forgot_password: "Забыли пароль?",
            breadcrumbs_home: "Домашняя страница",
            breadcrumbs_cart: "Корзина",
            breadcrumbs_order: "Заказ",
            common_delete: "Удалить",
            common_edit: "Редактировать",
            common_confirm: "Подтвердить",
            common_cancel: "Отменить",
            common_item_was_added_to_cart: "Товар был добавлен в корзину",
            common_no_data_to_display: "Нет данных для отображения",
            common_cookies_concent_start: "Сайт использует файлы cookie для предоставления услуг и в соответствии с Политикой использования ",
            common_cookies_concent_link_text: "файлов cookie",
            common_cookies_concent_end: ". Вы можете указать условия хранения или доступа к файлам cookie в вашем браузере.",
            common_cookies_concent_button_accept: "Принять",
            header_sign_in: "Войти в систему",
            header_sign_up: "Зарегистрироваться",
            header_logout: "Выйти из учетной записи",
            home_page_header: "разнообразьте свой образ",
            home_page_description: "Прозрачные украшения ручной работы с живыми цветами, украшения из бисера и многое другое",
            home_page_to_catalog: "В Каталог",
            home_page_discover_the_experience: "Откройте для себя этот опыт",
            home_page_gallery_header: "Галерея",
            home_page_gallery_header_no_data: "Нет данных для отображения",
            footer_customer_service:"Обслуживание клиентов",
            footer_customer_service_help:"Помощь и контакты",
            footer_customer_service_terms:"Правила и условия",
            footer_customer_service_privacy_policy:"Политика приватности",
            footer_customer_service_cookies_policy:"Политика Cookies",
            footer_company:"Компания",
            footer_company_about:"О нас",
            footer_company_blog:"Блог",
            footer_company_tracking:"Отслеживание заказов",
            footer_company_faq:"Часто Задаваемые Вопросы",
            footer_company_contact:"Как связаться с нами",
            footer_company_login:"Авторизоваться",
            footer_social_media:"Социальные Медиа",
            footer_social_media_instagram:"Инстаграм ( Instagram )",
            footer_social_media_pinterest:"Пинтерест ( Pinterest )",
            footer_customer_language: "Язык Интерфейса",
            footer_customer_region: "Страна",
            footer_handmade_jewelry_store: 'Магазин украшений ручной работы',
            footer_learn_more: "Узнать больше",
            tile_page_order_by: "Сортировать",
            tile_page_add_to_cart: "В корзину",
            tile_page_out_of_stock: "Нет В Наличии",
            tile_page_no_data_to_display: "Нет данных для отображения",
            tile_page_add_new_tile_header:"Добавление нового тайла",
            tile_page_sorting_newest_first: "Сначала новые",
            tile_page_sorting_oldest_first: "Сначала старые",
            tile_page_sorting_name_asc: "Имя по возрастанию",
            tile_page_sorting_name_desc: "Имя по убыванию",
            tile_page_sorting_cost_asc: "Сначала дешевые",
            tile_page_sorting_cost_desc: "Сначала дорогие",
            tile_page_editor_click_here:"Нажать тут",
            tile_page_editor_to_load_picture:" чтобы загрузить картинку",
            tile_page_editor_header:"Заголовок",
            tile_page_editor_language:"Язык",
            tile_page_editor_country:"Страна",
            tile_page_editor_child_section:"Дочерняя секция",
            tile_page_editor_name:"Имя",
            tile_page_editor_type:"Тип",
            tile_page_editor_path:"Путь",
            tile_page_editor_not_defined:"Не Задано",
            tile_page_editor_add:"Добавить",
            tile_page_editor_edit:"Редактировать",
            tile_page_editor_delete:"Удалить",
            tile_page_editor_cancel:"Отменить",
            tile_page_editor_save:"Сохранить",
            tile_page_edit_tile_header:"Редактирование тайла",
            tile_page_editor_add_new_section:"Добавление дочерней секции",
            tile_page_editor_edit_section:"Редактирование дочерней секции",
            item_details_offer_language: "Язык в объявлении",
            item_details_description: "Описание",
            item_details_in_stock: "В наличии",
            news_page_news_removal:"Удаление поста",
            news_page_news_removal_msg:"Вы уверены что хотите удалить пост?",
            admin_add_new_section: "Добавить Секцию",
            admin_edit_section: "Редактировать Секцию",
            admin_view_statistics: "Статистика",
            admin_order_management: "Управление Заказами",
            section_editor_section_type: "Тип Секции",
            section_editor_section_name: "Имя Секции",
            section_editor_section_path: "Путь к секции ( Только латинские буквы a-z )",
            section_editor_country: "Страна",
            section_editor_country_select_msg: "Пожалуйста выберите страну в которой будет отображаться секция",
            section_editor_section_type_tiles: "Галерея / Тайлы / Плитка",
            section_editor_section_type_posts: "Лента постов / новостей",
            section_editor_section_type_item_details: "Развернутые детали товара",
            section_editor_section_type_post: "Одиночная страница",
            section_editor_parent_section: "Родительская секция",
            section_editor_parent_section_tile: "Тайл (Плитка) из родительской секции",
            section_editor_loading_data: "Загрузка данных...",
            section_editor_parent_section_should_be_selected: "Тайл (Плитка) из родительской секции должен быть выбран",
            section_editor_section_icon: "Иконка меню секции ( только для корневых секций )",
            section_editor_section_location: "Расположение секции",
            section_editor_section_location_menu: "Меню",
            section_editor_section_location_footer_left: "Внизу страницы слева",
            section_editor_section_location_footer_middle: "Внизу страницы посередине",
            section_editor_section_location_footer_right: "Внизу страницы справа",
            section_editor_section_location_err_msg: "По крайней мере одно место должно быть выбрано",
            section_editor_path_already_used: "Введенный путь уже используется другой секцией",
            section_editor_tiles_per_line_label: "Количество Тайлов в линии",
            section_editor_tiles_per_line_limitation_msg: "Количество тайлов в линии должно быть между 1 и 5",
            section_editor_shade_if_no_descendants: "Затенить если нет дочерних секций",
            section_editor_custom_tile_height_enabled: "Пользовательская высота тайла вкл/выкл",
            section_editor_custom_tile_height_scale: "Высота тайла ( в % от высоты экрана )",
            section_editor_post_static_height_enabled: "Статическая высота поста вкл/выкл",
            section_editor_post_height_scale: "Высота поста ( в % от высоты экрана )",
            section_editor_cancel_button: "Отменить",
            section_editor_save_changes: "Сохранить",
            section_editor_child_section_removal: "Удаление дочерней секции",
            section_editor_tile_removal: "Удаление тайла",
            section_editor_child_section_removal_msg: "Вы уверены что хотите удалить дочернюю секцию? ПРИ СОХРАНЕНИИ ТАЙЛА все ее дочерние секции также будут удалены ( если они существуют ) и восстановление будет невозможно!",
            section_editor_tile_removal_msg: "Вы уверены что хотите удалить тайл? Все его дочерние секции также будут удалены ( если они существуют ). Также товар будет удален из заказа если таковые уже были сделаны. Восстановление будет невозможно!",
            shopping_cart_go_to_order: "К заказу",
            shopping_cart_total: "Всего",
            shopping_cart_quantity: "Количество",
            shopping_cart_price: "Цена",
            order_billing_address: "Адрес",
            order_billing_details: "Детали заказа",
            order_payment_details: "Детали платежа",
            order_payment_details_item: "Товар",
            order_payment_details_count: "Количество",
            order_payment_details_cost: "Цена",
            order_billing_address_name: "Имя",
            order_payment_details_total: "Всего",
            order_payment_details_delivery_cost: "Цена Доставки",
            order_billing_address_surname: "Фамилия",
            order_billing_address_address: "Адрес",
            order_billing_address_zipcode: "Почтовый Индекс",
            order_billing_address_city: "Город",
            order_billing_address_country: "Страна",
            order_billing_address_email: "Адрес Электронной почты",
            order_billing_address_phone: "Телефон",
            order_billing_address_zipcode_error: "Почтовый индекс должен содержать только цифры",
            order_billing_address_email_error: "Адрес Электронной почты введен некорректно",
            order_billing_address_phone_error: "Телефон должен содержать только цифры и опционально начинаться со знака '+' и быть меньше 14 символов",
            order_shipping_method: "Метод доставки",
            order_payment_method: "Метод оплаты",
            order_payment_method_przelewy_24: "Przelewy 24",
            order_payment_method_blik: "BLIK",
            order_payment_payment_upon_receipt: "Оплата при доставки",
            order_payment_bank_transfer: "Банковский перевод",
            order_shipping_method_pp: "Почтовая отправка",
            order_shipping_method_pickup_at_point: "Забрать на точке (Żabka, Orlen, Ruch, Poczta Polska)",
            order_shipping_method_personal_reception: "Самовывоз",
            order_shipping_method_pocztex: "Pocztex 48",
            order_shipping_method_in_post: "Постомат InPost",
            order_shipping_method_orlen_paczka: "Orlen Paczka",
            order_back: "Назад",
            order_next: "Вперед",
            order_submit: "Отправить заказ",
            order_shipping_details: "Данные клиента",
            order_delivery_payment_method: "Способ оплаты и доставки",
            order_summary: "Итого",
            order_user_notice: "Вскоре после размещения заказа вы получите сообщение с номером идентификации вашего заказа. Этот номер позволит вам отслеживать статус вашего заказа. Пожалуйста, не забудьте также проверить папку со спамом, так как письмо может оказаться там. Вместе с заказанным товаром вы также получите счет-фактуру.",
            order_accept_site_rules: "Принимаю ",
            order_accept_privacy_policy: "Принимаю ",
            order_accept_site_rules_link: "правила сайта",
            order_accept_privacy_policy_link: "политику приватности",
        }
      },
      Polski: {
        translation: {
            sign_in_sign_in: "Logowanie",
            sign_in_not_a_member_yet: "Nie jesteś jeszcze członkiem?",
            sign_in_sign_up_here: "Zarejestruj się tutaj",
            sign_in_forgot_password: "zapomniałeś hasła?",
            breadcrumbs_home: "Strona główna",
            breadcrumbs_cart: "Koszyk",
            breadcrumbs_order: "Zamówienie",
            common_delete: "Usuń",
            common_edit: "Edytuj",
            common_confirm: "Potwierdzić",
            common_cancel: "Anulować",
            common_item_was_added_to_cart: "Produkt został dodany do koszyka",
            common_no_data_to_display: "Brak danych do wyświetlenia",
            common_cookies_concent_start: "Strona korzysta z plików cookies w celu realizacji usług i zgodnie z ",
            common_cookies_concent_link_text: "Polityką Plików Cookies",
            common_cookies_concent_end: ". Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce. ",
            common_cookies_concent_button_accept: "Akceptuję",
            header_sign_in: "Zaloguj Się",
            header_sign_up: "Zapisz Się",
            header_logout: "Wyloguj się",
            home_page_header: "Zmień swój styl",
            home_page_description:"Oferujemy piękną biżuterię z żywicy i koralików oraz wiele innych unikatowych ręcznie wykonanych wyrobów",
            home_page_to_catalog: "Do Katalogu",
            home_page_discover_the_experience: "Odkryj doświadczenie",
            home_page_gallery_header: "Galeria",
            home_page_gallery_header_no_data: "Brak danych do wyświetlenia",
            footer_customer_service:"Obsługa Klienta",
            footer_customer_service_help:"Pomoc I Kontakt",
            footer_customer_service_terms:"Regulamin",
            footer_customer_service_privacy_policy:"Polityka prywatnośći",
            footer_customer_service_cookies_policy:"Polityka Cookies",
            footer_company:"Przedsiębiorstwo",
            footer_company_about:"O Nas",
            footer_company_blog:"Blog",
            footer_company_tracking:"Śledzenie Zamówień",
            footer_company_faq:"FAQ",
            footer_company_contact:"Kontakt",
            footer_company_login:"Login",
            footer_social_media:"Social Media",
            footer_social_media_instagram:"Instagram",
            footer_social_media_pinterest:"Pinterest",
            footer_learn_more: "Dowiedz się więcej",
            footer_customer_language: "Jezyk Interfejsu",
            footer_customer_region: "Kraj",
            footer_handmade_jewelry_store: 'Sklep z biżuterią ręcznie robioną',
            tile_page_order_by: "Sortuj",
            tile_page_add_to_cart: "Do Koszyka",
            tile_page_out_of_stock: "Brak W Magazynie",
            tile_page_no_data_to_display: "Brak danych do wyświetlenia",
            tile_page_add_new_tile_header:"Dodawanie nowego kafelka",
            tile_page_sorting_newest_first: "Czas: Najnowsze",
            tile_page_sorting_oldest_first: "Czas: Najstarsze",
            tile_page_sorting_name_asc: "Nazwa rosnąco",
            tile_page_sorting_name_desc: "Nazwa malejąco",
            tile_page_sorting_cost_asc: "Cena: Najtańsze",
            tile_page_sorting_cost_desc: "Cena: Najdroższe",
            tile_page_editor_click_here:"Kliknij tutaj",
            tile_page_editor_to_load_picture:" aby przesłać zdjęcie",
            tile_page_editor_header:"Nagłówek",
            tile_page_editor_language:"Język",
            tile_page_editor_country:"Kraj",
            tile_page_editor_child_section:"Sekcja podrzędna",
            tile_page_editor_name:"Nazwa",
            tile_page_editor_type:"Rodzaj",
            tile_page_editor_path:"Droga",
            tile_page_editor_not_defined:"Nie Zadano",
            tile_page_editor_add:"Dodać",
            tile_page_editor_edit:"Redagować",
            tile_page_editor_delete:"Usunąć",
            tile_page_editor_cancel:"Anulować",
            tile_page_editor_save:"Zachować",
            tile_page_edit_tile_header:"Edycja kafelków",
            tile_page_editor_add_new_section:"Dodawanie sekcji podrzędnej",
            tile_page_editor_edit_section:"Edycja sekcji podrzędnej",
            item_details_offer_language: "Jezyk oferty",
            item_details_description: "Opis",
            item_details_in_stock: "W magazynie",
            news_page_news_removal:"Usuwanie postów",
            news_page_news_removal_msg:"Czy na pewno chcesz usunąć post?",
            admin_add_new_section: "Dodaj Sekcję",
            admin_edit_section: "Edytuj Sekcję",
            admin_view_statistics: "Statystyk",
            admin_order_management: "Zarządzanie Zamówieniami",
            section_editor_section_type: "Typ Sekcji",
            section_editor_section_name: "Nazwa Sekcji",
            section_editor_section_path: "Ścieżka do sekcji ( tylko łacińskie litery a-z )",
            section_editor_country: "Kraj",
            section_editor_country_select_msg: "Proszę wybrać kraj, w którym będzie wyświetlana sekcja",
            section_editor_section_type_tiles: "Galeria",
            section_editor_section_type_posts: "Posty",
            section_editor_section_type_item_details: "Szczegóły Produktu",
            section_editor_section_type_post: "Pojedyncza strona",
            section_editor_parent_section: "Sekcja rodzicielska",
            section_editor_parent_section_tile: "Kafelek z sekcji nadrzędnej",
            section_editor_loading_data: "Ładowanie danych...",
            section_editor_parent_section_should_be_selected: "Należy wybrać kafelek z sekcji nadrzędnej",
            section_editor_section_icon: "Ikona menu sekcji (tylko dla sekcji głównych )",
            section_editor_section_location: "Lokalizacja sekcji",
            section_editor_section_location_menu: "Menu",
            section_editor_section_location_footer_left: "Footer left",
            section_editor_section_location_footer_middle: "Footer middle",
            section_editor_section_location_footer_right: "Footer right",
            section_editor_section_location_err_msg: "Należy wybrać co najmniej jedno miejsce",
            section_editor_path_already_used: "Wprowadzona ścieżka jest już używana przez inną sekcję",
            section_editor_tiles_per_line_label: "Liczba płytek w linii",
            section_editor_tiles_per_line_limitation_msg: "Liczba płytek w linii powinna wynosić od 1 do 5",
            section_editor_shade_if_no_descendants: "Przyciemnij jeśli nie ma sekcji podrzędnych",
            section_editor_custom_tile_height_enabled: "Niestandardowa wysokość płytki on / off",
            section_editor_custom_tile_height_scale: "Wysokość płytki (w % wysokości ekranu )",
            section_editor_post_static_height_enabled: "Statyczna Wysokość słupka on / off",
            section_editor_post_height_scale: "Wysokość słupka (w % wysokości ekranu )",
            section_editor_cancel_button: "Anulować",
            section_editor_save_changes: "Zachować",
            section_editor_child_section_removal: "Usuwanie sekcji podrzędnej",
            section_editor_tile_removal: "Usuwanie kafelków",
            section_editor_child_section_removal_msg: "Czy na pewno chcesz usunąć sekcję podrzędną? Wszystkie jej sekcje podrzędne również zostaną usunięte (jeśli istnieją ) i przywrócenie nie będzie możliwe!",
            section_editor_tile_removal_msg: "Czy na pewno chcesz usunąć kafelek? Wszystkie jego sekcje podrzędne również zostaną usunięte (jeśli istnieją ). Również towary zostaną usunięte z zamówienia, jeśli zostały już wykonane. Przywrócenie nie będzie możliwe!",
            shopping_cart_go_to_order: "przejdź do zamówienia",
            shopping_cart_total: "Ogółem",
            shopping_cart_quantity: "Ilość",
            shopping_cart_price: "Koszt",
            order_billing_address: "Adres",
            order_billing_details: "Szczegóły zamówienia",
            order_payment_details: "Szczegóły płatności",
            order_payment_details_item: "Towar",
            order_payment_details_count: "Ilość",
            order_payment_details_cost: "Koszt",
            order_billing_address_name: "Imię",
            order_payment_details_total: "ogółem",
            order_payment_details_delivery_cost: "Koszt Wysyłki",
            order_billing_address_surname: "Nazwisko",
            order_billing_address_address: "Adres",
            order_billing_address_zipcode: "Kod Pocztowy",
            order_billing_address_city: "Miasto",
            order_billing_address_country: "Kraj",
            order_billing_address_email: "Adres e-mail",
            order_billing_address_phone: "Telefon",
            order_billing_address_zipcode_error: "Kod Pocztowy powinien zawierać tylko cyfry",
            order_billing_address_email_error: "Adres e-mail wpisany nieprawidłowo",
            order_billing_address_phone_error: "Telefon powinien zawierać tylko cyfry i opcjonalnie zaczynać się od znaku + i mieć mniej niż 14 znaków",
            order_shipping_method: "Metoda wysyłki",
            order_payment_method: "Metoda płatności",
            order_payment_method_przelewy_24: "Przelewy 24",
            order_payment_method_blik: "BLIK",
            order_payment_payment_upon_receipt: "Płatność przy odbiorze",
            order_payment_bank_transfer: "Przelew bankowy",
            order_shipping_method_pp: "Poczta Polska",
            order_shipping_method_pickup_at_point: "Odebrać w punkcie (Żabka, Orlen, Ruch, Poczta Polska)",
            order_shipping_method_personal_reception: "Odbiór osobisty",
            order_shipping_method_pocztex: "Pocztex 48",
            order_shipping_method_in_post: "Paczkomat InPost",
            order_shipping_method_orlen_paczka: "Orlen Paczka",
            order_back: "Powrót",
            order_next: "Do następnego kroku",
            order_submit: "Wyślij zamówienie",
            order_shipping_details: "Dane Klienta",
            order_delivery_payment_method: "Sposób płatności i dostawy",
            order_summary: "Podsumowanie",
            order_user_notice: "Wkrótce po złożeniu zamówienia otrzymasz wiadomość zawierającą numer identyfikacyjny Twojego zamówienia. Ten numer umożliwi Ci śledzenie statusu Twojego zamówienia. Prosimy pamiętać, aby również sprawdzić folder ze spamem, ponieważ wiadomość może się tam znajdować. Razem z zamówionym produktem otrzymasz także fakturę.",
            order_accept_site_rules: "Akceptuję ",
            order_accept_privacy_policy: "Akceptuję ",
            order_accept_site_rules_link: "regulamin",
            order_accept_privacy_policy_link: "politykę prywatności",
        }
      },
    }
  });

export default i18n;