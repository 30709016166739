import './News.css'
import React, { useEffect, useState } from "react"
import { Avatar, Badge, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Collapse, Divider, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import CommentsPanel from 'components/comments/Comments';
import { Comment, Delete, DoubleArrow, Edit, MoreVert } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        wordBreak: 'break-word'
    },
    fixedHeight: {
        height: 240,
    },
    root: {

    },
    addNewComment: {
        boxShadow: 'unset'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

}));

const NewsPanel = ({ newsItem, newsFeedService, handleDeleteEvent, commentsService, handleUpdateEvent, detailsLinkEnabled, customStyles, showDeleteButton, postHeightScale, postStaticHeightEnabled }) => {
    const [listComments, setListComments] = useState([]);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [commentsSize, setCommentsSize] = useState(0);
    const panelStaticHeight = postHeightScale ? (90 * postHeightScale) + 'vh' : '30vh';
    const history = useHistory();
    useEffect(() => {
        fetchComments(newsItem.id);
    }, []);


    const handleExpandClick = () => {
        fetchComments(newsItem.id);
        setExpanded(!expanded);
    };

    const userHasUpdatePermission = function () {
        return JSON.parse(localStorage.getItem('authorities'))?.includes("UPDATE_NEWS_POST");
    }

    const resolveAuthorString = function (author) {
        if (author.nickname) {
            return author.nickname;
        } else if (author.firstname || author.lastname) {
            return author.firstname + author.lastname;
        } else {
            return null;
        }
    }

    const fetchComments = function (postId) {
        setIsLoading(true);
        const fetchComments = async () => {
            try {
                const result = await commentsService.retrieveComments(postId);
                setListComments(result);
                if (result?.length) {
                    setCommentsSize(result.length);
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        };
        fetchComments();
    }

    const handleCommentsListChange = (commentsList) => {
        setCommentsSize(commentsList.length);
    }

    const goToNewsDetails = function () {
        if (detailsLinkEnabled && detailsLinkEnabled === true) {
            history.push({
                pathname: "/_/" + newsItem.path + "/post-details",
                state: newsItem
            });
        }
    }

    const convertDate = (date) => {
        return new Date(date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
    }

    return (
        <div id="news">
            <Card className={classes.root}>
                <CardHeader
                    onClick={goToNewsDetails}
                    title={newsItem.header}
                />
                {!customStyles?.newsContent?.minHeight && postStaticHeightEnabled && postStaticHeightEnabled === true &&
                    <CardContent style={{ wordBreak: 'break-word', height: panelStaticHeight, overflowY: 'hidden' }}>
                        <div style={{ position: 'relative', height: panelStaticHeight }}>
                            <div className="news-body" dangerouslySetInnerHTML={{ __html: newsItem.body }}></div>
                            <div style={{ height: panelStaticHeight, width: '100%', pointerEvents: 'none', backgroundImage: 'linear-gradient(to bottom, transparent 20%, white 90%)', position: 'absolute', top: '0px' }}></div>
                        </div>
                    </CardContent>}
                {!customStyles?.newsContent?.minHeight && (!postStaticHeightEnabled || postStaticHeightEnabled === false) &&
                    <CardContent style={{ wordBreak: 'break-word' }}>
                        <div dangerouslySetInnerHTML={{ __html: newsItem.body }}></div>
                    </CardContent>}
                {customStyles?.newsContent?.minHeight &&
                    <CardContent style={{ wordBreak: 'break-word', minHeight: customStyles?.newsContent?.minHeight }}>
                        <div dangerouslySetInnerHTML={{ __html: newsItem.body }}></div>
                    </CardContent>}
                <CardContent id="author" className='author'>
                    {resolveAuthorString(newsItem.author) && <div>{resolveAuthorString(newsItem.author)}, {convertDate(newsItem.updatedDate)}</div>}
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        {isLoading && <CircularProgress color="primary" size={17} thickness={10} />}
                        {!isLoading && <Badge badgeContent={commentsSize} color="secondary">
                            <Comment />
                        </Badge>}
                    </IconButton>
                    {userHasUpdatePermission() && showDeleteButton &&
                        <IconButton disabled={isLoading} id={newsItem.id} onClick={handleDeleteEvent}>
                            <Delete />
                        </IconButton>}
                    {userHasUpdatePermission() &&
                        <IconButton disabled={isLoading} id={newsItem.id} onClick={handleUpdateEvent}>
                            <Edit />
                        </IconButton>}
                    {detailsLinkEnabled && detailsLinkEnabled === true && <Link disabled={isLoading} id={"link-to-details" + newsItem.id} to={{
                        pathname: "/_/" + newsItem.path + "/post-details",
                        state: newsItem
                    }}>
                        <IconButton disabled={isLoading} id={"link-to-details-button" + newsItem.id}>
                            <DoubleArrow />
                        </IconButton>
                    </Link>}
                </CardActions>
                <Collapse in={expanded && !isLoading} timeout="auto" unmountOnExit>
                    <CommentsPanel commentableEntityId={newsItem.id} commentsService={commentsService} initialComments={listComments} handleCommentsListChange={handleCommentsListChange} />
                </Collapse>
            </Card>

        </div>
    );
}

export default NewsPanel;