import checkIcon from './assets/check.svg';
import errorIcon from './assets/error.svg';
import infoIcon from './assets/info.svg';
import warningIcon from './assets/warning.svg';
import React, { useImperativeHandle, useState } from 'react';
import Toast from './Toast';

const ToastWrapper = ({},ref) => {
  const [list, setList] = useState([]);
  const [position, setPosition] = useState('Select Position');
  let [checkValue, setCheckValue] = useState(false);
  const [autoDeleteTime, setAutoDeleteTime] = useState(0);
  let toastProperties = null;

  useImperativeHandle(ref, () => ({
    showToast (type, message) {
      showNotification(type, message);
    }
  }), [])
  
  const selectPosition = (e) => {
    setPosition(e.target.value);
    setList([]);
  }

  const showNotification = function(type, message) {
    const id = Math.floor((Math.random() * 101) + 1);

    switch(type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Success',
          description: message,
          backgroundColor: '#5cb85c',
          icon: checkIcon
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Danger',
          description: message,
          backgroundColor: '#d9534f',
          icon: errorIcon
        }
        break;
      case 'info':
        toastProperties = {
          id,
          title: 'Info',
          description: message,
          backgroundColor: '#5bc0de',
          icon: infoIcon
        }
        break;
      case 'warning':
        toastProperties = {
          id,
          title: 'Warning',
          description: message,
          backgroundColor: '#f0ad4e',
          icon: warningIcon
        }
        break;

        default:
          setList([]);
    }

    setList([...list, toastProperties]);
  }

  return (
    <div>
      <Toast 
        toastList={list}
        position={'bottom-right'}
        autoDelete={true}
        dismissTime={3000}
      />
    </div>
  );
}

export default React.forwardRef(ToastWrapper);
