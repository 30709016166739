import { Dialog, DialogContent, DialogTitle, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import AuthorityUtils from "util/AuthorityUtils";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import './Home.css';
import HomePageEditor from "./HomePageEditor";
import HomePageHeader from "components/modules/views/HomePageHeader";
import HomePageDetails from "components/modules/views/HomePageDetails";
import HomePageGallery from "components/modules/views/HomePageGallery";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import { useTranslation } from "react-i18next";
import SEO from "util/SEO";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dialog: {
    minWidth: '85%',
    minHeight: '85%'
  },
  addFab: {
    position: 'absolute',
    bottom: '5vh',
    right: '3vw'
  },
  root: {

  },

}));

const Home = ({
  homePageService,
  exceptionHandlerService,
  commentsService,
  fileService }) => {
  const homeSectionPath = 'home';
  const [itemDetails, setItemDetails] = useState({
    images: []
  });
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  useEffect(() => {
    setIsLoading(true);
    const fetchNews = async () => {
      try {
        const result = await homePageService.retrieveHomePage(currentRegion);
        if(result && result.id) {
          setItemDetails(result);
        } else {
          setItemDetails({
            images: []
          });
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };

    fetchNews();

  }, []);
  const classes = useStyles();

  const handleClose = function () {

  }

  const updateEventHandler = function (updatedItemDetails) {
    setItemDetails(updatedItemDetails);
  }

  const handleAddNewsButtonClick = function () {
    setOpen(true);
  }

  const images = itemDetails.images.map(image =>
    <SwiperSlide>
      <img src={"/fstor/" + image.pathMedium} alt="" />
    </SwiperSlide>
  );

  return (
    <div>
      <SEO title={t('home_page_header')} description={t('home_page_description')} name="Pearl Art Store" image="/fstor/homepage/medium-header.jpg"/>
      <HomePageHeader />
      <HomePageGallery classes={classes} isLoading={isLoading} itemDetails={itemDetails} images={images}/>
      <HomePageDetails title={itemDetails.header} description={itemDetails.description}/>
       <Dialog disableScrollLock classes={{ paper: classes.dialog }} disableBackdropClick disableEnforceFocus onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          Edit item details
        </DialogTitle>
        <DialogContent dividers>
         <HomePageEditor homePageService={homePageService} exceptionHandlerService={exceptionHandlerService} itemDetails={itemDetails} setOpen={setOpen} updateEventHandler={updateEventHandler} sectionPath={homeSectionPath} fileService={fileService}/>
        </DialogContent>
      </Dialog>
      {AuthorityUtils.userHasAuthority("UPDATE_NEWS_POST") && <Fab color="primary" aria-label="add" onClick={handleAddNewsButtonClick} className={classes.addFab}>
        <Edit />
      </Fab>}
    </div>
  )
};

export default Home;
