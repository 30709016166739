import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageAndRegionContext = createContext({});

export const useLanguageAndRegionContext = function () {
    return useContext(LanguageAndRegionContext)
}

const LanguageAndRegionContextProvider = ({ children }) => {


    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, []);

    const getCurrentLanguage = function () {
        //todo: check for users preffered region
        const currentLanguageFromCache = localStorage.getItem('currentLanguage');
        let currentLanguage = null;
        if (currentLanguageFromCache && currentLanguageFromCache !== "undefined") {
            currentLanguage = currentLanguageFromCache;
        } else {
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userPreferredLocale = getNavigatorLanguage();
            console.log(currentTimezone);
            console.log(userPreferredLocale);
            if (currentTimezone && currentTimezone === 'Europe/Warsaw') {
                currentLanguage = 'Polski';
            } else if (userPreferredLocale === 'pl-PL') {
                currentLanguage = 'Polski';
            } else if (userPreferredLocale === 'ru-RU') {
                currentLanguage = 'Русский';
            } else {
                currentLanguage = 'Polski'
            }
        }

        return currentLanguage;
    }

    const getCurrentRegion = function () {
        const currentRegionFromCache = localStorage.getItem('currentRegion');
        let currentRegion = null;
        if (currentRegionFromCache && currentRegionFromCache !== "undefined") {
            currentRegion = currentRegionFromCache;
        } else {
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userPreferredLocale = getNavigatorLanguage();
            console.log(currentTimezone);
            console.log(userPreferredLocale);
            if (currentTimezone && currentTimezone === 'Europe/Warsaw') {
                currentRegion = 'Polska';
            } else if (userPreferredLocale === 'pl-PL') {
                currentRegion = 'Polska';
            } else if (userPreferredLocale === 'ru-RU') {
                currentRegion = 'Polska';
            } else {
                currentRegion = 'Polska'
            }
        }

        return currentRegion;
    }


    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
          return navigator.languages[0];
        } else {
          return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
        }
      }
      
    const [currentLanguage, setCurrentLanguage] = React.useState(getCurrentLanguage());
    const [currentRegion, setCurrentRegion] = React.useState(getCurrentRegion());
    const { t, i18n } = useTranslation();
    
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        localStorage.setItem('currentLanguage', language);
    }

    const changeRegion = (region) => {
        setCurrentRegion(region);
        localStorage.setItem('currentRegion', region);
    }





    return (
        <LanguageAndRegionContext.Provider
            value={{
                changeLanguage,
                changeRegion,
                currentLanguage,
                currentRegion
            }}
        >
            {children}
        </LanguageAndRegionContext.Provider>
    )
}

export default LanguageAndRegionContextProvider;