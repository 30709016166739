class CommentsService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveComments(postId) {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/comments/${postId}`);
    }

    async addComment(comment) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(comment)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/comments', requestOptions);
    }

    async deleteComment(commentId) {
        /*TODO: replace with reverse proxy */
        const requestOptions = {
            method: 'DELETE',
            body: {}
        };
        return this.httpRequestExecutor.execute(`/api/comments/${commentId}`, requestOptions);
    }

}

export default CommentsService;