import { Breadcrumbs, Container, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { CategoryScale, Chart, registerables } from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { v4 as uuidv4 } from 'uuid';
import StatisticsPageItem from "./StatisticsPageItem";
import "./StatisticsPage.css";

Chart.register(CategoryScale);
Chart.register(...registerables);

export default function StatisticsPage({ dataAnalyticsService }) {


  const [report, setReport] = useState([]);

  useEffect(
    () => {
      let report;
      const fetchReport = async () => {
        report = await dataAnalyticsService.getReport();
        setReport(report);
      }
      fetchReport();
    },
    []
  );

  const mapToChartInput = (source) => {
    const target = {};
    target.labels = [source.name];
    target.datasets = source.payload.data.map(payloadDataItem => {
      let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      return {
        label: payloadDataItem.key,
        data: [[+payloadDataItem.value, 0]],
        backgroundColor: randomColor
      }
    });

    return target;
  }

  const mapToLineChartInput = (source) => {
    const target = {};
    target.labels = [];
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    target.datasets = [{
      label: source.name,
      borderColor: randomColor,
      data: []
    }]
    source.payload.data.forEach(payloadDataItem => {
      target.labels.push(payloadDataItem.key)
      target.datasets[0].data.push(payloadDataItem.value);
    });

    return target;
  }

  const mapToGridRows = (source) => {
    return source.payload.rows.map(row => {
      let result = {};
      for (let r in row) {
        result = { ...result, ...{ [row[r].headerIdentifier]: row[r].value } }
      }
      result = { ...result, ...{ id: uuidv4() } };
      return result;
    })
  }

  const mapToGridHeaders = (source) => {
    return source.payload.headers.map(header => {
      return {
        field: header.identifier,
        headerName: header.namePrettyPrinted,
        flex: 1,
        renderCell: (params) => (
          <Tooltip id={params.row[params.field]} title={params.row[params.field]}>
            <span>{params.row[params.field]}</span>
          </Tooltip>
        ),
      };
    });
  }

  const chartOptions = (name) => {
    return {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: name
        },
        legend: {
          display: true,
          position: 'top'
        }
      }
    };
  }

  return (<>
    <Container maxWidth="lg" className="breadcrumbs">
      <Grid key="breadcrumbs" item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="primary">Statistics</Typography>
        </Breadcrumbs>

      </Grid>
    </Container>
    {
      report && report.map(s => {
        if (s.type === "SINGLE_VALUE") {
          return <StatisticsPageItem title={s.name} itemHeight="25vh">
            <Typography variant="h1">{s.payload}</Typography>
          </StatisticsPageItem>
        }
        if (s.type === "TABLE") {

          return <StatisticsPageItem title={s.name} itemHeight="60vh">
            <div className="statisticsDataGrid">
              <DataGrid
                rows={mapToGridRows(s)}
                columns={mapToGridHeaders(s)}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
          </StatisticsPageItem>
        }
        if (s.type === "BAR_CHART") {
          return <StatisticsPageItem title={s.name} itemHeight="60vh">
            <div className="statisticsChart">
              <Bar data={mapToChartInput(s)}
                options={chartOptions(s.name)}
              />
            </div>
          </StatisticsPageItem>
        }
        if (s.type === "LINE_CHART") {
          return <StatisticsPageItem title={s.name} itemHeight="60vh">
            <div className="statisticsChart">
              <Line
                data={mapToLineChartInput(s)}
                options={chartOptions(s.name)}
              />
            </div>
          </StatisticsPageItem>
        }
        return <></>;
      })
    }
  </>);
}