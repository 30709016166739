import React, { useState } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import './LoginPage.css';
import AppForm from 'components/modules/views/AppForm';
import Typography from 'components/modules/components/Typography';
import TextField from 'components/modules/components/TextField';
import FormButton from 'components/modules/form/FormButton';
import { t } from "i18next";
import SEO from 'util/SEO';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

export default function LoginPage({loginService, sharedUserData }) {
  const classes = useStyles();


  const location = useLocation();
  const search = location.search.substring(1);
  const routingParams = new URLSearchParams(search);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const incomingErrorMessage = routingParams.get('errorMessage');
  const [errorMessage, setErrorMessage] = useState(incomingErrorMessage);

  const history = useHistory();
  let isUserNameCorrect = false;
  let isPasswordCorrect = false;
  const handleUserNameInputValueChange = function(e) {
      setUsername(e.target.value);
  }
  const handlePasswordInputValueChange = function(e) {
      setPassword(e.target.value);
  }
  const handleEnterPressedEvent = function() {

  }
  const login = function(e) {
      
      loginService.login(username, password).then(response => {
          if (response.status === 401) {
              if (response.message === 'Bad credentials') {
                  setErrorMessage('Wrong credentials provided, please try again');
                  return;
              }
          }
          localStorage.setItem('id', response.id);
          localStorage.setItem('firstname', response.firstname);
          localStorage.setItem('lastname', response.lastname);
          localStorage.setItem('email', response.email);
          localStorage.setItem('nickname', response.nickname);
          localStorage.setItem('authorities', JSON.stringify(response.authorities));
          sharedUserData({
              id: response.id,
              firstname: response.firstname,
              lastname: response.lastname
          });
          history.push('/welcomepage');
        }).catch(error => {
            setErrorMessage('Unexpected error occured');
            console.log(error);
            return;
        });
      
  }



  return (
    <AppForm>
      <SEO title={t('sign_in_sign_in')} description={t('sign_in_sign_in')} name="Pearl Art Store" image={null}/>
      <React.Fragment>
        <Typography variant="h3" gutterBottom marked="center" align="center">
          {t('sign_in_sign_in')}
        </Typography>
        <Typography variant="body2" align="center">
          { t('sign_in_not_a_member_yet')}
          <Link href="/" align="center" underline="always">
          { t('sign_in_sign_up_here')}
          </Link>
        </Typography>
      </React.Fragment>

      <TextField
        disabled={false}
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        size="large"
        error={isUserNameCorrect}
        value={username} 
        onChange={handleUserNameInputValueChange}
      />
      <TextField
        disabled={false}
        fullWidth
        label="Password"
        margin="normal"
        type="password"
        name="password"
        size="large"
        error={isPasswordCorrect}
        value={password}
        onChange={handlePasswordInputValueChange}
      />
      <FormButton
        className={classes.button}
        disabled={false}
        size="large"
        color="secondary"
        fullWidth
        onClickCapture={login}
        onKeyPress={handleEnterPressedEvent}
      >
        {t('sign_in_sign_in')}
      </FormButton>
      <Typography align="center">
        <Link underline="always" href="/">
        {t('sign_in_forgot_password')}
        </Link>
      </Typography>
      { errorMessage && <div><span className="error-message">{errorMessage}</span></div> }

    </AppForm>
  );
}
