import React from "react";
import { BrowserRouter } from "react-router-dom";
import Root from 'Root';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from "@material-ui/core";
import theme from './theme';
import ShoppingCartContextProvider from "util/ShoppingCartContextProvider";
import 'i18n/i18n'
import LanguageAndRegionContextProvider from "util/LanguageAndRegionContextProvider";
import { HelmetProvider } from "react-helmet-async";
import { v4 as uuidv4 } from 'uuid';

const App = () => {

  if (!sessionStorage.getItem('sessionId')) {
    const sessionId = uuidv4();
    sessionStorage.setItem('sessionId', sessionId);
  }

  return (
    <div>
      <HelmetProvider>
        <LanguageAndRegionContextProvider>
          <ShoppingCartContextProvider>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <Root />
              </BrowserRouter>
            </MuiThemeProvider>
          </ShoppingCartContextProvider>
        </LanguageAndRegionContextProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
