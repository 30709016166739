class HomePageService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async retrieveHomePage(country) {
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute(`/api/home?country=${country}`);

        //return Promise.resolve(this.news);
    }

    async addHomePage(homePageDetails) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(homePageDetails)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/home', requestOptions);
    }

    async updateHomePage(homePageDetails) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(homePageDetails)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/home', requestOptions);
    }
}

export default HomePageService;