class OrderService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    async getOrderPageMetadata() {
        return this.httpRequestExecutor.execute(`/api/order/page-metadata`);
    }

    submitOrder(order) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(order)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/order', requestOptions);
    }

    resumeProcessing(orderId) {
        return this.httpRequestExecutor.execute(`/api/order/${orderId}/resume-processing`);
    }

    reprocess(orderId) {
        return this.httpRequestExecutor.execute(`/api/order/${orderId}/reprocess`);
    }

    async findAll() {
        return this.httpRequestExecutor.execute(`/api/order`);
    }

    async getOrderHistory(orderNumber) {
        return this.httpRequestExecutor.execute(`/api/order/history/${orderNumber}`);
    }

    

}

export default OrderService;