import { Breadcrumbs, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import AuthorityUtils from "util/AuthorityUtils";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import NewsPanel from "./news/News";
import NewsEditor from "./NewsEditor";
import "./NewsFeed.css";
import ConfirmationDialog from "util/ConfirmationDialog";
import { t } from "i18next";
import SEO from "util/SEO";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dialog: {
    minWidth: '85%',
    minHeight: '85%'
  },
  addFab: {
    position: 'absolute',
    bottom: '5vh',
    right: '3vw'
  }

}));

const NewsFeed = ({
  newsFeedService,
  exceptionHandlerService,
  commentsService,
  sectionPath,
  createSectionIfNotExists, group, sectionName }) => {

  const [listNews, setListNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedNewsListItem, setSelectedNewsListItem] = React.useState({});
  const [postStaticHeightEnabled, setPostStaticHeightEnabled] = React.useState(false);
  const [postHeightScale, setPostHeightScale] = React.useState(null);
  const [newsDeleteWarningOpen, setNewsDeleteWarningOpen] = React.useState(false);
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  const deletableNewsIdRef = React.useRef(null);

  const handleNewsDeleteWarningOpen = (e) => {
    deletableNewsIdRef.current = +e.currentTarget.id;
    setNewsDeleteWarningOpen(true);
  }

  const handleNewsDeleteWarningProceed = () => {
   setNewsDeleteWarningOpen(false);
   setIsLoading(true);
   const newsId = deletableNewsIdRef.current;
   newsFeedService.deleteNews(newsId).then(res => {
     setListNews(listNews.filter(n => n.id !== newsId));
     setIsLoading(false);
   }).catch(e => {
     console.log(e);
     setIsLoading(false);
   });
  }

  const handleNewsDeleteWarningCancel = () => {
    setNewsDeleteWarningOpen(false);
  }

  useEffect(() => {
    resetModalState();
    setIsLoading(true);
    const fetchNews = async () => {
      try {
        const result = await newsFeedService.retrieveNewsBySectionPathAndCountry(sectionPath, currentRegion);
        if (result[0]?.section?.sectionConfigs && result[0].section.sectionConfigs.filter(sc => sc.key === 'postHeightScale').length > 0) {
          setPostHeightScale(+result[0].section.sectionConfigs.filter(sc => sc.key === 'postHeightScale')[0].value);
        }
        if (result[0]?.section?.sectionConfigs && result[0].section.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled').length > 0) {
          setPostStaticHeightEnabled(result[0].section.sectionConfigs.filter(sc => sc.key === 'postStaticHeightEnabled')[0].value === 'true');
        }
        setListNews(result);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
      //exceptionHandlerService.showInfoToast('News has been retrieved');
    };

    fetchNews();

  }, [sectionPath]);
  const classes = useStyles();

  const resetModalState = function () {
    setListNews([]);
    setIsLoading(false);
    setOpen(false);
    setSelectedNewsListItem({});
    setPostStaticHeightEnabled(false);
    setPostHeightScale(null);
  }

  const handleUpdateEvent = function (listNewsItem) {
    setSelectedNewsListItem(listNewsItem);
    setOpen(true);
  }

  const res = listNews.map(listNewsItem =>
    <Grid key={listNewsItem.header} item xs={12}>
      <NewsPanel newsItem={listNewsItem} newsFeedService={newsFeedService} handleDeleteEvent={handleNewsDeleteWarningOpen} commentsService={commentsService} handleUpdateEvent={() => handleUpdateEvent(listNewsItem)} detailsLinkEnabled={true} customStyles={{}} showDeleteButton={true} postHeightScale={postHeightScale} postStaticHeightEnabled={postStaticHeightEnabled} />
    </Grid>
  );

  const breadcrumbsLinks = function () {
    const words = sectionPath.split('/');
    let currentLink = "/_";
    let currentWordIndex = 1;
    return words.map(pathItem => {
      currentLink = currentLink + "/" + pathItem;
      if (currentWordIndex < words.length) {
        currentWordIndex++;
        return (<Link underline="hover" color="inherit" href={currentLink}>
          {pathItem}
        </Link>);
      } else if (currentWordIndex === words.length) {
        currentWordIndex++;
        return (<Typography color="primary">{pathItem}</Typography>);
      }
    });
  }

  const handleClose = function () {

  }

  const handleSave = function () {

  }

  const updateEventHandler = function (updatedNewsItem) {
    const newsToUpdate = listNews.filter(n => n.id !== updatedNewsItem.id);
    newsToUpdate.push(updatedNewsItem);
    setListNews(newsToUpdate);
  }

  const handleAddNewsButtonClick = function () {
    setSelectedNewsListItem({});
    setOpen(true);
  }

  return (
    <div>
      <SEO title={sectionName} description="" name="Pearl Art Store" image={null}/>
      <Container maxWidth="lg" className="breadcrumbs">
        <Grid key="breadcrumbs" item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            {breadcrumbsLinks()}
          </Breadcrumbs>

        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid key="news-grid" container spacing={3}>
          {isLoading && <CircularProgress color="primary" size={37} thickness={10} />}
          {!isLoading && res && res.length > 0 && res}
          {!isLoading && (!res || (res && res.length === 0)) && <div>No Data to display</div>}
        </Grid>
      </Container>
      <Dialog disableEnforceFocus disableScrollLock classes={{ paper: classes.dialog }} disableBackdropClick onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          Edit news post
        </DialogTitle>
        <DialogContent dividers>
          <NewsEditor newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} newsItem={selectedNewsListItem} setOpen={setOpen} updateEventHandler={updateEventHandler} sectionPath={sectionPath} createSectionIfNotExists={createSectionIfNotExists} group={group} usePathEditor={true} sectionType="posts" sectionName={sectionName} />
        </DialogContent>
      </Dialog>
      {newsDeleteWarningOpen && <ConfirmationDialog 
        message={t('news_page_news_removal_msg')}
        onClose={handleNewsDeleteWarningCancel}
        onConfirm={handleNewsDeleteWarningProceed}
        open={newsDeleteWarningOpen}
        title={t('news_page_news_removal')}
        />}
      {AuthorityUtils.userHasAuthority("UPDATE_NEWS_POST") && <Fab color="primary" aria-label="add" onClick={handleAddNewsButtonClick} className={classes.addFab}>
        <Add />
      </Fab>}
    </div>
  )
};

export default NewsFeed;
