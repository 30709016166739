import { Breadcrumbs, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Fab, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import AuthorityUtils from "util/AuthorityUtils";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import NewsPanel from "./news/News";
import NewsEditor from "./NewsEditor";
import "./NewsFeedItem.css";
import SEO from "util/SEO";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  dialog: {
    minWidth: '85%',
    minHeight: '85%'
  },
  addFab: {
    position: 'absolute',
    bottom: '5vh',
    right: '3vw'
  }

}));

const NewsFeedItem = ({
  newsFeedService,
  exceptionHandlerService,
  commentsService,
  sectionPath,
  newsPath,
  news,
  createSectionIfNotExists,
  group, hasUniquePath, sectionName }) => {
  const transformNews = function (news, hasUniquePath) {
    if (news && !hasUniquePath) {
      news.path = "";
    }
    return news;
  }
  const [newsItem, setNewsItem] = useState(news ? transformNews(news, hasUniquePath) : null);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  
  useEffect(() => {
    document.querySelector("main").scrollTo(0, 0)
    const fetchNews = async () => {
      setIsLoading(true);
      try {
        let result;
        if (hasUniquePath === true) {
          result = await newsFeedService.retrieveNewsByPathAndCountry(newsPath, currentRegion);
        } else {
          result = await newsFeedService.retrieveNewsBySectionPathAndCountry(sectionPath, currentRegion);
        }

        const resultTransformed = transformNews(result[0], hasUniquePath);
        setNewsItem(resultTransformed);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
      //exceptionHandlerService.showInfoToast('News has been retrieved');
    };
    if (newsItem === null || (hasUniquePath && newsPath !== newsItem?.path) || (!hasUniquePath && sectionPath !== newsItem?.section?.path)) {
      fetchNews();
    } else {
      setIsLoading(false);
    }
  }, [newsPath, sectionPath]);
  const classes = useStyles();
  const handleDelete = (event) => {
  }
  const handleClose = (event) => {
  }
  const updateEventHandler = function (updatedNewsItem) {
    const updatedNewsItemTransformed = transformNews(updatedNewsItem, hasUniquePath);
    setNewsItem(updatedNewsItemTransformed);
    news = updatedNewsItemTransformed;
  }

  const handleUpdateEvent = function () {
    setOpen(true);
  }

  const handleAddNewsButtonClick = function () {
    setOpen(true);
  }

  const breadcrumbsLinks = function () {
    let sp = null;
    if (hasUniquePath === true) {
      sp = newsPath;
    } else {
      sp = sectionPath;
    }

    const words = sp.replace("/post-details", "").split('/');
    let currentLink = "/_";
    let currentWordIndex = 1;
    return words.map(pathItem => {
      currentLink = currentLink + "/" + pathItem;
      if (currentWordIndex < words.length) {
        currentWordIndex++;
        return (<Link underline="hover" color="inherit" href={currentLink}>
          {pathItem}
        </Link>);
      } else if (currentWordIndex === words.length) {
        currentWordIndex++;
        return (<Typography color="primary">{pathItem}</Typography>);
      }
    });
  }

  return (
    <>
      <SEO title={newsItem?.header ? newsItem?.header : ""} description="" name="Pearl Art Store" image={null}/>
      <Container maxWidth="lg" className="breadcrumbs">
        <Grid key="breadcrumbs" item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            {breadcrumbsLinks()}
          </Breadcrumbs>

        </Grid>
      </Container>
      {isLoading && <CircularProgress color="primary" size={37} thickness={10} />}
      {console.log(isLoading)}
      {!isLoading && <div>
        <Container maxWidth="lg" className={classes.container}>
          <Grid key="news-grid" container spacing={3}>
            {newsItem &&
              <Grid key={newsItem.header} item xs={12}>

                <NewsPanel
                  newsItem={newsItem} newsFeedService={newsFeedService} handleDeleteEvent={handleDelete} commentsService={commentsService} handleUpdateEvent={handleUpdateEvent} detailsLinkEnabled={false} showDeleteButton={false} customStyles={{ newsContent: { minHeight: '65vh' } }} postHeightScale={null} postStaticHeightEnabled={null} />
              </Grid>
            }
          </Grid>
        </Container>
        <Dialog disableEnforceFocus disableScrollLock classes={{ paper: classes.dialog }} disableBackdropClick onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" >
            Edit news post
          </DialogTitle>
          <DialogContent dividers>
            <NewsEditor newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} newsItem={newsItem} setOpen={setOpen} updateEventHandler={updateEventHandler} sectionPath={newsItem ? newsItem.section.path : sectionPath} createSectionIfNotExists={createSectionIfNotExists} group={group} usePathEditor={hasUniquePath} sectionType="post" sectionName={sectionName} />
          </DialogContent>
        </Dialog>
        {AuthorityUtils.userHasAuthority("UPDATE_NEWS_POST") && <Fab color="primary" aria-label="add" onClick={handleAddNewsButtonClick} className={classes.addFab}>
          <Add />
        </Fab>}

      </div>}
    </>
  )
};

export default NewsFeedItem;
