import './Root.css';
import React, { createContext, memo, useContext, useEffect, useRef } from "react";
import { NavLink, Redirect, Route, useHistory } from "react-router-dom";
import clsx from 'clsx';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Switch } from 'react-router-dom';
import NewsFeedService from 'services/NewsFeedService';
import LoginPage from 'components/login-page/LoginPage';
import LoginService from 'services/LoginService';
import WelcomePage from 'components/login-page/WelcomePage';
import ExceptionHandlerService from 'services/ExceptionHandlerService';
import HttpRequestExecutor from 'services/HttpRequestExecutor';
import ToastWrapper from 'components/toast/ToastWrapper';
import { CircularProgress, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import CommentsService from 'services/CommentsService';
import TilePage from 'components/tile-page/TilePage';
import TileService from 'services/TileService';
import DataAnalyticsService from 'services/DataAnalyticsService';
import SectionBar from 'components/section-bar/SectionBar';
import NewsFeed from 'components/NewsFeed';
import SectionService from 'services/SectionService';
import ItemDetails from 'components/ItemDetails';
import ItemDetailsService from 'services/ItemDetailsService';
import FileService from 'services/FileService';
import NewsFeedItem from 'components/NewsFeedItem';
import Home from 'components/home-page/Home';
import HomePageService from 'services/HomePageService';
import { AccountCircle, Close, Input, PersonAdd } from '@material-ui/icons';
import AppFooter from 'components/modules/views/AppFooter';
import SectionContentPage from 'components/SectionContentPage';
import SectionCacheUtils from 'util/SectionCacheUtils';
import ShoppingCartComponent from 'util/ShoppingCart';
import ShoppingCartPage from 'components/payments/shoppingCart';
import { useTranslation } from "react-i18next";
import { useLanguageAndRegionContext } from 'util/LanguageAndRegionContextProvider';
import ShoppingOrderPage from 'components/payments/shoppingOrder';
import OrderService from 'services/OrderService';
import { useShoppingCart } from 'util/ShoppingCartContextProvider';
import OrderManagementPage from 'components/payments/orderManagement';
import CookieConsent from "react-cookie-consent";
import StatisticsPage from 'components/statistics-page/StatisticsPage';


const useStyles = props => makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
  },
  toolbarIcon: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarIconButton: {
    color: 'black'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: props.drawerWidth,
    width: `calc(100% - ${props.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1
  },
  logo: {
    fontWeight: 600,
    flexGrow: 1,
    fontSize: 24,
    textAlign: 'center'
  },
  logoMobile: {
    fontWeight: 600,
    flexGrow: 1,
    fontSize: 15,
    textAlign: 'center'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: props.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  left: {
    flex: 1
  },
  body: {
    minHeight: '85vh'
  },
  drawerLogo: {
    textAlignLast: 'center'
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    whiteSpace: 'nowrap',
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
  }



}));

const Root = () => {
  const {
    currentRegion
  } = useLanguageAndRegionContext();
  const { t, i18n } = useTranslation();
  const toastComponentRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 200 : 340;
  const classes = useStyles({drawerWidth})();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sharedUserData, setSharedUserData] = React.useState({
    id: localStorage.getItem('id'),
    firstname: localStorage.getItem('firstname'),
    lastname: localStorage.getItem('lastname'),
    email: localStorage.getItem('email'),
    nickname: localStorage.getItem('nickname'),
    authorities: localStorage.getItem('authorities')
  });
  const {
    itemsCount
  } = useShoppingCart();

  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const exceptionHandlerService = new ExceptionHandlerService(setSharedUserData, history, toastComponentRef);
  const httpRequestExecutor = new HttpRequestExecutor(exceptionHandlerService);
  const commentsService = new CommentsService(httpRequestExecutor);
  const newsFeedService = new NewsFeedService(httpRequestExecutor);
  const itemDetailsService = new ItemDetailsService(httpRequestExecutor);
  const homePageService = new HomePageService(httpRequestExecutor);
  const loginService = new LoginService(httpRequestExecutor);
  const tileService = new TileService(httpRequestExecutor);
  const orderService = new OrderService(httpRequestExecutor);
  const fileService = new FileService(httpRequestExecutor);
  const sectionService = new SectionService(httpRequestExecutor);
  const dataAnalyticsService = new DataAnalyticsService(httpRequestExecutor);
  const [sections, setSections] = React.useState([]);
  const [isSectionListLoading, setIsSectionListLoading] = React.useState(false);
  const [showDrawerSpinner, setShowDrawerSpinner] = React.useState(false);

  useEffect(() => {
    const sendHeartbeat = () => {
      dataAnalyticsService.postUserSessionHeartbeatEvent({
        sessionID: sessionStorage.getItem('sessionId')
      });
    };

    // Set up a setInterval to call sendHeartbeat every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(sendHeartbeat, 300000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleSignOut = function () {
    loginService.signout().then(res => {
      console.log(res);
      exceptionHandlerService.handleSignOut();
    });

  }

  const handleDrawerOpen = async function() {
    if(isSectionListLoading) {
      setShowDrawerSpinner(true);
    } else {
      setOpen(true);
    }
  };



  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (showDrawerSpinner === true && isSectionListLoading === false) {
      setShowDrawerSpinner(false);
      setOpen(true);
    }
  }, [isSectionListLoading]);

  function trackPageView() {  
    dataAnalyticsService.postDataAnalyticsEvent({
      username: sharedUserData.email ? sharedUserData.email : "Unknown",
      eventType: "Page Change",
      sessionID: sessionStorage.getItem('sessionId'),
      page: window.location.toString()
    });
  }
  
  useEffect(() => {
    trackPageView(); 
    history.listen(trackPageView); // Track all subsequent pageviews
  }, [history]);

  const handleMenu = function (event) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsSectionListLoading(true);
    const fetchSections = async () => {
        try {
            const result = await sectionService.retrieveRootSections(currentRegion);
            setSections(result);
            SectionCacheUtils.addAll(result);
            setIsSectionListLoading(false);
        } catch (e) {
            console.log(e);
            setIsSectionListLoading(false);
        }
    };

    fetchSections();

}, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              { !showDrawerSpinner && <MenuIcon /> }
              { showDrawerSpinner && <CircularProgress color="inherit" size={17} thickness={10} /> }
            </IconButton>
          </div>
          <Link

            underline="none"
            color="inherit"
            className={isMobile? classes.logoMobile : classes.logo}
            href="/"
          >
            {'Pearl Art Store'}
          </Link>
          <div className={classes.right}>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/cart"
            style={{pointerEvents: itemsCount === 0 ? 'none' : ''}}
          >
          <ShoppingCartComponent></ShoppingCartComponent>
          </Link>

            {!sharedUserData.firstname && <div style={{whiteSpace: 'nowrap'}}><Link
              color="inherit"
              variant="h6"
              underline="none"
              className={classes.rightLink}
              href="/signin"
            > { isMobile ? <Input /> : t('header_sign_in') }
            </Link>
              <Link
                variant="h6"
                underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
                href="/"
              >{ isMobile ? <PersonAdd /> : t('header_sign_up') }
              </Link></div>
            }
            {sharedUserData.firstname &&

              <div>
                <IconButton
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem disabled={true}>{sharedUserData.firstname + ', ' + sharedUserData.lastname}</MenuItem>
                  <MenuItem onClick={() => handleSignOut()}>{t('header_logout')}</MenuItem>
                </Menu>
              </div>

            }
          </div>
        </Toolbar>
      </AppBar>
      
      <Drawer
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        ModalProps={{ onBackdropClick: handleDrawerClose }}
      >

          <div>
        <div className={classes.drawerLogo}>
        <img src="/fstor/logo/medium-logo.jpg" alt="" width={drawerWidth-50} />
        </div>
        <Divider />
        <SectionBar handleSectionItemClick={handleDrawerClose} sectionService={sectionService} tileService={tileService} exceptionHandlerService={exceptionHandlerService} sectionsInput={sections}/>
        <div className={classes.toolbarIcon}>
        <IconButton className={classes.toolbarIconButton} onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </div>
        </div>
      </Drawer>
      <main className={classes.content}>

        <div className={classes.appBarSpacer} />
        <div className={classes.body}>
          <Switch>
            <Route exact path="/">
              <Home homePageService={homePageService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} fileService={fileService} />
            </Route>
            <Route path="/signin">
              <LoginPage loginService={loginService} sharedUserData={setSharedUserData} />
            </Route>
            <Route path="/cart">
              <ShoppingCartPage/>
            </Route>
            <Route path="/order">
              <ShoppingOrderPage orderService={orderService} exceptionHandlerService={exceptionHandlerService}/>
            </Route>
            <Route path="/order-management">
              <OrderManagementPage orderService={orderService} exceptionHandlerService={exceptionHandlerService}/>
            </Route>
            <Route path="/welcomepage">
              <WelcomePage />
            </Route>
            <Route path="/statistics">
              <StatisticsPage dataAnalyticsService={dataAnalyticsService}/>
            </Route>
            <Redirect from="/_/home" to="/" />
            <Route path="/_/:sectionPath+" exact render={
              (props) =>
                <SectionContentPage
                  sectionPath={props.match.params.sectionPath}
                  tileService={tileService}
                  sectionService={sectionService}
                  exceptionHandlerService={exceptionHandlerService}
                  fileService={fileService}
                  newsFeedService={newsFeedService}
                  commentsService={commentsService}
                  state={props.location.state}
                  itemDetailsService={itemDetailsService}
                />} >
            </Route>
            <Route path="/footer-center/post/:sectionName+" exact render={(props) => <NewsFeedItem hasUniquePath={false} sectionPath={props.match.params.sectionName} newsPath={null} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} news={null} createSectionIfNotExists={true} sectionName={props.location.state} group='footerMiddle' />} >
            </Route>
            <Route path="/footer-center/posts/:sectionName+" exact render={(props) => <NewsFeed sectionPath={props.match.params.sectionName} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} createSectionIfNotExists={true} sectionName={props.location.state} group='footerMiddle' />} >
            </Route>
            <Route path="/footer-left/:sectionName+" exact render={(props) => <NewsFeedItem hasUniquePath={false} sectionPath={props.match.params.sectionName} newsPath={null} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} news={null} createSectionIfNotExists={true} sectionName={props.location.state} group='footerLeft' />} >
            </Route>
            <Route path="/footer-right/:sectionName+" exact render={(props) => <NewsFeedItem hasUniquePath={false} sectionPath={props.match.params.sectionName} newsPath={null} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} news={null} createSectionIfNotExists={true} sectionName={props.location.state} group='footerRight' />} >
            </Route>
            
          </Switch>
        </div>
        <AppFooter sharedUserData={sharedUserData} sections={sections}></AppFooter>
      </main>
      <CookieConsent style={{ opacity: "0.8" }} buttonText={t('common_cookies_concent_button_accept')}>{t('common_cookies_concent_start')}<Link color="secondary" href="/footer-left/cookiesPolicy">{t('common_cookies_concent_link_text')}</Link>{t('common_cookies_concent_end')}</CookieConsent>
      <ToastWrapper ref={toastComponentRef} />
    </div>
  );
}

export default Root;
