import React, { useEffect } from "react";
import { useLanguageAndRegionContext } from "util/LanguageAndRegionContextProvider";
import SectionCacheUtils from "util/SectionCacheUtils";
import ItemDetails from "./ItemDetails";
import NewsFeed from "./NewsFeed";
import NewsFeedItem from "./NewsFeedItem";
import TilePage from "./tile-page/TilePage";

const SectionContentPage = function ({
  sectionPath,
  tileService,
  sectionService,
  exceptionHandlerService,
  fileService,
  newsFeedService,
  commentsService,
  state,
  itemDetailsService }) {
    const {
      currentRegion
    } = useLanguageAndRegionContext();
  const [section, setSection] = React.useState(null);
  const isPostDetails = function (path) {
    return path.endsWith('/post-details');
  }
  const cropRoutingData = function (path) {
    let editablePath = '' + path;
    if (isPostDetails(editablePath)) {
      editablePath = editablePath.replace('/post-details', '');
    }

    return editablePath;
  }
  useEffect(() => {
    const path = cropRoutingData(sectionPath);
    const fetchSection = async () => {
      const sections = await sectionService.retrieveSectionsByPathAndCountry(path, currentRegion);
      if (sections.length != 1) {
        throw Error('Unacceptable response received, expected only one section found by path');
      }
      setSection(sections[0]);
      SectionCacheUtils.add(sections[0]);
    }
    const section = SectionCacheUtils.getByPath(path);
    if (section) {
      setSection(section);
    } else if(!isPostDetails(sectionPath)) {
      fetchSection();
    }
  }, [sectionPath]);

  return (<>
    {section && section.type === 'tiles' &&
      <TilePage sectionPath={section.path} section={section} sectionService={sectionService} tileService={tileService} exceptionHandlerService={exceptionHandlerService} fileService={fileService} />
    }
    {section && section.type === 'posts' && !isPostDetails(sectionPath) &&
      <NewsFeed sectionPath={section.path} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} createSectionIfNotExists={false} group='' sectionName={null} />
    }
    {section && section.type === 'post' &&
      <NewsFeedItem hasUniquePath={false} sectionPath={section.path} newsPath={null} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} news={state} createSectionIfNotExists={false} group='' sectionName={null} />
    }
    {isPostDetails(sectionPath) &&
      <NewsFeedItem hasUniquePath={true} newsPath={cropRoutingData(sectionPath)} sectionPath={null} newsFeedService={newsFeedService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} news={state} createSectionIfNotExists={false} group='' sectionName={null} />
    }
    {section && section.type === 'details' &&
      <ItemDetails sectionPath={section.path} itemDetailsService={itemDetailsService} exceptionHandlerService={exceptionHandlerService} commentsService={commentsService} fileService={fileService}/>
    }
  </>);
}

export default SectionContentPage;