class DataAnalyticsService {
    constructor(httpRequestExecutor) {
        this.httpRequestExecutor = httpRequestExecutor;
    }

    postDataAnalyticsEvent(event) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(event)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/data-analytics/event', requestOptions);
    }

    postUserSessionHeartbeatEvent(event) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(event)
        };
        /*TODO: replace with reverse proxy */
        return this.httpRequestExecutor.execute('/api/data-analytics/session-heartbeat', requestOptions);
    }

   /*async getReport() {
        return Promise.resolve(statisticsResponse);
    }*/

    async getReport() {
        return this.httpRequestExecutor.execute('/api/statistics');
    }

}

export default DataAnalyticsService;